import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import SwiperCore from "swiper";
export default function GetWallet() {
  SwiperCore.use([Navigation, Pagination]);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <>
      <Swiper className="WalletSwiper" pagination={pagination} navigation>
        <SwiperSlide>
          <div className="dwalletcontainer">
            <div className="wtitle">
              <a href="https://defly.app/" target="blank">
                <img src="/defly-logo.webp" alt="DEFLY" />
              </a>
              <p> DEFLY</p>
            </div>
            <div className="wtext">
              Charts, swaps, stats in one mobile wallet. We combined the best
              features of traditional crypto trading and real-time portfolio
              monitoring with the independence and security of DeFi.
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="dwalletcontainer">
            <div className="wtitle">
              <a href="https://perawallet.app/" target="blank">
                <img src="/pera-logo.webp" alt="PERA" />
              </a>
              <p> PERA</p>
            </div>
            <div className="wtext">
              Pera Wallet is the easiest and safest way to store, buy and swap
              on the Algorand blockchain. Discover & connect decentralized
              applications on any device.
            </div>
          </div>{" "}
        </SwiperSlide>
        <SwiperSlide>
          <div className="dwalletcontainer">
            <div className="wtitle">
              <a href="https://www.daffione.com/" target="blank">
                <img src="/daffione-logo.png" alt="DAFFI-ONE" />
              </a>
              <p> DAFFI ONE</p>
            </div>
            <div className="wtext">
              Seamless interoperability of digital currencies & tokenized assets
              at your fingertips. A full suite of broadly-inclusive
              digital-first solutions for your everyday use.
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="dwalletcontainer">
            <div className="wtitle">
              <a href="https://lute.app/" target="blank">
                <img src="/lute-logo.png" alt="LUTE" />
              </a>
              <p>LUTE</p>
            </div>
            <div className="wtext">
              Lute is an Algorand web wallet. By default, Lute is Ledger-only -
              check the Settings for more options.
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
