// Import necessary libraries and components

import { Link } from "react-router-dom";

import React from "react";

// Main component function
export default function Terms() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="burncontainer">
      <div className="burnNFT">
        <div className="closeposition">
          <Link
            to="/"
            type="button"
            className="btn-close"
            aria-label="Close"
          ></Link>
        </div>
        <h1>Terms and Conditions</h1>
        <div className="termsbox">
          <h4>
            Agreement between User and Mental Marvin (https://mentalmarvin.art)
          </h4>
          <div>
            Welcome to Mental Marvin Website (the "Website"), which is operated
            by Studio Maars (the “Company”). The Website is offered to you
            conditioned on your acceptance without modification of the terms,
            conditions, and notices contained herein (the "Terms"). Your use of
            the Website constitutes your agreement to all such Terms. Please
            read these terms carefully and keep a copy of them for your
            reference.
          </div>

          <div>
            This Terms of Service ("Agreement") governs the access and use of
            the Website ("Website") and its related services, including but not
            limited to the display of digital assets, wallet connection, asset
            management, and participation in various mini-games and utilities
            provided by the Company. The purpose of the Website is to provide
            information and services related to the Company's digital asset and
            comic book project, which includes the creation, modification, sale,
            and management of digital assets ("Assets"). Through the Website,
            users can view the Assets created by the Company, connect their
            digital wallets to manage and modify them, as well as enroll their
            Assets in various competitions and mini-games. By using the Website
            and its Services, users agree to be bound by the terms and
            conditions set forth in this Agreement. This Agreement is designed
            to ensure that users understand their rights and obligations while
            using the Website and its Services, and to protect the Company, its
            Assets, and the intellectual property rights associated with the
            project. It also aims to provide guidance on the appropriate use of
            the Website, including compliance with applicable laws and
            regulations, and to establish the relationship between the Company
            and its users.
          </div>

          <h4>Privacy</h4>
          <div>
            Your use of the Website is subject to Studio Maars' Privacy Policy.
            Please review our Privacy Policy, which also governs the Website and
            informs users of our data collection practices.
          </div>

          <h4>Electronic Communications</h4>
          <div>
            Visiting the Website or sending emails to the Website constitutes
            electronic communications. You consent to receive electronic
            communications and agree that all agreements, notices, disclosures,
            and other communications that we provide to you electronically, via
            email and on the Website, satisfy any legal requirement that such
            communications be in writing.
          </div>

          <h4>Children Under Thirteen</h4>
          <div>
            The Website does not knowingly collect, either online or offline,
            personal information from persons under the age of thirteen. If you
            are under 18, you may use the Website only with the permission of a
            parent or guardian.
          </div>

          <h4>Links to Third Party Websites/Third Party Services</h4>
          <div>
            The Website may contain links to other websites ("Linked Websites").
            The Linked Websites are not under the control of the Company and
            Studio Maars is not responsible for the contents of any Linked
            Website, including without limitation any link contained in a Linked
            Website, or any changes or updates to a Linked Website. The Company
            is providing these links to you only as a convenience, and the
            inclusion of any link does not imply endorsement by the Company of
            the Website or any association with its operators. Certain services
            made available via the Website are delivered by third-party websites
            and organizations. By using any product, service, or functionality
            originating from the Website domain, you acknowledge and consent
            that the Website may share such information and data with any third
            party with whom the Company has a contractual relationship to
            provide the requested product, service, or functionality on behalf
            of the Website users and customers.
          </div>

          <h4>No Unlawful or Prohibited Use/Intellectual Property</h4>
          <div>
            You are granted a non-exclusive, non-transferable, revocable license
            to access and use the Website strictly in accordance with these
            terms of use. As a condition of your use of the Website, you warrant
            to the Company that you will not use the Website for any purpose
            that is unlawful or prohibited by these Terms. You may not use the
            Website in any manner that could damage, disable, overburden, or
            impair the Website or interfere with any other party's use and
            enjoyment of the Website. You may not obtain or attempt to obtain
            any materials or information through any means not intentionally
            made available or provided for through the Website. All content
            included as part of the Service, such as text, graphics, logos,
            images, as well as the compilation thereof, and any software used on
            the Website, is the property of the Company or its suppliers and
            protected by copyright and other laws that protect intellectual
            property and proprietary rights. You agree to observe and abide by
            all copyright and other proprietary notices, legends, or other
            restrictions contained in any such content and will not make any
            changes thereto. You will not modify, publish, transmit, reverse
            engineer, participate in the transfer or sale, create derivative
            works, or in any way exploit any of the content, in whole or in
            part, found on the Website. The Company content is not for resale.
            Your use of the Website does not entitle you to make any
            unauthorized use of any protected content, and in particular you
            will not delete or alter any proprietary rights or attribution
            notices in any content. You will use protected content solely for
            your personal use and will make no other use of the content without
            the express written permission of the Company and the copyright
            owner. You agree that you do not acquire any ownership rights in any
            protected content. We do not grant you any licenses, express or
            implied, to the intellectual property of the Company or our
            licensors except as expressly authorized by these Terms.
          </div>

          <h4>International Users</h4>
          <div>
            The Service is controlled, operated, and administered by the Company
            from our offices within the European Union (Croatia). If you access
            the Service from a location outside the EU, you are responsible for
            compliance with all local laws. You agree that you will not use the
            Company Content accessed through the Website in any country or in
            any manner prohibited by any applicable laws, restrictions, or
            regulations.
          </div>

          <h4>Indemnification</h4>
          <div>
            You agree to indemnify, defend, and hold harmless the Company, its
            owners, directors, employees, agents, and third parties, from any
            losses, costs, liabilities, and expenses (including reasonable
            attorney's fees) relating to or arising out of your use of or
            inability to use the Website or services, any user postings made by
            you, your violation of any terms of this Agreement, or your
            violation of any rights of a third party, or your violation of any
            applicable laws, rules, or regulations. The Company reserves the
            right, at its own cost, to assume the exclusive defense and control
            of any matter otherwise subject to indemnification by you, in which
            event you will fully cooperate with the Company in asserting any
            available defenses.
          </div>

          <h4>Arbitration</h4>
          <div>
            In the event the parties are unable to resolve any dispute arising
            out of or concerning these Terms and Conditions, or any provisions
            hereof, whether in contract, tort, or otherwise at law or in equity
            for damages or any other relief, such dispute shall be resolved only
            by final and binding arbitration pursuant to the European Union
            regulations, conducted by a single neutral arbitrator and
            administered by an arbitration service selected by the parties, in a
            location mutually agreed upon by the parties. The arbitrator's award
            shall be final, and judgment may be entered upon it in any court
            having jurisdiction. In the event that any legal or equitable
            action, proceeding, or arbitration arises out of or concerns these
            Terms and Conditions, the prevailing party shall be entitled to
            recover its costs and reasonable attorney's fees. The parties agree
            to arbitrate all disputes and claims in regards to these Terms and
            Conditions or any disputes arising as a result of these Terms and
            Conditions, whether directly or indirectly, including tort claims
            that are a result of these Terms and Conditions. The parties agree
            that the European Union regulations govern the interpretation and
            enforcement of this provision. The entire dispute, including the
            scope and enforceability of this arbitration provision, shall be
            determined by the arbitrator. This arbitration provision shall
            survive the termination of these Terms and Conditions.
          </div>

          <h4>Class Action Waiver</h4>
          <div>
            Any arbitration under these Terms and Conditions will proceed on an
            individual basis; class arbitrations and
            class/representative/collective actions are not permitted. THE
            PARTIES AGREE THAT A PARTY MAY ONLY BRING CLAIMS AGAINST THE OTHER
            IN THEIR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER
            IN ANY PUTATIVE CLASS, COLLECTIVE, OR REPRESENTATIVE PROCEEDING,
            INCLUDING BUT NOT LIMITED TO PRIVATE ATTORNEY GENERAL ACTIONS.
            Furthermore, unless both parties mutually agree otherwise, the
            arbitrator shall not consolidate claims from more than one person
            and shall not preside over any form of representative or class
            proceeding.
          </div>

          <h4>Liability Disclaimer</h4>
          <div>
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
            AVAILABLE THROUGH THE WEBSITE MAY INCLUDE INACCURACIES OR
            TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
            INFORMATION HEREIN. STUDIO MAARS AND/OR ITS SUPPLIERS MAY MAKE
            IMPROVEMENTS AND/OR CHANGES IN THE WEBSITE AT ANY TIME. STUDIO MAARS
            AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY,
            RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
            CONTAINED ON THE WEBSITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
            PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
            WARRANTY OR CONDITION OF ANY KIND. STUDIO MAARS AND/OR ITS SUPPLIERS
            HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
            INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. TO
            THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            STUDIO MAARS AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
            INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR
            ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
            LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH THE USE OR PERFORMANCE OF THE WEBSITE, WITH THE DELAY OR
            INABILITY TO USE THE WEBSITE OR RELATED SERVICES, THE PROVISION OF
            OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE,
            PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE
            WEBSITE, OR OTHERWISE ARISING OUT OF THE USE OF THE WEBSITE, WHETHER
            BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
            EVEN IF STUDIO MAARS OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
            POSSIBILITY OF DAMAGES. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
            DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE
            DISSATISFIED WITH ANY PORTION OF THE WEBSITE, OR WITH ANY OF THESE
            TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
            THE WEBSITE.
          </div>

          <h4>Termination/Access Restriction</h4>
          <div>
            The Company reserves the right, at its sole discretion, to terminate
            your access to the Website and related services, or any portion
            thereof, at any time, without notice. To the fullest extent
            permitted by law, this agreement is governed by the laws of [Your
            Country or State], and you consent to the exclusive jurisdiction and
            venue of the courts in [Your Jurisdiction] for all disputes arising
            out of or related to the use of the Website. Use of the Website is
            unauthorized in any jurisdiction that does not give effect to all
            provisions of these Terms, including this section. You agree that no
            joint venture, partnership, employment, or agency relationship
            exists between you and the Company as a result of this agreement or
            your use of the Website. The Company’s performance under this
            agreement is subject to existing laws and legal processes, and
            nothing in this agreement limits the Company’s right to comply with
            governmental, court, or law enforcement requests or requirements
            regarding your use of the Website or information provided to or
            gathered by the Company in relation to such use. If any part of this
            agreement is determined to be invalid or unenforceable under
            applicable law, including but not limited to warranty disclaimers
            and liability limitations, the invalid or unenforceable provision
            will be replaced by a valid, enforceable provision that most closely
            reflects the original intent, and the remainder of the agreement
            shall remain in effect. Unless otherwise specified, this agreement
            constitutes the entire agreement between the user and the Company
            with respect to the Website and supersedes all prior or
            contemporaneous communications and proposals, whether electronic,
            oral, or written, between the user and the Company. A printed
            version of this agreement and any notices given in electronic form
            shall be admissible in judicial or administrative proceedings to the
            same extent and subject to the same conditions as other business
            documents and records originally maintained in printed form. It is
            the express wish of the parties that this agreement and all related
            documents be written in English.
          </div>

          <h4>Changes to Terms</h4>
          <div>
            The Company reserves the right, in its sole discretion, to change
            the Terms under which the Website is offered. The most current
            version of the Terms will supersede all previous versions. The
            Company encourages you to periodically review the Terms to stay
            informed of our updates.
          </div>

          <h4>Contact Us</h4>
          <div>
            The Company welcomes your questions or comments regarding the Terms:
            Studio Maars, trade for digital technologies, design and art, vl.
            Stjepko Marenic
            <p>
              {" "}
              Email Address:{" "}
              <a href="mailto:maarscomics@gmail.com">maarscomics@gmail.com</a>
            </p>
          </div>

          <div>Effective as of August 1, 2024.</div>
        </div>
        <button className="btn btn-secondary" onClick={scrollToTop}>
          Back to the Top
        </button>
      </div>
    </div>
  );
}
