import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import "./Styles.css";
import "./Navbar.css";

function Marvin() {
  const [selectedButton, setSelectedButton] = useState("Button3");
  const navigate = useNavigate();
  const location = useLocation();

  // Map path to button name
  const pathToButtonMap = {
    "marvin/mymarvins": "Button2",
    "marvin/swapper": "Button3",
    "marvin/traitshop": "Button4",
    "marvin/specials": "Button6",
    marvin: "Button5", // Assuming this is the home or default page
    "/": "Button5", // Default route or home
  };
  // Update selectedButton when the location changes
  useEffect(() => {
    const currentPath = location.pathname.replace(/^\/|\/$/g, "");

    setSelectedButton(pathToButtonMap[currentPath] || "Button3");
  }, [location, pathToButtonMap]);

  const handleButtonClick = (buttonName, path) => {
    setSelectedButton(buttonName);
    navigate(path);
  };

  return (
    <div className="marvincontainer">
      <div className="floating-buttons">
        <button
          className={`nav-link ${selectedButton === "Button2" ? "active" : ""}`}
          onClick={() => handleButtonClick("Button2", "mymarvins")}
        >
          <h5>MY MARVINS</h5>
        </button>
        <button
          className={`nav-link ${selectedButton === "Button3" ? "active" : ""}`}
          onClick={() => handleButtonClick("Button3", "swapper")}
        >
          <h5>SWAPPER</h5>
        </button>
        <button
          className={`nav-link ${selectedButton === "Button4" ? "active" : ""}`}
          onClick={() => handleButtonClick("Button4", "traitshop")}
        >
          <h5>TRAIT SHOP</h5>
        </button>
        <button
          className={`nav-link ${selectedButton === "Button6" ? "active" : ""}`}
          onClick={() => handleButtonClick("Button6", "specials")}
        >
          <h5>BENEFITS</h5>
        </button>
        <div>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => handleButtonClick("Button5", "/")}
          ></button>
        </div>
      </div>
      <div className="component-container">
        <Outlet />
      </div>
    </div>
  );
}

export default Marvin;
