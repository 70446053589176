import { useEffect, useState, useRef } from "react";
import algosdk from "algosdk";
import { getBalance, swap_request_tx } from "../utilities.ts";
import { getAssetsFromAddress } from "../utilities.ts";
import { SelectField } from "../customSelect.tsx";
import {
  ALGO_FEE,
  CREATOR_WALLET,
  CUBE_FEE,
  FULL_MENTAL_MARVIN_RESERVE,
  NODE_ENDPOINT,
  TRAITS,
  API_ENDPOINT,
  API_ENDPOINT2,
} from "../../constants.ts";
import { incompatibleTraitRules } from "../../TraitClash.js";
import algo_logo from "../../assets/algo.webp";
import cube_icon from "../../assets/cube.webp";
import { toast } from "react-toastify";
import axios from "axios";
import { decodeSignedTransaction, decodeUnsignedTransaction } from "algosdk";
import { encode, decode } from "uint8-to-base64";
import React from "react";
import { useWallet } from "@txnlab/use-wallet";
import "./swapper.css";
import TraitBox from "../traitbox.tsx";
import marvin1 from "../../assets/marvin/marvin1.webp";
import marvin3 from "../../assets/marvin/marvin3.webp";
import { ImArrowDown } from "react-icons/im";
import { ImArrowUp } from "react-icons/im";
import { PiDotsThreeVertical } from "react-icons/pi";
import ConnectDropdown from "../ConnectDropdown";
import success from "../../assets/sounds/success.mp3";
import DynamicImage from "../utils/DynamicAssetImages";
import { useWalletInfo } from "../utils/UserWalletHook.js";

export default function Swapper() {
  const { walletAddress } = useWalletInfo();
  const [loading, setLoading] = useState(true);
  const [userAssets, setUserAssets] = useState([] as number[]);
  const [userBalance, setUserBalance] = useState([0, 0]);
  const [firstAsset, setFirstAsset] = useState("");
  const [secondAsset, setSecondAsset] = useState("");
  const [selectedTraits, setSelectedTraits] = useState<string[]>([]);
  const [isClicked, setIsClicked] = useState(false);
  const [transactions, setTransactions] = useState([] as any[]);
  const { activeAccount, signTransactions } = useWallet();
  const [showToast, setShowToast] = useState(false);
  const [isSwapButtonClickable, setIsSwapButtonClickable] = useState(false);
  const [trait1Data, setTrait1Data] = useState([]);
  const [trait2Data, setTrait2Data] = useState([]);
  const [incompatibleTraits, setIncompatibleTraits] = useState<string[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const testAssets = [1017947273, 1018074433, 1017982745, 1018095567];
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (walletAddress !== "" && walletAddress !== "0") {
      resetAsset(setFirstAsset, "");
      resetAsset(setSecondAsset, "");
      getAssetsFromAddress(walletAddress).then((res) => {
        setUserAssets(res);

        getBalance(walletAddress).then((res) => {
          setUserBalance(res);

          setLoading(false);
        });
      });
    }
  }, [walletAddress]);

  useEffect(() => {
    // Check for incompatible traits
    if (trait1Data && trait2Data) {
      const newIncompatibleTraits: string[] = [];

      for (const { trait1, value1, trait2, value2 } of incompatibleTraitRules) {
        const trait1Value1Matches = Array.isArray(value1)
          ? value1.includes(trait1Data[trait1])
          : typeof value1 === "function"
          ? value1(trait1Data[trait1])
          : trait1Data[trait1] === value1;

        const trait2Value2Matches = Array.isArray(value2)
          ? value2.includes(trait2Data[trait2])
          : typeof value2 === "function"
          ? value2(trait2Data[trait2])
          : trait2Data[trait2] === value2;

        const trait2Value1Matches = Array.isArray(value1)
          ? value1.includes(trait2Data[trait1])
          : typeof value1 === "function"
          ? value1(trait2Data[trait1])
          : trait2Data[trait1] === value1;

        const trait1Value2Matches = Array.isArray(value2)
          ? value2.includes(trait1Data[trait2])
          : typeof value2 === "function"
          ? value2(trait1Data[trait2])
          : trait1Data[trait2] === value2;

        if (
          (trait1Value1Matches && trait2Value2Matches) ||
          (trait2Value1Matches && trait1Value2Matches)
        ) {
          newIncompatibleTraits.push(trait1.toString(), trait2.toString());
        }
      }

      setIncompatibleTraits(newIncompatibleTraits);
    }
  }, [trait1Data, trait2Data]);

  const playSound = (sound) => {
    const audio = new Audio(sound);
    audio.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  };

  const handleTraitSelect = (trait) => {
    // Check if the trait is currently selected
    const isSelected = selectedTraits.includes(trait);

    // Update selectedTraits state: add or remove the trait
    setSelectedTraits((prev) => {
      if (isSelected) {
        return prev.filter((t) => t !== trait); // Deselect if already selected
      } else {
        return [...prev, trait]; // Select if not already selected
      }
    });

    // Only swap the traits if the trait is selected or de-selected
    swapTraits(trait, isSelected);
  };

  const swapTraits = (trait, isSelected) => {
    // Create copies of trait1Data and trait2Data to avoid direct mutation
    const updatedTrait1Data = { ...trait1Data };
    const updatedTrait2Data = { ...trait2Data };

    if (isSelected) {
      // If the trait is being selected, swap it
      const temp = updatedTrait1Data[trait];
      updatedTrait1Data[trait] = updatedTrait2Data[trait];
      updatedTrait2Data[trait] = temp;
    } else {
      // If the trait is being deselected, swap it again (revert to original state)
      const temp = updatedTrait1Data[trait];
      updatedTrait1Data[trait] = updatedTrait2Data[trait];
      updatedTrait2Data[trait] = temp;
    }

    // Update the states with the swapped trait data
    setTrait1Data(updatedTrait1Data);
    setTrait2Data(updatedTrait2Data);
  };

  const handleSelectAsset = (
    newAsset,
    setAssetFunction,
    resetOtherAsset = () => {}
  ) => {
    setAssetFunction(newAsset); // Update the respective asset (first or second)
    // Reset the other asset (the one not selected)
    resetOtherAsset();
    setSelectedTraits([]); // Reset selected traits each time an asset changes
  };
  const resetAsset = (setAsset, assetValue) => {
    // Temporarily clear the asset
    setAsset(null);

    // Reset the asset after a brief delay (e.g., 50 ms)
    setTimeout(() => {
      setAsset(assetValue);
    }, 50);
  };

  useEffect(() => {
    // Update the clickability status whenever relevant conditions change
    setIsSwapButtonClickable(
      firstAsset !== "" &&
        secondAsset !== "" &&
        firstAsset !== secondAsset &&
        selectedTraits.length > 0
    );
  }, [firstAsset, secondAsset, selectedTraits]);

  async function checkSwap() {
    if (firstAsset === "" || secondAsset === "") {
      toast.info("Please select two Marvins to swap!");
      return;
    }
    if (firstAsset === secondAsset) {
      toast.info("Please select different Marvins to swap!");
      return;
    }
    if (selectedTraits.length === 0) {
      toast.info("Please select at least one trait to swap!");
      return;
    }
    setIsClicked(true);

    try {
      const first_asset_reserve_response = await axios.get(
        `${NODE_ENDPOINT}/v2/assets/${firstAsset}`
      );
      if (
        first_asset_reserve_response.data.params.reserve ===
        FULL_MENTAL_MARVIN_RESERVE
      ) {
        toast.error("You can't swap a full mental Marvin!");
        setIsClicked(false);
        return;
      }

      const second_asset_reserve_response = await axios.get(
        `${NODE_ENDPOINT}/v2/assets/${secondAsset}`
      );
      if (
        second_asset_reserve_response.data.params.reserve ===
        FULL_MENTAL_MARVIN_RESERVE
      ) {
        toast.error("You can't swap a full mental Marvin!");
        setIsClicked(false);
        return;
      }

      const rebalance = await getBalance(walletAddress);
      setUserBalance(rebalance);
      if (userBalance[0] < selectedTraits.length * ALGO_FEE) {
        toast.error("You don't have enough ALGO to pay for the swap fee!");
        setIsClicked(false);
        return;
      }
      if (userBalance[1] < selectedTraits.length * CUBE_FEE) {
        toast.error("You don't have enough FC to pay for the swap fee!");
        setIsClicked(false);
        return;
      }

      const transaction = await swap_request_tx(
        walletAddress,
        parseInt(firstAsset),
        parseInt(secondAsset),
        selectedTraits
      );

      if (!transaction) {
        toast.error("Something went wrong while creating the swap request!");
        setIsClicked(false);
        return;
      }

      // Convert algosdk.Tx to Uint8Array
      const encodedTransaction = algosdk.encodeUnsignedTransaction(transaction);

      // Sign transactions using useWallet module
      const signedTransactions = await signTransactions([encodedTransaction]);

      // Extract signed transaction
      const signedTxn = signedTransactions[0];

      // Encode the signed transaction
      const encodedTxn = encode(signedTxn);

      if (encodedTxn) {
        toast.info("Please wait while creating the swap transactions...");
        const res = await axios.post(`${API_ENDPOINT}/swap/`, {
          tx: encodedTxn,
        });

        if (res.status === 200) {
          const transactions = res.data.transactions;
          if (transactions.length !== 3) {
            console.error("Unexpected number of transactions:", transactions);
            toast.error("Something went wrong while creating the swap!");
            setIsClicked(false);
            return;
          }
          setIsClicked(false);
          toast.info("Please sign the transactions to complete the swap!");
          setTransactions(transactions);
        }
      } else {
        toast.error("Something went wrong while creating the swap!");
        setIsClicked(false);
      }
    } catch (error: any) {
      console.error("Error during checkSwap:", error);
      if (error.response) {
        console.error("Response from the server:", error.response.data);
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  async function handleSwap() {
    if (!transactions || transactions.length !== 3) {
      toast.error("Something went wrong while creating the swap!");
      return;
    }
    setIsClicked(true);
    try {
      /* let user_algo_payment_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[0], "base64")
      ); */
      let user_cube_payment_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[0], "base64")
      );
      let first_asset_config_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[1], "base64")
      );
      let second_asset_config_txn = decodeSignedTransaction(
        Buffer.from(transactions[2], "base64")
      );
      let txnsToValidate = [] as Uint8Array[];
      toast.info("Please sign the transactions to complete the swap...");

      const multipleTxnGroups = [
        /* { txn: user_algo_payment_txn, signers: [walletAddress] }, */
        { txn: user_cube_payment_txn, signers: [walletAddress] },
        { txn: first_asset_config_txn, signers: [CREATOR_WALLET] },
        { txn: second_asset_config_txn.txn, signers: [CREATOR_WALLET] },
      ];

      // Flatten the array of transaction groups into a single array of transactions
      const transactionsToSign = multipleTxnGroups
        .map(({ txn }) => algosdk.encodeUnsignedTransaction(txn))
        .flat();

      // Sign transactions using useWallet module
      const signedTransactions = await signTransactions([transactionsToSign]);

      if (!signedTransactions) {
        toast.error("Something went wrong while signing the transactions!");
        setIsClicked(false);
        return;
      }

      txnsToValidate = [
        signedTransactions[0],
        /* signedTransactions[1], */
        decode(transactions[1]),
        decode(transactions[2]),
      ];
      // Encode the signed transactions
      const validateData = txnsToValidate.map((txn) => {
        return encode(txn);
      });

      // Log the request payload
      console.log("number or trx", validateData.length);

      // Validate the signed transactions against your API
      const validate_response = await axios.post(
        `${API_ENDPOINT}/swap/validate/`,
        {
          txns: validateData,
        }
      );

      if (validate_response.status === 200) {
        toast.success("Swap successfully completed!");
        setIsClicked(false);
        playSound(success);
        setTransactions([]);
        resetAsset(setFirstAsset, firstAsset);
        resetAsset(setSecondAsset, secondAsset);
        setSelectedTraits([]);

        /*  setTimeout(() => {
          window.location.reload();
        }, 1500); */
      } else {
        toast.error("Something went wrong while validating the transactions!");
        setIsClicked(false);
      }
    } catch (error: any) {
      console.error("Error during handleSwap:", error);
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  const marketlink = () => (
    <div className="marketwrapper">
      <h6>If you need Marvin's you can find the secondary markets here.</h6>
      <a
        href="https://www.minthol.art/3%3A0_1643/assets/all?listingTypes=BUY&listingTypes=BID"
        target="blank"
        className="marketlink"
      >
        <img src="/minthol.jpg" alt="minthol.art" />{" "}
      </a>
      <a
        href="https://www.asalytic.app/collection/mental-marvin?tab=market&traitTypes=&traitValues=&filterTypes=&filterValues="
        target="blank"
        className="marketlink"
      >
        <img src="/asalytic.png" alt="asalytic.app" />{" "}
      </a>
    </div>
  );

  return (
    <div className="limiter">
      <main className="swappermain">
        <div>
          <div className="arrows-down arrows-mobile">
            <ImArrowDown />
            <PiDotsThreeVertical />
            <ImArrowDown />
          </div>
          <div className="maincontainer">
            <div className="Selectfield SelectField--left">
              <SelectField
                text="First Marvin"
                selectedAsset={firstAsset}
                setSelectedAsset={(newAsset) =>
                  handleSelectAsset(newAsset, setFirstAsset, () =>
                    resetAsset(setSecondAsset, secondAsset)
                  )
                }
                assets={walletAddress === "" ? testAssets : userAssets}
              />
              {firstAsset !== "" ? (
                <div className="swapperimage-dynamic">
                  <img
                    src="/traits/Clothes/none.webp"
                    alt="marvin"
                    className="marvin3"
                  />
                  <DynamicImage traitData={trait1Data} />
                </div>
              ) : (
                <div>
                  <div className="swapperimage">
                    <img src={marvin3} alt="marvin" className="marvin3" />
                  </div>
                </div>
              )}
              <div className="traits1">
                <div
                  className="accordion swapper-accordion"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h5 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        TRAITS
                      </button>
                    </h5>

                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {firstAsset && firstAsset !== "" ? (
                          <TraitBox
                            asset_id={firstAsset}
                            onTraitsReceived={setTrait1Data}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="arrows-down arrows-up">
              <ImArrowUp />
              <PiDotsThreeVertical />
              <ImArrowUp />
            </div>
            {/* TRAIT PICKER*/}
            <div className="middlsection">
              <div className="selecttraits">
                <p>SELECT TRAITS TO SWAP</p>
                <ul className="listitems">
                  {TRAITS.map((trait) => (
                    <li
                      key={trait}
                      onClick={() =>
                        !incompatibleTraits.includes(trait) &&
                        handleTraitSelect(trait)
                      } // Disable click for incompatible traits
                      style={{
                        cursor: incompatibleTraits.includes(trait)
                          ? "not-allowed"
                          : "pointer", // Change cursor style for incompatible traits
                      }}
                    >
                      <label
                        className={`trait-label ${
                          selectedTraits.includes(trait) ? "selected" : ""
                        }`}
                      >
                        <div
                          className={`trait-image ${
                            incompatibleTraits.includes(trait)
                              ? "incompatible"
                              : ""
                          }`}
                        >
                          <img
                            src={`/traits/${trait}.webp`}
                            alt={trait}
                            className={
                              selectedTraits.includes(trait) ? "selected" : ""
                            }
                          />
                          {incompatibleTraits.includes(trait) && (
                            <div className="trait-overlay"></div>
                          )}
                        </div>
                        <span className="trait-name">{trait}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              {/* SWAP COST */}
              <div className="swapcost">
                {!walletAddress ? (
                  <>
                    <>
                      <div className="showcase-description">
                        This is just a showcase. If you want to do trait swaps
                        with your Marvin collection you need to connect your
                        Wallet.
                      </div>
                      <h6>Connect here</h6>
                      <div className="costmobile">
                        <div className="not-connected-button">
                          <ConnectDropdown
                            isOpen={dropdownOpen}
                            onClose={() => setDropdownOpen(false)}
                            ref={dropdownRef}
                          />
                        </div>
                      </div>{" "}
                      {marketlink()}
                    </>
                  </>
                ) : (
                  <>
                    {userAssets.length < 2 ? (
                      <>
                        <h6>YOU NEED AT LEAST 2 MARVINS TO DO A SWAP</h6>

                        {marketlink()}
                      </>
                    ) : (
                      <>
                        <h6>COST</h6>
                        <div className="costmobile">
                          <div className="priceshow">
                            {/* <div className="algo">
                              <img src={algo_logo} alt="algo" />
                              {selectedTraits.length * ALGO_FEE}
                            </div> */}
                            <div className="fccube">
                              <img src={cube_icon} alt="cube" />
                              {selectedTraits.length * CUBE_FEE}
                            </div>
                          </div>
                          {isClicked ? (
                            <button
                              className="btn btn-info"
                              type="button"
                              disabled
                            >
                              <div
                                className="spinner-border text-light mr-2"
                                role="status"
                              ></div>
                            </button>
                          ) : (
                            <>
                              {transactions.length === 3 ? (
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={handleSwap}
                                  disabled={!isSwapButtonClickable}
                                >
                                  SWAP
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  onClick={checkSwap}
                                  disabled={!isSwapButtonClickable}
                                >
                                  CHECK
                                </button>
                              )}
                            </>
                          )}
                        </div>
                        <p className="swap-two-steps">
                          Swap process consists of two steps.
                        </p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* MARVIN ON THE RIGHT */}
            <div className="Selectfield SelectField--right">
              <SelectField
                text="Second Marvin"
                selectedAsset={secondAsset}
                setSelectedAsset={(newAsset) =>
                  handleSelectAsset(newAsset, setSecondAsset, () =>
                    resetAsset(setFirstAsset, firstAsset)
                  )
                }
                assets={walletAddress === "" ? testAssets : userAssets}
              />
              {secondAsset !== "" ? (
                <div className="swapperimage-dynamic">
                  <img
                    src="/traits/Clothes/none.webp"
                    alt="marvin"
                    className="marvin3"
                  />
                  <DynamicImage traitData={trait2Data} />
                </div>
              ) : (
                <div>
                  <div className="swapperimage">
                    <img src={marvin3} alt="marvin" className="marvin3" />
                  </div>
                </div>
              )}
              <div className="traits2">
                <div
                  className="accordion swapper-accordion"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h5 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        TRAITS
                      </button>
                    </h5>

                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {secondAsset && secondAsset !== "" ? (
                          <TraitBox
                            asset_id={secondAsset}
                            onTraitsReceived={setTrait2Data}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swapdescription">
            All Mental Marvin's are unique and trait swapper will not allow to
            create a copy of another Mental Marvin. <br /> All transactions are
            final
          </div>
        </div>
      </main>
    </div>
  );
}
