import React, { useState } from "react";
import { IPFS_PREFIX } from "../../constants.ts";
import "./trinketmodal.css";

const TrinketModal = ({ isOpen, onClose, trinket }) => {
  if (!isOpen) return null; // Return nothing if the modal isn't open

  if (!trinket) {
    return (
      <div className="modal-overlay">
        <div className="trinket-modal-content">No trinket data available.</div>
      </div>
    ); // Handle case when trinketData is null
  }
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="trinket-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="closeposition">
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
            aria-label="Close"
          ></button>
        </div>
        <h2>{trinket.name}</h2>
        <p>{trinket.description}</p>

        <img src={`${IPFS_PREFIX}/${trinket.image_cid}`} alt={trinket.name} />

        <p></p>
        {trinket.currently_holding && (
          <h5>
            You hold <span>{trinket.currently_holding}</span>{" "}
          </h5>
        )}

        <p></p>
        {trinket.currently_holding && (
          <h5>
            Still in the JUNK <span>{trinket.supply}</span>{" "}
          </h5>
        )}

        <>
          Find JUNK in all the different places of Algo City and use it to make
          your Marvin or your life just a bit more Junkier.
        </>
      </div>
    </div>
  );
};

export default TrinketModal;
