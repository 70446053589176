import React, { useEffect, useState } from "react";
import algosdk from "algosdk";
import * as constant from "../constants.ts";
import { useWallet } from "@txnlab/use-wallet";
import cube_icon from "../assets/cube.webp";
import ripley_icon from "../assets/ripley.webp";
import algo_icon from "../assets/algo.webp";
import marvin_icon from "../assets/marvinicon.png";
import AF_icon from "../assets/AF.png";
import ForceCube from "./ClaimFC.tsx";
import { toast } from "react-toastify";
import axios from "axios";
import { force_cube_optin } from "./utilities.ts";
import { special_data } from "./specialdata.tsx";

export const GetBalance = () => {
  const { activeAccount, signTransactions, sendTransactions } = useWallet();
  const [isClicked, setIsClicked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [forceCubeFromTraits, setForceCubeFromTraits] = useState(0);
  const [specialBlance, setSpecialBalance] = useState(0);
  const [algoBalance, setAlgoBalance] = useState(0);
  const [assetBalance, setAssetBalance] = useState(0);
  const [totalMarvins, setTotalMarvins] = useState(0);
  const [totalAlgoforce, setTotalAlgoforce] = useState(0);
  const [totalRipley, setTotalRipley] = useState(0);
  const [balance, setBalance] = useState([0, 0, 0, 0]);
  const [claimData, setClaimData] = useState({
    algo_force: 0,
    marvin: 0,
    sweet_ripley: 0,
  });

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        // Step 0: Check if activeAccount is available
        if (!activeAccount) {
          // Reset the state or perform cleanup actions
          setAlgoBalance(0);
          setAssetBalance(0);
          setTotalMarvins(0);
          setTotalAlgoforce(0);
          setTotalRipley(0);
          setBalance([0, 0, 0, 0, 0]);
          setLoading(false);
          return;
        }

        // Step 0: Get all claim assets

        {
          axios.get(`${constant.API_ENDPOINT}/claim/`).then((res) => {
            setClaimData(res.data);
          });
        }
        // Step 1: Get the wallet address from wallet

        const walletAddress = activeAccount.address;

        // Step 2: Fetch data from Algorand Node
        const response = await fetch(
          `https://mainnet-idx.algonode.cloud/v2/accounts/${walletAddress}`
        );
        const data = await response.json();

        // Step 3: Extract the Algo balance and asset balance
        const algoAmount = data.account?.["amount"];
        setAlgoBalance(algoAmount ? Math.trunc(algoAmount / 10 ** 6) : 0);

        // grab the cube amount
        const assetId = constant.CUBE_ASSET_ID;
        const assetData = data.account?.assets.find(
          (asset) => asset["asset-id"] === assetId
        );
        const assetAmount = assetData?.amount;
        setAssetBalance(assetAmount || 0);

        //Check number of marvins
        let totalMarvinsCount = 0;

        Object.keys(constant.ASSET_NAMES).forEach((assetId) => {
          const assetIdNumber = parseInt(assetId, 10);
          const foundAsset = data.account?.assets.find(
            (asset) => asset["asset-id"] === assetIdNumber
          );

          // Check if the asset is found and the amount is greater than 0
          if (foundAsset && foundAsset.amount > 0) {
            totalMarvinsCount++;
          }
        });
        setTotalMarvins(totalMarvinsCount);

        //Check number of Algoforce
        let totalAlgoforceCount = 0;

        constant.ALGO_FORCE_IDS.forEach((assetId) => {
          const assetIdNumber = parseInt(assetId, 10);
          const foundAsset = data.account?.assets.find(
            (asset) => asset["asset-id"] === assetIdNumber
          );

          // Check if the asset is found and the amount is greater than 0
          if (foundAsset && foundAsset.amount > 0) {
            totalAlgoforceCount++;
          }
        });

        setTotalAlgoforce(totalAlgoforceCount);

        //Check number of Ripley
        let totalRipleyCount = 0;

        constant.SWEET_RIPLEY_IDS.forEach((assetId) => {
          const assetIdNumber = parseInt(assetId, 10);
          const foundAsset = data.account?.assets.find(
            (asset) => asset["asset-id"] === assetIdNumber
          );

          // Check if the asset is found and the amount is greater than 0
          if (foundAsset && foundAsset.amount > 0) {
            totalRipleyCount++;
          }
        });

        setTotalRipley(totalRipleyCount);

        setBalance([
          algoAmount ? Math.trunc(algoAmount / 10 ** 6) : 0,
          assetAmount || 0,
          totalMarvinsCount,
          totalAlgoforceCount,
          totalRipleyCount,
        ]);

        special_data(walletAddress).then((res) => {
          // Logging the content of the response (res) to the console

          // Updating the state with the fetched user assets

          setSpecialBalance(res);
          const forceCubes = res.filter(
            (obj) => obj.token_name === "Force Cube"
          );

          // Extract numbers from descriptions, sum them up, and set the total
          const total = forceCubes.reduce((acc, obj) => {
            const matches = obj.description.match(/\d+/g); // Extract numbers
            const number = matches ? parseInt(matches[0]) : 0; // Parse the number
            return acc + number; // Accumulate the total
          }, 0);

          setForceCubeFromTraits(total);
        });

        //catch errors
        setLoading(false);
      } catch (error) {
        console.error("Error fetching account info:", error);
        setLoading(false);
      }
    };

    fetchAccountInfo();
  }, [activeAccount]);

  async function FCOptin() {
    const walletAddress = activeAccount.address;
    const assetID = constant.CUBE_ASSET_ID;
    if (!walletAddress) {
      toast.error("Please connect your wallet first!");
      return;
    }
    setIsClicked(true);
    const transaction = await force_cube_optin(walletAddress, assetID);
    if (!transaction) {
      toast.error("Something went wrong while creating the swap request!");
      setIsClicked(false);
      return;
    }

    try {
      // Sign transaction using useWallet module
      const signedTxn = await signTransactions([
        algosdk.encodeUnsignedTransaction(transaction),
      ]);

      const txId = await sendTransactions(signedTxn);
      console.log("Transaction ID:", txId);
    } catch (error) {
      // Handle errors
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  return (
    <div className="cubestation">
      <div className="balance">
        {/* Group the first two elements */}
        <div className="balance-group">
          {`${algoBalance}`}
          <img className="coin" src={algo_icon} alt="cube-icon" />
        </div>
        {/* Group the next two elements */}
        <div className="balance-group">
          {`${assetBalance}`}
          <img className="coin" src={cube_icon} alt="cube-icon" />
        </div>
        <div className="balance-group">
          {`${totalMarvins}`}
          <img className="coin" src={marvin_icon} alt="cube-icon" />
        </div>
        <div className="balance-group">
          {`${totalAlgoforce}`}
          <img className="coin" src={AF_icon} alt="cube-icon" />
        </div>
        <div className="balance-group">
          {`${totalRipley}`}
          <img className="coin" src={ripley_icon} alt="cube-icon" />
        </div>
      </div>
      <div className="weeklyfarm">
        <h3>CUBE STATION</h3>
        <div className="copycubeid">
          <img className="coin" src={cube_icon} alt="cube-icon" />
          {constant.CUBE_ASSET_ID}{" "}
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={FCOptin}
          >
            OPT-IN
          </button>
        </div>
        <ForceCube balance={balance} />

        <div className="fromtraitsclaimer">
          <div>EXTRA WEEKLY DROP FROM SPECIAL TRAITS </div>
          <div className="dividercubes">
            {" "}
            {forceCubeFromTraits}{" "}
            <img className="coin" src={cube_icon} alt="cube-icon" />
          </div>
        </div>

        <div className="totalclaimed">
          <h5 className="text-teal-600/90 italic text-center font-semibold">
            Total Claimed
          </h5>
          <div className="claimedresults">
            <div className="results">
              <p className="text-primary-white">
                <img className="coin" src={AF_icon} alt="cube-icon" />
                <span className="text-teal-500">{claimData.algo_force}</span>/
                {constant.ALGO_FORCE_IDS.length}
              </p>
              <p className="text-primary-white">
                <img className="coin" src={marvin_icon} alt="cube-icon" />
                <span className="text-teal-500">{claimData.marvin}</span>/
                {constant.ASSET_IDS.length}
              </p>
              <p className="text-primary-white">
                <img className="coin" src={ripley_icon} alt="cube-icon" />
                <span className="text-teal-500">{claimData.sweet_ripley}</span>/
                {constant.SWEET_RIPLEY_IDS.length}
              </p>
            </div>
            <div className="lottery">
              <h6>UNCLAIMED LOTTERY</h6>
              <button className="btn btn-info btn-sm">COMING SOON</button>
              {constant.ALGO_FORCE_IDS.length +
                constant.ASSET_IDS.length +
                constant.SWEET_RIPLEY_IDS.length -
                (claimData.algo_force +
                  claimData.marvin +
                  claimData.sweet_ripley)}{" "}
              CUBES
            </div>
          </div>
        </div>
        <div className="resettime">
          <h6>Resetting every Sunday at 9PM UTC.</h6>
        </div>
      </div>
    </div>
  );
};
