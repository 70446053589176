import React, { useEffect, useState } from "react";

const CATEGORY_ORDER = [
  "Background",
  "Body",
  "Clothes",
  "Hair",
  "Mouth",
  "Eyes",
  "Special1",
  "Hand",
];

const getTraitImagePath = (traitCategory, traitName) => {
  return `/traits/${traitCategory}/${traitName}.webp`;
};

const DynamicAssetImage = ({ traitData }) => {
  const [traitLayers, setTraitLayers] = useState([]);

  useEffect(() => {
    // Log the incoming traitData for debugging

    // Ensure traitData is an object
    if (traitData && typeof traitData === "object") {
      // Filter out the 'Special2' and 'Metal' categories and sort by the CATEGORY_ORDER
      const filteredTraits = Object.entries(traitData)
        .filter(
          ([category, name]) => category !== "Special2" && category !== "Metal"
        ) // Remove Special2 and Metal categories
        .sort(([categoryA], [categoryB]) => {
          return (
            CATEGORY_ORDER.indexOf(categoryA) -
            CATEGORY_ORDER.indexOf(categoryB)
          ); // Sort by category order
        });

      // Map the filtered traits to their corresponding image paths
      const layers = filteredTraits.map(([category, name]) => {
        const path = getTraitImagePath(category, name);

        return path;
      });

      setTraitLayers(layers);
    } else {
      console.warn("traitData is not an object:", traitData);
      setTraitLayers([]); // Set empty layers if traitData is invalid
    }
  }, [traitData]);

  return (
    <>
      {traitLayers.length === 0 ? (
        <p>No traits available or invalid data</p>
      ) : (
        traitLayers.map((layer, index) => (
          <img
            key={index}
            src={layer}
            loading="lazy"
            alt={`Trait layer ${index}`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
            onContextMenu={(e) => e.preventDefault()} // Disable right-click menu
          />
        ))
      )}
    </>
  );
};

export default DynamicAssetImage;
