// Import necessary libraries and components

import { Link } from "react-router-dom";
import React from "react";
import "./Footer.css";
import { FaXTwitter } from "react-icons/fa6";
import { TbBrandDiscord } from "react-icons/tb";
import { BsInstagram } from "react-icons/bs";
import { SiTiktok } from "react-icons/si";
// Main component function
export default function Contact() {
  return (
    <>
      <div className="burncontainer">
        <div className="burnNFT">
          <h2>CONTACT</h2>

          <>
            <div className="closeposition">
              <Link
                to="/"
                type="button"
                className="btn-close"
                aria-label="Close"
              ></Link>
            </div>

            <div className="contactdescription">
              For any questions or collaborations or issues contact us directly
              on our social networks
            </div>
            <div>
              <div className="socials">
                <a href="https://x.com/MaarsComics" target="blank">
                  <FaXTwitter className="socialimg" />
                </a>

                <a href="https://discord.gg/DvXzSBX7bN" target="blank">
                  <TbBrandDiscord className="socialimg" />
                </a>
                <a
                  href="https://www.instagram.com/studio_maars/"
                  target="blank"
                >
                  <BsInstagram className="socialimg" />
                </a>
                <a href="https://www.tiktok.com/@studio_maars" target="blank">
                  <SiTiktok className="socialimg" />
                </a>
              </div>
            </div>
            <div className="contactdescription">
              Or use the information below:
            </div>
            <div className="company">
              <div className="contactlogo">
                <img src="/logosm.png" alt="logo"></img>
              </div>
              Studio Maars, trade for digital tehnologies, design and art{" "}
              <div>
                <p>Owner - Stjepko Marenic</p>
                <p>
                  Address of headquarters - Biskupa Kvirina 2, Sisak, Croatia
                </p>
                <p>Contact email - maarscomics(at)gmail.com</p>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
