import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { GetBalance } from "./GetBalance";
import ForceCube from "./BuyFC.tsx";
import cube from "../assets/navigation/cube.webp";

const Cubestation = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <img
        src={cube}
        alt="cubeshop"
        className="cubeshop"
        onClick={handleOffcanvas}
      />

      <Offcanvas
        show={showOffcanvas}
        onHide={handleOffcanvas}
        placement="start"
        className="cubeoffcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src="/logo.png" alt="Mental Marvin" className="logosmall" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <GetBalance />
          <ForceCube />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Cubestation;
