export const incompatibleTraitRules = [
  {
    trait1: "Body",
    value1: "banged",
    trait2: "Hair",
    value2: "black",
  },
  {
    trait1: "Hair",
    value1: "black",
    trait2: "Body",
    value2: "banged",
  },
  {
    trait1: "Eyes",
    value1: "neo",
    trait2: "Hair",
    value2: ["black", "frugal", "hoggy", "lsd", "rick"],
  },
  {
    trait1: "Hair",
    value1: ["black", "frugal", "hoggy", "lsd", "rick"],
    trait2: "Eyes",
    value2: "neo",
  },
  {
    trait1: "Special1",
    value1: "doofmask",
    trait2: "Hair",
    value2: ["fish", "bucket", "pencils"],
  },
  {
    trait1: "Hair",
    value1: ["fish", "bucket", "pencils"],
    trait2: "Special1",
    value2: "doofmask",
  },
  {
    trait1: "Special1",
    value1: "horns",
    trait2: "Hair",
    value2: ["phones", "dibbley", "panties", "bucket", "fedora", "bears"],
  },
  {
    trait1: "Hair",
    value1: ["phones", "dibbley", "panties", "bucket", "fedora", "bears"],
    trait2: "Special1",
    value2: "horns",
  },
  {
    trait1: "Special1",
    value1: "mask",
    trait2: "Hair",
    value2: ["panties", "bucket", "fish", "black", "bush", "fedora", "bears"],
  },
  {
    trait1: "Hair",
    value1: ["panties", "bucket", "fish", "black", "bush", "fedora", "bears"],
    trait2: "Special1",
    value2: "mask",
  },
  {
    trait1: "Special1",
    value1: "mask",
    trait2: "Mouth",
    value2: ["cigar", "fun"],
  },
  {
    trait1: "Mouth",
    value1: ["cigar", "fun"],
    trait2: "Special1",
    value2: "mask",
  },
  {
    trait1: "Special1",
    value1: "sirchain",
    trait2: "Clothes",
    value2: ["cyber", "snaketie"],
  },
  {
    trait1: "Clothes",
    value1: ["cyber", "snaketie"],
    trait2: "Special1",
    value2: "sirchain",
  },
  {
    trait1: "Special1",
    value1: "nuggie",
    trait2: "Clothes",
    value2: ["flymarv", "snaketie"],
  },
  {
    trait1: "Clothes",
    value1: ["flymarv", "snaketie"],
    trait2: "Special1",
    value2: "nuggie",
  },
  {
    trait1: "Special1",
    value1: "nuggie",
    trait2: "Mouth",
    value2: "billy",
  },
  {
    trait1: "Mouth",
    value1: "billy",
    trait2: "Special1",
    value2: "nuggie",
  },
  {
    trait1: "Special1",
    value1: "yippeekiyay",
    trait2: "Hair",
    value2: (value) => value !== "phones",
  },
  {
    trait1: "Hair",
    value1: (value) => value !== "phones",
    trait2: "Special1",
    value2: "yippeekiyay",
  },
  {
    trait1: "Special1",
    value1: "santabothead",
    trait2: "Hair",
    value2: (value) => value !== "none",
  },
  {
    trait1: "Hair",
    value1: (value) => value !== "none",
    trait2: "Special1",
    value2: "santabothead",
  },
  {
    trait1: "Special1",
    value1: "topaf",
    trait2: "Hair",
    value2: (value) => value !== "none",
  },
  {
    trait1: "Hair",
    value1: (value) => value !== "none",
    trait2: "Special1",
    value2: "topaf",
  },
  {
    trait1: "Special1",
    value1: "purplehat",
    trait2: "Hair",
    value2: (value) => value !== "none",
  },
  {
    trait1: "Hair",
    value1: (value) => value !== "none",
    trait2: "Special1",
    value2: "purplehat",
  },
  {
    trait1: "Special1",
    value1: "festivesocks",
    trait2: "Hair",
    value2: (value) => value !== "socks",
  },
  {
    trait1: "Hair",
    value1: (value) => value !== "socks",
    trait2: "Special1",
    value2: "festivesocks",
  },
  {
    trait1: "Special1",
    value1: "frozenginger",
    trait2: "Mouth",
    value2: (value) => value !== "ginger",
  },
  {
    trait1: "Mouth",
    value1: (value) => value !== "ginger",
    trait2: "Special1",
    value2: "frozenginger",
  },
  {
    trait1: "Special1",
    value1: "redsuit",
    trait2: "Clothes",
    value2: (value) => value !== "none",
  },
  {
    trait1: "Clothes",
    value1: (value) => value !== "none",
    trait2: "Special1",
    value2: "redsuit",
  },
  {
    trait1: "Special1",
    value1: "yellowsnow",
    trait2: "Clothes",
    value2: (value) => !["pelt", "bllingpelt"].includes(value),
  },
  {
    trait1: "Clothes",
    value1: (value) => !["pelt", "bllingpelt"].includes(value),
    trait2: "Special1",
    value2: "yellowsnow",
  },
  {
    trait1: "Special1",
    value1: "bulbs",
    trait2: "Hair",
    value2: (value) => !["classic", "blue", "ginger"].includes(value),
  },
  {
    trait1: "Hair",
    value1: (value) => !["classic", "blue", "ginger"].includes(value),
    trait2: "Special1",
    value2: "bulbs",
  },
  {
    trait1: "Special1",
    value1: "frogloon",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket"],
    trait2: "Special1",
    value2: "frogloon",
  },
  {
    trait1: "Special1",
    value1: "saw",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket"],
    trait2: "Special1",
    value2: "saw",
  },
  {
    trait1: "Special1",
    value1: "tiny",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket"],
    trait2: "Special1",
    value2: "tiny",
  },
  {
    trait1: "Special1",
    value1: "jorge",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket"],
    trait2: "Special1",
    value2: "jorge",
  },
  {
    trait1: "Special1",
    value1: "feather",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket"],
    trait2: "Special1",
    value2: "feather",
  },
  {
    trait1: "Special1",
    value1: "redcube",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket"],
    trait2: "Special1",
    value2: "redcube",
  },
  {
    trait1: "Special1",
    value1: "ripleyarm",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket"],
    trait2: "Special1",
    value2: "ripleyarm",
  },
  {
    trait1: "Special1",
    value1: "gary",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket"],
    trait2: "Special1",
    value2: "gary",
  },
  {
    trait1: "Special1",
    value1: "coop",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket"],
    trait2: "Special1",
    value2: "coop",
  },
  {
    trait1: "Special1",
    value1: "embryo",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket", "snaketie"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket", "snaketie"],
    trait2: "Special1",
    value2: "embryo",
  },
  {
    trait1: "Special1",
    value1: "lsd",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket"],
    trait2: "Special1",
    value2: "lsd",
  },
  {
    trait1: "Special1",
    value1: "golem",
    trait2: "Clothes",
    value2: ["plungers", "goldplunge", "strjacket"],
  },
  {
    trait1: "Clothes",
    value1: ["plungers", "goldplunge", "strjacket"],
    trait2: "Special1",
    value2: "golem",
  },
  {
    trait1: "Special1",
    value1: "mentalscarf",
    trait2: "Mouth",
    value2: ["cigar", "fun", "zipper"],
  },
  {
    trait1: "Mouth",
    value1: ["cigar", "fun", "zipper"],
    trait2: "Special1",
    value2: "mentalscarf",
  },
  {
    trait1: "Special1",
    value1: "butts",
    trait2: "Mouth",
    value2: ["cigar", "fun", "zipper"],
  },
  {
    trait1: "Mouth",
    value1: ["cigar", "fun", "zipper"],
    trait2: "Special1",
    value2: "butts",
  },
  {
    trait1: "Special1",
    value1: "yeticone",
    trait2: "Hair",
    value2: ["phones", "bears"],
  },
  {
    trait1: "Hair",
    value1: ["phones", "bears"],
    trait2: "Special1",
    value2: "yeticone",
  },
  {
    trait1: "Special1",
    value1: "yetiwatch",
    trait2: "Clothes",
    value2: "cyber",
  },
  {
    trait1: "Clothes",
    value1: "cyber",
    trait2: "Special1",
    value2: "yetiwatch",
  },
  {
    trait1: "Special1",
    value1: "yetiwatch",
    trait2: "Hand",
    value2: ["alex", "onepunch"],
  },
  {
    trait1: "Hand",
    value1: ["alex", "onepunch"],
    trait2: "Special1",
    value2: "yetiwatch",
  },
  {
    trait1: "Special1",
    value1: "shades",
    trait2: "Eyes",
    value2: ["neo", "robo"],
  },
  {
    trait1: "Eyes",
    value1: ["neo", "robo"],
    trait2: "Special1",
    value2: "shades",
  },
  {
    trait1: "Special1",
    value1: "fishers",
    trait2: "Eyes",
    value2: ["neo", "robo"],
  },
  {
    trait1: "Eyes",
    value1: ["neo", "robo"],
    trait2: "Special1",
    value2: "fishers",
  },
  {
    trait1: "Special1",
    value1: "mortality",
    trait2: "Eyes",
    value2: ["neo", "robo", "hamster"],
  },
  {
    trait1: "Eyes",
    value1: ["neo", "robo", "hamster"],
    trait2: "Special1",
    value2: "mortality",
  },
  {
    trait1: "Body",
    value1: "banged",
    trait2: "Hair",
    value2: "black",
  },
  {
    trait1: "Hair",
    value1: "black",
    trait2: "Body",
    value2: "banged",
  },
  {
    trait1: "Clothes",
    value1: "junk",
    trait2: "Body",
    value2: "redioactive",
  },
  {
    trait1: "Body",
    value1: "redioactive",
    trait2: "Clothes",
    value2: "junk",
  },
  {
    trait1: "Special1",
    value1: "monocufs",
    trait2: "Eyes",
    value2: "neo",
  },
  {
    trait1: "Eyes",
    value1: "neo",
    trait2: "Special1",
    value2: "monocufs",
  },
  {
    trait1: "Eyes",
    value1: "neo",
    trait2: "Hair",
    value2: ["black", "frugal", "hoggy", "lsd", "rick"],
  },
  {
    trait1: "Hair",
    value1: ["black", "frugal", "hoggy", "lsd", "rick"],
    trait2: "Eyes",
    value2: "neo",
  },
  {
    trait1: "Special1",
    value1: "sirchain",
    trait2: "Body",
    value2: "mingo",
  },
  {
    trait1: "Body",
    value1: "mingo",
    trait2: "Special1",
    value2: "sirchain",
  },
  {
    trait1: "Special1",
    value1: "sirchain",
    trait2: "Mouth",
    value2: ["monster", "billy", "dibbley", "ginger", "hoggy"],
  },
  {
    trait1: "Mouth",
    value1: ["monster", "billy", "dibbley", "ginger", "hoggy"],
    trait2: "Special1",
    value2: "sirchain",
  },
  {
    trait1: "Special1",
    value1: "bots",
    trait2: "Hair",
    value2: (value) => value !== "none",
  },
  {
    trait1: "Hair",
    value1: (value) => value !== "none",
    trait2: "Special1",
    value2: "bots",
  },
  {
    trait1: "Special1",
    value1: "hairyp",
    trait2: "Clothes",
    value2: (value) => value !== "none",
  },
  {
    trait1: "Clothes",
    value1: (value) => value !== "none",
    trait2: "Special1",
    value2: "hairyp",
  },
  {
    trait1: "Special1",
    value1: "brokenheart",
    trait2: "Clothes",
    value2: (value) => value !== "none",
  },
  {
    trait1: "Clothes",
    value1: (value) => value !== "none",
    trait2: "Special1",
    value2: "brokenheart",
  },
  {
    trait1: "Special1",
    value1: "brokenheart",
    trait2: "Body",
    value2: ["glass", "hijacked", "parasite"],
  },
  {
    trait1: "Body",
    value1: ["glass", "hijacked", "parasite"],
    trait2: "Special1",
    value2: "brokenheart",
  },
];
