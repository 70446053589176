// Import necessary libraries and components
import { useWallet } from "@txnlab/use-wallet";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  API_ENDPOINT,
  ALGO_EXPLORER_URL,
  NFTGROUP1,
  NFTGROUP2,
  NFTGROUP3,
  NFTGROUP1AMOUNT,
  NFTGROUP2AMOUNT,
  NFTGROUP3AMOUNT,
} from "../../constants.ts";
import {
  burn_nft_request_txs,
  getBalance,
  getItemsToBurn,
} from "../utilities.ts";
import { toast } from "react-toastify";
import Loader from "../Loader.js";
import { encode } from "uint8-to-base64";
import cube_icon from "../../assets/cube.webp";
import React from "react";
import algosdk from "algosdk";
import AssetBoxForSwap from "../assetboxoptimal.tsx";
import "./burnnft.css";
import marvin4 from "../../assets/marvin/marvin4.webp";

// Main component function
export default function BurnNFT() {
  // State variables
  const [loading, setLoading] = useState(true);
  const [userAssets, setUserAssets] = useState<number[]>([]);
  const [assetNames, setAssetNames] = useState<{ [key: number]: string }>({});
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [connectType, setWalletType] = useState("");
  const [userBalance, setUserBalance] = useState([0, 0]);
  const [amount, setAmount] = useState(0);
  const [isBurnClicked, setIsBurnClicked] = useState(false);
  const [burnNFTId, setBurnNFTId] = useState("");
  const { activeAccount, signTransactions } = useWallet();
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [groupAmount, setGroupAmount] = useState(0);
  const [fetchedAssets, setFetchedAssets] = useState(new Set());
  // Define a map to store asset IDs and their corresponding group amounts
  const assetGroups: { [key: number]: number } = {};

  // Assign amounts for group 1 asset IDs
  NFTGROUP1.forEach((assetID) => {
    assetGroups[assetID] = NFTGROUP1AMOUNT;
  });

  // Assign amounts for group 2 asset IDs
  NFTGROUP2.forEach((assetID) => {
    assetGroups[assetID] = NFTGROUP2AMOUNT;
  });

  NFTGROUP3.forEach((assetID) => {
    assetGroups[assetID] = NFTGROUP3AMOUNT;
  });

  const handleAssetClick = (asset_id, index) => {
    setSelectedAssetIndex(index);
    setSelectedAssetId(asset_id);
    const groupAmount = assetGroups[asset_id] || 0;
    setGroupAmount(groupAmount);
  };

  // useEffect to load user's wallet and connection type from localStorage
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAddressLocal = activeAccount?.address;
        const connectTypeLocal = activeAccount?.providerId;

        if (userAddressLocal && connectTypeLocal) {
          setWalletAddress(userAddressLocal);
          setWalletType(connectTypeLocal);

          if (userAddressLocal !== "") {
            const [userAssets, balance] = await Promise.all([
              getItemsToBurn(userAddressLocal),
              getBalance(userAddressLocal),
            ]);

            // Updating the state with the fetched user assets and balance

            setUserAssets(userAssets);

            setUserBalance(balance);
          }
        }
      } catch (error) {
        console.error("Error fetching account information:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeAccount]);

  const handleNameReceived = (name, asset_id) => {
    setAssetNames((prevNames) => ({
      ...prevNames,
      [asset_id]: prevNames[asset_id] !== name ? name : prevNames[asset_id],
    }));

    setFetchedAssets((prevFetchedAssets) =>
      new Set(prevFetchedAssets).add(asset_id)
    );
  };
  // Function to handle NFT burn
  async function handleBurnNFT(asset_id) {
    if (!walletAddress) {
      toast.info("Please connect your wallet first!");
      return;
    }
    /* if (amount === 0) {
      toast.info("You need to buy at least " + CUBE_BUY_AMOUNT + "FC!");
      setIsBuyClicked(false);
      return;
    } */
    setIsBurnClicked(true);

    try {
      // Get NFT burn trx

      const transactions = await burn_nft_request_txs(
        walletAddress,
        groupAmount,
        asset_id
      );
      if (!transactions) {
        toast.info("Something went wrong while creating the transactions!");
        setIsBurnClicked(false);
        return;
      }

      // Initialize variables for signed transactions
      let user_nft_send_tx: any; // 0
      let cube_transfer_tx: any; // 1

      // Sign transactions using useWallet module
      const signedTransactions = await signTransactions([
        algosdk.encodeUnsignedTransaction(transactions[0]),
        algosdk.encodeUnsignedTransaction(transactions[1]),
      ]);

      // Extract transactions
      user_nft_send_tx = encode(transactions[0].toByte());
      cube_transfer_tx = encode(transactions[1].toByte());
      const tx_signed = encode(signedTransactions[0]);
      setBurnNFTId("");

      if (user_nft_send_tx && cube_transfer_tx) {
        const res = await axios.post(`${API_ENDPOINT}/burn-nft/`, {
          user_nft_send_tx,
          cube_transfer_tx,
          tx_signed,
        });

        // Handle server response
        if (res.status === 200) {
          toast.success("Successfully juiced!");
          setIsBurnClicked(false);
          setBurnNFTId(transactions[1].txID().toString());
          setAmount(0);

          // Update user balance after purchase
          getBalance(walletAddress).then((res) => {
            setUserBalance(res);
          });
        } else {
          toast.error("Something went wrong while buying!");
          setIsBurnClicked(false);
        }
      } else {
        toast.error("Something went wrong while buying!");
        setIsBurnClicked(false);
      }
    } catch (error: any) {
      // Handle errors
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsBurnClicked(false);
      return;
    }
  }

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="burncontainer">
      <div className="burnNFT">
        {userAssets.length === 0 ? (
          <div className="nowalletswapper">
            <p></p>
            <h4>
              Juicing a specific collectable will send it to 4 Orange Co. and
              you will recieve Cubes for it.
            </h4>
            <img src={marvin4} alt="Mental Marvin" className="marvinimg4" />

            <h5>Or maybe you just need to connect your wallet?</h5>
            <div className="closeposition">
              <Link
                to="/"
                type="button"
                className="btn-close"
                aria-label="Close"
              ></Link>
            </div>
          </div>
        ) : (
          <>
            <div className="closeposition">
              <Link
                to="/"
                type="button"
                className="btn-close"
                aria-label="Close"
              ></Link>
            </div>

            <h3>JUICE NFTs FOR CUBES</h3>
            <p>
              Juicing a NFT will send it to 4 Orange Co. and you will recieve
              Cubes for it.
            </p>

            <div className="juicegrid">
              {userAssets.map((asset_id, index) => {
                const groupAmount = assetGroups[asset_id] || 0;
                return (
                  <div
                    key={index}
                    className={`juicegriditem ${
                      selectedAssetIndex === index ? "selected" : ""
                    }`}
                    onClick={() => handleAssetClick(asset_id, index)}
                  >
                    <div className="cubejuice">
                      {groupAmount} <img src={cube_icon} alt="cube-icon" />
                    </div>

                    <AssetBoxForSwap
                      asset_id={asset_id}
                      onNameReceived={
                        !fetchedAssets.has(asset_id)
                          ? handleNameReceived
                          : undefined
                      }
                      onTraitsReceived={undefined}
                      onImageUrlReceived={undefined}
                    />
                    <div>{assetNames[asset_id]}</div>
                  </div>
                );
              })}
            </div>

            <div className="juicecontainer">
              <div className="buyicons">
                <div className="">
                  <span className="">{groupAmount}</span>
                  <img className="coin" src={cube_icon} alt="cube-icon" />
                </div>
              </div>
              <div className="buycubesbutton">
                {isBurnClicked ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-info btn-lg"
                      disabled
                    >
                      <div
                        className="spinner-border text-light mr-2"
                        role="status"
                      ></div>
                    </button>
                    <p>Sign the Transaction</p>
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn btn-info btn-lg"
                    onClick={() =>
                      handleBurnNFT(userAssets[selectedAssetIndex])
                    }
                  >
                    JUICE
                  </button>
                )}
              </div>
              {burnNFTId !== "" && (
                <div className="">
                  <span className="">Successfully juiced</span>
                  <p className="text-primary-white">
                    Tx ID:{" "}
                    <a
                      href={`${ALGO_EXPLORER_URL}/tx/${burnNFTId}`}
                      target="_blank"
                      rel="noreferrer"
                      className=""
                    >
                      {burnNFTId.substring(0, 5)}...
                      {burnNFTId.substring(
                        burnNFTId.length - 5,
                        burnNFTId.length
                      )}
                    </a>
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
