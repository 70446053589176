import axios from "axios";
import { TbHelpHexagon } from "react-icons/tb";
import { API_ENDPOINT } from "../constants.ts";
import React, { useState, useRef, useEffect } from "react";
import "./Styles.css";
import ConnectDropdown from "./ConnectDropdown";
import Cubestation from "./Cubestation";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { Navigation, Scrollbar, Autoplay, EffectFade } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import Loading from "./Loader";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import AssetBoxForSwap from "../components/asset_box_for_swap.tsx";
import sitemain from "../assets/navigation/sitemain.webp";
import sitemainL from "../assets/navigation/sitemainL.webp";
import sitemainM from "../assets/navigation/sitemainM.webp";
import sitemainR from "../assets/navigation/sitemainR.webp";
import orange from "../assets/navigation/4o.webp";
import af from "../assets/navigation/af.webp";
import asylum from "../assets/navigation/asylum.webp";
import catiitout from "../assets/navigation/catitout.webp";
import character from "../assets/navigation/character2.webp";
import cube from "../assets/navigation/cube.webp";
import flats from "../assets/navigation/flats.webp";
import hero from "../assets/navigation/hero.webp";
import chair from "../assets/navigation/chair.webp";
import afcar from "../assets/navigation/afcar2.webp";
import sewer from "../assets/navigation/sewer2.webp";
import smoke from "../assets/navigation/smoke.webp";
import glitch from "../assets/navigation/glitch.jpg";
import kiosko from "../assets/navigation/market.webp";
import collections from "../assets/navigation/collections.jpg";
import kioskc from "../assets/navigation/kioskclosed.webp";
import traitshop from "../assets/navigation/traitshop2.webp";
import minter from "../assets/commercial/algominter2.png";
import algopy from "../assets/commercial/algo.png";
import Video from "./Video";
import Snow from "./utils/snow.js";

const messages = [
  "Pstt. I heard there's some comics here.",
  "Best JUNK in town!",
  "Marvin kicked those AF!",
  "I have bears here",
  "Just go Mental and that's it!",
  "I got hired bruw!",
];

function Navbar() {
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);
  SwiperCore.use([Navigation, Scrollbar, Autoplay, EffectFade]);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );
  const [afmentalAssetId, setAfmentalAssetId] = useState({
    ai: 0,
    s: "n",
  });
  const [isVisible, setIsVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const swiperRef = useRef(null);

  const handleClickOutside = (e) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(e.target)
    ) {
      setDropdownOpen(false);
    }
  };

  const togglePopover = () => {
    setShowPopover(!showPopover);

    // Hide the popover after a few seconds
    setTimeout(() => {
      setShowPopover(false);
    }, 3000); // 3 seconds
  };
  const togglePopover2 = () => {
    setShowPopover2(!showPopover);

    // Hide the popover after a few seconds
    setTimeout(() => {
      setShowPopover2(false);
    }, 3000); // 3 seconds
  };

  const handleCandyClick = () => {
    // Navigate to the 3rd slide (index 2)
    swiperRef.current.swiper.slideTo(2);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    async function checkAfMentalAssetId() {
      const res = await axios.get(`${API_ENDPOINT}/mental-af/`);
      if (res.status === 200) {
        setAfmentalAssetId(res.data);
      } else {
        setAfmentalAssetId({
          ai: 0,
          s: "n",
        });
      }
    }
    {
      checkAfMentalAssetId();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 1024px)").matches);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageClick = () => {
    // Toggle pop-up visibility
    setPopupOpen(!popupOpen);
  };

  useEffect(() => {
    const images = [
      sitemain,
      orange,
      af,
      asylum,
      catiitout,
      chair,
      cube,
      flats,
      kiosko,
      kioskc,
      afcar,
      traitshop,
      minter,
      algopy,
    ];

    const promises = images.map((imgSrc) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imgSrc;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(promises)
      .then(() => setAllImagesLoaded(true))
      .catch((error) => console.error("Error loading images:", error));
  }, []);

  //CHAT BUBBLES
  /*  useEffect(() => {
    const showChatBubble = () => {
      setIsVisible(true);
      setCurrentMessage(messages[Math.floor(Math.random() * messages.length)]);

      setTimeout(() => {
        setIsVisible(false);
      }, 3000); 

     
      setTimeout(() => {
        showChatBubble();
      }, 15000); 
    };

    
    const timer = setTimeout(() => {
      showChatBubble();
    }, 0); 
    return () => clearTimeout(timer); 
  }, []); */

  return (
    <div className="navcontainer">
      {!allImagesLoaded ? (
        <div className="navloading">
          <h3>Loading your Mental Marvin Experience</h3> <Loading />
          <p>If you experience issues click below to find a way to reach us.</p>
        </div>
      ) : (
        <div className="navbar">
          {isMobile ? (
            <>
              <Swiper
                ref={swiperRef}
                navigation={{
                  prevEl: ".arrow-left",
                  nextEl: ".arrow-right",
                  disabledClass: "swiper-button-disabled",
                }}
                scrollbar
                className="mainswiper"
                initialSlide={1}
              >
                <SwiperSlide>
                  <img
                    src={sitemainL}
                    alt="Mental Marvin"
                    className="mainimgswipe"
                  />
                  <div className="ripperspective">
                    <div className="ripvideo">
                      <Video />
                    </div>
                  </div>
                  {popupOpen && (
                    <div className="popups">
                      <div className="popup7">
                        <h4>DYNAMIC CONTENT</h4>
                        <h6>
                          Don't just read the comic, be a part of one! As you
                          gather more comics you will have option to interact
                          with more things on the website.
                        </h6>
                      </div>
                    </div>
                  )}
                  <img src={sewer} alt="sewer" className="sewer2" />
                  <img src={catiitout} alt="catitout" className="catitout" />
                  <img src={hero} alt="hero" className="hero" />
                  <img
                    src={afcar}
                    alt="afcar"
                    className="afcar"
                    onClick={togglePopover2}
                  />

                  {showPopover2 && (
                    <div className="popover-christmas2">
                      CRIME SCENE! MOVE AWAY!
                    </div>
                  )}
                </SwiperSlide>
                {/* ///MID SCREEN SLIDE/// */}
                <SwiperSlide>
                  <img
                    src={sitemainM}
                    alt="Mental Marvin"
                    className="mainimgswipe"
                  />
                  <div className="userperspective">
                    <div className="users">
                      <Link to="/collections">
                        <img src={collections} alt="collections" />
                      </Link>
                    </div>
                  </div>
                  <Link to="/marvin/swapper">
                    <img
                      src={traitshop}
                      alt="Mental Marvin"
                      className="traitshop"
                    />
                  </Link>
                  <img src={cube} alt="cubeshop" className="cubeshop1" />
                  <Link to="/orange">
                    <img src={orange} alt="orange" className="orange" />
                  </Link>
                  <Cubestation />
                  {/* <img src={labs} alt="labs" className="labs" /> */}
                  <Link to="/mentalaf" className="mentalpanel">
                    <AssetBoxForSwap
                      asset_id={afmentalAssetId.ai}
                      onNameReceived={undefined}
                    />
                  </Link>

                  {/*  <img src={colector} alt="colector" className="colector" /> */}
                  <Link to="/market/comics">
                    <img src={kiosko} alt="market" className="kiosk" />
                  </Link>
                  <div className="chat-container">
                    <div className={`chat-bubble ${isVisible ? "show" : ""}`}>
                      <p>{currentMessage}</p>
                    </div>
                  </div>
                  <img src={character} alt="character" className="character" />
                  <div className="scrolling-text">
                    <p>ATOMIC LABS</p>
                  </div>
                  <div className="scrolling-text2">
                    <p>CLAIM - BUY</p>
                  </div>
                  <div className="scrolling-text3">
                    <p>CLAIM - BUY</p>
                  </div>

                  {popupOpen && (
                    <div className="popups">
                      <div className="popup">
                        {/* Your pop-up content */}

                        <h4>MENTAL MARVIN UTILITIES</h4>
                        <h6>
                          A shop for all your Mental Marvin trait utilities.{" "}
                        </h6>
                      </div>
                      <div className="popup2">
                        {/* Your pop-up content */}

                        <h4>FORCE CUBES</h4>
                        <h6>
                          Force cubes are the main fuel of Mental Marvin
                          universe. Everything you need regarding your Force
                          Cubes can be found here!{" "}
                        </h6>
                      </div>
                      <div className="popup4">
                        {/* Your pop-up content */}

                        <h4>KIOSK</h4>
                        <h6>
                          A one stop shop for all the comics, merch and other
                          collectables
                        </h6>
                      </div>
                      <div className="popup5">
                        {/* Your pop-up content */}

                        <h4>4 ORANGE CO</h4>
                        <h6>
                          This company specialized in turning enything into
                          energy and charging Energy Cubes. Juice your NFTs or
                          Traits to get Force Cubes.
                        </h6>
                      </div>

                      <div className="popup8">
                        {/* Your pop-up content */}

                        <h4>ATOMIC LABS</h4>
                        <h6>T.B.A.</h6>
                      </div>
                    </div>
                  )}
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={sitemainR}
                    alt="Mental Marvin"
                    className="mainimgswipe"
                  />
                  <img src={sewer} alt="sewer" className="sewer" />
                  <img src={smoke} alt="smoke" className="smoke" />
                  <img
                    src={chair}
                    alt="chair"
                    className="chair"
                    onClick={togglePopover}
                  />

                  {showPopover && (
                    <div className="popover-christmas">SANTA IS COMING!</div>
                  )}

                  {/* <img src={flats} alt="flats" className="flats" /> */}
                  {/*  <img src={af} alt="af" className="af" /> */}
                  {/* <img src={asylum} alt="Asylum" className="asylum" /> */}

                  {popupOpen && (
                    <div className="popups">
                      <div className="popup3">
                        {/* Your pop-up content */}

                        <h4>ALGO FORCE (TBA)</h4>

                        <h6>
                          A base for your Algo Force characters! Check your
                          collection, buy upgrades or prepare your AF for print!
                        </h6>
                      </div>
                      <div className="popup6">
                        {/* Your pop-up content */}

                        <h4>MAARS FLATS</h4>
                        <h6>T.B.A.</h6>
                      </div>
                    </div>
                  )}
                </SwiperSlide>
                {/* Add more slides if needed */}
              </Swiper>
              <Snow />
              {/*   ARROWS */}
              {/* <div className="arrow-left">
              <TbSquareArrowLeft />
            </div>
            <div className="arrow-right">
              <TbSquareArrowRight />
            </div> */}

              <div className="scrolltag">
                <MdKeyboardDoubleArrowLeft />
                SWIPE
                <MdKeyboardDoubleArrowRight />
              </div>

              <img
                src="/icons/candy.png"
                className="candy-cane"
                onClick={handleCandyClick} // Add the click handler
                alt="Candy Cane"
              />
            </>
          ) : (
            <div className="relative-position">
              <img src={sitemain} alt="Mental Marvin" className="sitemain" />
              <Snow />
              <Link to="/marvin/swapper">
                <img
                  src={traitshop}
                  alt="Mental Marvin"
                  className="traitshop"
                />
              </Link>

              <Cubestation />
              <img src={cube} alt="cubeshop" className="cubeshop1" />
              <img
                src={afcar}
                alt="afcar"
                className="afcar"
                onClick={togglePopover2}
              />

              {showPopover2 && (
                <div className="popover-christmas2">
                  CRIME SCENE! MOVE AWAY!
                </div>
              )}

              <Link to="/orange">
                <img src={orange} alt="orange" className="orange" />
              </Link>
              <img src={character} alt="character" className="character" />
              {/* <img src={colector} alt="colector" className="colector" /> */}
              {/*   <img src={asylum} alt="Asylum" className="asylum" /> */}
              <Link to="/market/comics">
                <img src={kiosko} alt="market" className="kiosk" />
              </Link>

              <img src={hero} alt="hero" className="hero" />
              <img src={sewer} alt="sewer" className="sewer" />
              <img src={smoke} alt="smoke" className="smoke" />
              <img src={sewer} alt="sewer" className="sewer2" />
              <img
                src={chair}
                alt="chair"
                className="chair"
                onClick={togglePopover}
              />

              {showPopover && (
                <div className="popover-christmas">SANTA IS COMING!</div>
              )}
              {/* <img src={labs} alt="labs" className="labs" /> */}
              <img src={catiitout} alt="catitout" className="catitout" />
              <div className="chat-container">
                <div className={`chat-bubble ${isVisible ? "show" : ""}`}>
                  <p>{currentMessage}</p>
                </div>
              </div>
              {/* <img src={af} alt="af" className="af" /> */}

              {/*   <img src={flats} alt="flats" className="flats" /> */}

              {popupOpen && (
                <div className="popups">
                  <div className="popup">
                    {/* Your pop-up content */}

                    <h4>MENTAL MARVIN UTILITIES</h4>
                    <h6>A shop for all your Mental Marvin trait utilities. </h6>
                  </div>
                  <div className="popup2">
                    {/* Your pop-up content */}

                    <h4>FORCE CUBES</h4>
                    <h6>
                      Force cubes are the main fuel of Mental Marvin universe.
                      Everything you need regarding your Force Cubes can be
                      found here!{" "}
                    </h6>
                  </div>
                  <div className="popup3">
                    {/* Your pop-up content */}

                    <h4>ALGO FORCE (TBA)</h4>

                    <h6>
                      A base for your Algo Force characters! Check your
                      collection, buy upgrades or prepare your AF for print!
                    </h6>
                  </div>
                  <div className="popup4">
                    {/* Your pop-up content */}

                    <h4>KIOSK</h4>
                    <h6>
                      A one stop shop for all the comics, merch and other
                      collectables
                    </h6>
                  </div>
                  <div className="popup5">
                    {/* Your pop-up content */}

                    <h4>4 ORANGE CO</h4>
                    <h6>
                      This company specialized in turning enything into energy
                      and charging Energy Cubes. Juice your NFTs or Traits to
                      get Force Cubes.
                    </h6>
                  </div>
                  <div className="popup6">
                    {/* Your pop-up content */}

                    <h4>MAARS FLATS</h4>
                    <h6>T.B.A.</h6>
                  </div>
                  <div className="popup7">
                    {/* Your pop-up content */}

                    <h4>DYNAMIC CONTENT</h4>
                    <h6>
                      Don't just read the comic, be a part of one! As you gather
                      more comics you will have option to interact with more
                      things on the website.
                    </h6>
                  </div>
                  <div className="popup8">
                    {/* Your pop-up content */}

                    <h4>ATOMIC LABS</h4>
                    <h6>T.B.A.</h6>
                  </div>
                </div>
              )}

              <div className="cubebutton">
                {" "}
                <Cubestation />
              </div>
              <div className="ripperspective">
                <div className="ripvideo">
                  <Video />
                </div>
              </div>

              <div className="userperspective">
                <div className="users">
                  <Link to="/collections">
                    <img src={collections} alt="collections" />
                  </Link>
                </div>
              </div>

              <div className="scrolling-text">
                <p>ATOMIC LABS</p>
              </div>
              <div className="scrolling-text2">
                <p>CLAIM - BUY</p>
              </div>
              <div className="scrolling-text3">
                <p>CLAIM - BUY</p>
              </div>

              <Link to="/mentalaf" className="mentalpanel">
                <AssetBoxForSwap
                  asset_id={afmentalAssetId.ai}
                  onNameReceived={undefined}
                />
              </Link>
              {/*   /////MAIN PANEL1//////// */}
              {/* <div className="tilted">
                <Swiper
                  className="bannerslider"
                  autoplay={{ delay: 5000, disableOnInteraction: false }}
                  loop
                  effect={"fade"}
                >
                  <SwiperSlide>
                    <a
                   
                    >
                      <img
                        src={minter}
                        alt="Mental Marvin"
                        className="mainimgswipe"
                      />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="news">
                     
                      <p>__</p>
                      <p>WEEKLY NEWS</p>
                      <p>
                        Despite the turmoil in the Algortand Community Mental
                        Marvin project get an amazing new page redesign
                      </p>

                    
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={algopy}
                      alt="Mental Marvin"
                      className="mainimgswipe"
                    />
                  </SwiperSlide>
                  
                </Swiper>
              </div> */}
            </div>
          )}
          <div className="navbuttons">
            <div className="connectbutton">
              <ConnectDropdown
                isOpen={dropdownOpen}
                onClose={() => setDropdownOpen(false)}
                ref={dropdownRef}
              />
            </div>

            <button
              type="button"
              className="btn btn-info"
              onClick={handleImageClick}
            >
              <TbHelpHexagon className="responsive-icon" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
