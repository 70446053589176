import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="cube-container">
      <div className="cube">
        <div className="face front">FORCE CUBE</div>
        <div className="face back">FORCE CUBE</div>
        <div className="face right">FORCE CUBE</div>
        <div className="face left">FORCE CUBE</div>
        <div className="face top">FORCE CUBE</div>
        <div className="face bottom">FORCE CUBE</div>
      </div>
    </div>
  );
};

export default Loader;
