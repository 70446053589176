import React, { useEffect } from "react";
import "./snow.css"; // Include the CSS for styling

const Snowfall = () => {
  useEffect(() => {
    const snowContainer = document.getElementById("snow-container");

    function createSnowflake() {
      const snowflake = document.createElement("div");
      snowflake.classList.add("snowflake");
      snowflake.textContent = "❄"; // You can replace this with a custom icon or shape

      // Randomize the position and size
      snowflake.style.left = `${Math.random() * 100}vw`;
      snowflake.style.animationDuration = `${Math.random() * 5 + 5}s`; // Random speed
      snowflake.style.fontSize = `${Math.random() * 5 + 10}px`; // Random size

      // Append the snowflake to the container
      snowContainer.appendChild(snowflake);

      // Remove the snowflake after its animation is complete
      setTimeout(() => {
        snowflake.remove();
      }, 5000);
    }

    // Generate snowflakes at intervals
    const interval = setInterval(createSnowflake, 300);

    // Cleanup on unmount
    return () => clearInterval(interval);
  }, []);

  return <div id="snow-container" />;
};

export default Snowfall;
