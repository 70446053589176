import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import MentalAF from "./mental_af.tsx";

const Home = () => {
  return (
    <div className="mentalaf">
      <div id="home-container">
        <div className="limiter">
          <div className="modal-overlay">
            <div className="mentalAF-container">
              <h2>MENTAL AF</h2>
              <h3>The ultimate stage for the mentalest of holders! </h3>
              <MentalAF />
              <div className="closeposition">
                <Link
                  to="/"
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                ></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
