export const RECEIVER_WALLET =
  "7PWMEGVTGLHQ427HEWC4ZNYYOHPXKUIANUUMSWPLEFMI7KNNJU6PG4WHZM";
export const CREATOR_WALLET =
  "47KPTVPRIIKKGEVSLTTOTK42ZK7FNMBSLMGXCAXENEWDDDVYJDERINJXMA";
export const MINT_WALLET =
  "E6AW363ZUCLTLAK3O5E5YWW3CJ6AY6KCVWSIKQ7UCXY3ZNMQNCDPGVRNE4";

export const FULL_MENTAL_MARVIN_RESERVE =
  "FBXOMEIDRATN5NVUO4ZC7QBBN3LI2LU4MAAEFSJLAZFXYHGXXEN323DMHI";
export const IPFS_PREFIX = "https://ipfs.mentalmarvin.art/ipfs";
export const IPFS_PREFIX2 = "https://ipfs.algonode.xyz/ipfs/";

export const API_ENDPOINT = "https://api.mentalmarvin.art/api";
export const API_ENDPOINT2 = "http://127.0.0.1:8000/api";

export const NODE_ENDPOINT = "https://mainnet-api.4160.nodely.dev";
export const INDEXER_ENDPOINT = "https://mainnet-idx.algonode.cloud/v2";
export const NODE_PORT = "443";
export const NODE_TOKEN = "";

export const ALGO_EXPLORER_URL = "https://allo.info";

export const NFTGROUP1AMOUNT = 20;
export const NFTGROUP2AMOUNT = 10;
export const NFTGROUP3AMOUNT = 50;

export const ALGO_FEE = 0;
export const CUBE_FEE = 1;

export const BASIC_TRAIT_ALGO_FEE = 9;
export const BASIC_TRAIT_USD_FEE = 1;
export const BASIC_TRAIT_CUBE_FEE = 5;

export const SPECIAL_TRAIT_ALGO_FEE = 14;
export const SPECIAL_TRAIT_USD_FEE = 2;
export const SPECIAL_TRAIT_CUBE_FEE = 10;

export const CUBE_BUY_FEE = 10;
export const CUBE_BUY_AMOUNT = 20;
export const COMIC_BUY_FEE = 99;
export const TRINKET_BUY_FEE = 200;

export const MENTALAF_CUBE_REWARD_AMOUNT = 100;

export const CUBE_ASSET_ID = 1047740777;

export const TRAITS = [
  "Background",
  "Body",
  "Clothes",
  "Hair",
  "Mouth",
  "Eyes",
  "Hand",
  "Special1",
];

export const ASSET_NAMES = {
  1017787789: "Mental Marvin #1",
  1017787806: "Mental Marvin #2",
  1017787851: "Mental Marvin #3",
  1017787952: "Mental Marvin #4",
  1017788042: "Mental Marvin #5",
  1017788073: "Mental Marvin #6",
  1017788119: "Mental Marvin #7",
  1017788358: "Mental Marvin #8",
  1017788585: "Mental Marvin #9",
  1017788726: "Mental Marvin #10",
  1017788823: "Mental Marvin #11",
  1017788869: "Mental Marvin #12",
  1017788899: "Mental Marvin #13",
  1017788935: "Mental Marvin #14",
  1017788981: "Mental Marvin #15",
  1017789034: "Mental Marvin #16",
  1017789085: "Mental Marvin #17",
  1017789125: "Mental Marvin #18",
  1017789266: "Mental Marvin #19",
  1017789396: "Mental Marvin #20",
  1017789418: "Mental Marvin #21",
  1017789452: "Mental Marvin #22",
  1017789542: "Mental Marvin #23",
  1017789603: "Mental Marvin #24",
  1017789918: "Mental Marvin #25",
  1017789978: "Mental Marvin #26",
  1017790012: "Mental Marvin #27",
  1017790060: "Mental Marvin #28",
  1017790393: "Mental Marvin #29",
  1017790646: "Mental Marvin #30",
  1017790837: "Mental Marvin #31",
  1017790912: "Mental Marvin #32",
  1017791154: "Mental Marvin #33",
  1017791182: "Mental Marvin #34",
  1017791225: "Mental Marvin #35",
  1017791272: "Mental Marvin #36",
  1017791322: "Mental Marvin #37",
  1017791349: "Mental Marvin #38",
  1017791402: "Mental Marvin #39",
  1017791461: "Mental Marvin #40",
  1017791493: "Mental Marvin #41",
  1017791530: "Mental Marvin #42",
  1017791576: "Mental Marvin #43",
  1017791619: "Mental Marvin #44",
  1017791655: "Mental Marvin #45",
  1017791975: "Mental Marvin #46",
  1017792135: "Mental Marvin #47",
  1017792328: "Mental Marvin #48",
  1017792457: "Mental Marvin #49",
  1017792504: "Mental Marvin #50",
  1017792561: "Mental Marvin #51",
  1017792595: "Mental Marvin #52",
  1017792747: "Mental Marvin #53",
  1017793026: "Mental Marvin #54",
  1017793175: "Mental Marvin #55",
  1017793648: "Mental Marvin #56",
  1017793889: "Mental Marvin #57",
  1017793926: "Mental Marvin #58",
  1017794014: "Mental Marvin #59",
  1017794186: "Mental Marvin #60",
  1017794257: "Mental Marvin #61",
  1017794333: "Mental Marvin #62",
  1017794377: "Mental Marvin #63",
  1017794422: "Mental Marvin #64",
  1017794481: "Mental Marvin #65",
  1017794542: "Mental Marvin #66",
  1017794604: "Mental Marvin #67",
  1017794691: "Mental Marvin #68",
  1017794853: "Mental Marvin #69",
  1017795092: "Mental Marvin #70",
  1017795175: "Mental Marvin #71",
  1017795233: "Mental Marvin #72",
  1017795280: "Mental Marvin #73",
  1017795352: "Mental Marvin #74",
  1017795433: "Mental Marvin #75",
  1017795546: "Mental Marvin #76",
  1017795616: "Mental Marvin #77",
  1017795664: "Mental Marvin #78",
  1017796206: "Mental Marvin #79",
  1017796514: "Mental Marvin #80",
  1017796791: "Mental Marvin #81",
  1017796833: "Mental Marvin #82",
  1017796873: "Mental Marvin #83",
  1017796909: "Mental Marvin #84",
  1017796932: "Mental Marvin #85",
  1017796980: "Mental Marvin #86",
  1017797047: "Mental Marvin #87",
  1017797089: "Mental Marvin #88",
  1017797251: "Mental Marvin #89",
  1017797288: "Mental Marvin #90",
  1017797336: "Mental Marvin #91",
  1017797372: "Mental Marvin #92",
  1017797441: "Mental Marvin #93",
  1017797502: "Mental Marvin #94",
  1017797553: "Mental Marvin #95",
  1017797580: "Mental Marvin #96",
  1017797625: "Mental Marvin #97",
  1017797664: "Mental Marvin #98",
  1017797708: "Mental Marvin #99",
  1017797753: "Mental Marvin #100",
  1017797795: "Mental Marvin #101",
  1017797838: "Mental Marvin #102",
  1017797886: "Mental Marvin #103",
  1017797975: "Mental Marvin #104",
  1017798008: "Mental Marvin #105",
  1017798083: "Mental Marvin #106",
  1017798149: "Mental Marvin #107",
  1017798180: "Mental Marvin #108",
  1017798220: "Mental Marvin #109",
  1017798250: "Mental Marvin #110",
  1017798288: "Mental Marvin #111",
  1017798329: "Mental Marvin #112",
  1017798360: "Mental Marvin #113",
  1017798390: "Mental Marvin #114",
  1017798466: "Mental Marvin #115",
  1017798527: "Mental Marvin #116",
  1017798562: "Mental Marvin #117",
  1017798594: "Mental Marvin #118",
  1017798647: "Mental Marvin #119",
  1017798679: "Mental Marvin #120",
  1017798713: "Mental Marvin #121",
  1017799441: "Mental Marvin #122",
  1017799707: "Mental Marvin #123",
  1017799991: "Mental Marvin #124",
  1017800418: "Mental Marvin #125",
  1017800895: "Mental Marvin #126",
  1017801043: "Mental Marvin #127",
  1017801370: "Mental Marvin #128",
  1017801495: "Mental Marvin #129",
  1017801681: "Mental Marvin #130",
  1017801757: "Mental Marvin #131",
  1017802295: "Mental Marvin #132",
  1017802698: "Mental Marvin #133",
  1017802841: "Mental Marvin #134",
  1017802905: "Mental Marvin #135",
  1017803061: "Mental Marvin #136",
  1017803151: "Mental Marvin #137",
  1017803338: "Mental Marvin #138",
  1017803398: "Mental Marvin #139",
  1017803513: "Mental Marvin #140",
  1017803694: "Mental Marvin #141",
  1017803937: "Mental Marvin #142",
  1017804078: "Mental Marvin #143",
  1017804130: "Mental Marvin #144",
  1017804162: "Mental Marvin #145",
  1017804213: "Mental Marvin #146",
  1017804280: "Mental Marvin #147",
  1017804311: "Mental Marvin #148",
  1017804350: "Mental Marvin #149",
  1017804385: "Mental Marvin #150",
  1017804441: "Mental Marvin #151",
  1017804490: "Mental Marvin #152",
  1017804543: "Mental Marvin #153",
  1017804597: "Mental Marvin #154",
  1017804659: "Mental Marvin #155",
  1017804709: "Mental Marvin #156",
  1017804783: "Mental Marvin #157",
  1017804827: "Mental Marvin #158",
  1017804888: "Mental Marvin #159",
  1017804917: "Mental Marvin #160",
  1017804968: "Mental Marvin #161",
  1017805009: "Mental Marvin #162",
  1017805055: "Mental Marvin #163",
  1017805122: "Mental Marvin #164",
  1017805367: "Mental Marvin #165",
  1017805682: "Mental Marvin #166",
  1017805921: "Mental Marvin #167",
  1017806060: "Mental Marvin #168",
  1017806227: "Mental Marvin #169",
  1017806284: "Mental Marvin #170",
  1017806407: "Mental Marvin #171",
  1017806563: "Mental Marvin #172",
  1017806735: "Mental Marvin #173",
  1017807047: "Mental Marvin #174",
  1017807536: "Mental Marvin #175",
  1017808208: "Mental Marvin #176",
  1017808277: "Mental Marvin #177",
  1017808449: "Mental Marvin #178",
  1017808812: "Mental Marvin #179",
  1017809107: "Mental Marvin #180",
  1017809241: "Mental Marvin #181",
  1017809330: "Mental Marvin #182",
  1017809422: "Mental Marvin #183",
  1017809466: "Mental Marvin #184",
  1017809517: "Mental Marvin #185",
  1017809793: "Mental Marvin #186",
  1017809950: "Mental Marvin #187",
  1017810186: "Mental Marvin #188",
  1017810283: "Mental Marvin #189",
  1017810372: "Mental Marvin #190",
  1017810458: "Mental Marvin #191",
  1017810502: "Mental Marvin #192",
  1017810568: "Mental Marvin #193",
  1017810599: "Mental Marvin #194",
  1017810650: "Mental Marvin #195",
  1017810696: "Mental Marvin #196",
  1017810772: "Mental Marvin #197",
  1017810829: "Mental Marvin #198",
  1017810898: "Mental Marvin #199",
  1017810955: "Mental Marvin #200",
  1017810995: "Mental Marvin #201",
  1017811042: "Mental Marvin #202",
  1017811097: "Mental Marvin #203",
  1017811132: "Mental Marvin #204",
  1017811178: "Mental Marvin #205",
  1017811238: "Mental Marvin #206",
  1017811405: "Mental Marvin #207",
  1017811531: "Mental Marvin #208",
  1017811842: "Mental Marvin #209",
  1017812044: "Mental Marvin #210",
  1017812227: "Mental Marvin #211",
  1017812276: "Mental Marvin #212",
  1017812590: "Mental Marvin #213",
  1017812652: "Mental Marvin #214",
  1017813014: "Mental Marvin #215",
  1017813096: "Mental Marvin #216",
  1017813479: "Mental Marvin #217",
  1017813734: "Mental Marvin #218",
  1017813981: "Mental Marvin #219",
  1017814238: "Mental Marvin #220",
  1017814417: "Mental Marvin #221",
  1017814604: "Mental Marvin #222",
  1017814833: "Mental Marvin #223",
  1017815039: "Mental Marvin #224",
  1017815182: "Mental Marvin #225",
  1017815322: "Mental Marvin #226",
  1017815559: "Mental Marvin #227",
  1017815713: "Mental Marvin #228",
  1017815849: "Mental Marvin #229",
  1017815897: "Mental Marvin #230",
  1017815944: "Mental Marvin #231",
  1017816010: "Mental Marvin #232",
  1017816052: "Mental Marvin #233",
  1017816085: "Mental Marvin #234",
  1017816131: "Mental Marvin #235",
  1017816173: "Mental Marvin #236",
  1017816211: "Mental Marvin #237",
  1017816261: "Mental Marvin #238",
  1017816362: "Mental Marvin #239",
  1017816400: "Mental Marvin #240",
  1017816428: "Mental Marvin #241",
  1017816479: "Mental Marvin #242",
  1017816530: "Mental Marvin #243",
  1017816651: "Mental Marvin #244",
  1017816745: "Mental Marvin #245",
  1017816783: "Mental Marvin #246",
  1017816821: "Mental Marvin #247",
  1017816863: "Mental Marvin #248",
  1017816918: "Mental Marvin #249",
  1017816944: "Mental Marvin #250",
  1017816994: "Mental Marvin #251",
  1017817035: "Mental Marvin #252",
  1017817097: "Mental Marvin #253",
  1017817126: "Mental Marvin #254",
  1017817167: "Mental Marvin #255",
  1017817219: "Mental Marvin #256",
  1017817256: "Mental Marvin #257",
  1017817287: "Mental Marvin #258",
  1017817340: "Mental Marvin #259",
  1017817402: "Mental Marvin #260",
  1017817437: "Mental Marvin #261",
  1017817486: "Mental Marvin #262",
  1017817531: "Mental Marvin #263",
  1017817583: "Mental Marvin #264",
  1017817621: "Mental Marvin #265",
  1017817673: "Mental Marvin #266",
  1017817728: "Mental Marvin #267",
  1017817775: "Mental Marvin #268",
  1017817815: "Mental Marvin #269",
  1017817862: "Mental Marvin #270",
  1017817896: "Mental Marvin #271",
  1017817934: "Mental Marvin #272",
  1017817985: "Mental Marvin #273",
  1017818034: "Mental Marvin #274",
  1017818077: "Mental Marvin #275",
  1017818140: "Mental Marvin #276",
  1017818189: "Mental Marvin #277",
  1017818228: "Mental Marvin #278",
  1017818299: "Mental Marvin #279",
  1017818344: "Mental Marvin #280",
  1017818384: "Mental Marvin #281",
  1017818418: "Mental Marvin #282",
  1017818455: "Mental Marvin #283",
  1017818499: "Mental Marvin #284",
  1017818539: "Mental Marvin #285",
  1017818569: "Mental Marvin #286",
  1017818648: "Mental Marvin #287",
  1017818695: "Mental Marvin #288",
  1017818760: "Mental Marvin #289",
  1017818811: "Mental Marvin #290",
  1017818853: "Mental Marvin #291",
  1017818888: "Mental Marvin #292",
  1017819117: "Mental Marvin #293",
  1017819225: "Mental Marvin #294",
  1017819293: "Mental Marvin #295",
  1017819344: "Mental Marvin #296",
  1017819415: "Mental Marvin #297",
  1017819501: "Mental Marvin #298",
  1017819546: "Mental Marvin #299",
  1017819620: "Mental Marvin #300",
  1017819688: "Mental Marvin #301",
  1017819743: "Mental Marvin #302",
  1017819790: "Mental Marvin #303",
  1017819877: "Mental Marvin #304",
  1017819965: "Mental Marvin #305",
  1017820026: "Mental Marvin #306",
  1017820082: "Mental Marvin #307",
  1017820155: "Mental Marvin #308",
  1017820203: "Mental Marvin #309",
  1017820256: "Mental Marvin #310",
  1017820311: "Mental Marvin #311",
  1017820353: "Mental Marvin #312",
  1017820405: "Mental Marvin #313",
  1017820450: "Mental Marvin #314",
  1017820502: "Mental Marvin #315",
  1017820545: "Mental Marvin #316",
  1017820588: "Mental Marvin #317",
  1017820636: "Mental Marvin #318",
  1017820688: "Mental Marvin #319",
  1017820733: "Mental Marvin #320",
  1017820804: "Mental Marvin #321",
  1017820829: "Mental Marvin #322",
  1017820870: "Mental Marvin #323",
  1017820982: "Mental Marvin #324",
  1017821059: "Mental Marvin #325",
  1017821135: "Mental Marvin #326",
  1017821239: "Mental Marvin #327",
  1017821374: "Mental Marvin #328",
  1017821446: "Mental Marvin #329",
  1017821494: "Mental Marvin #330",
  1017821553: "Mental Marvin #331",
  1017821617: "Mental Marvin #332",
  1017821675: "Mental Marvin #333",
  1017821712: "Mental Marvin #334",
  1017821744: "Mental Marvin #335",
  1017821789: "Mental Marvin #336",
  1017821893: "Mental Marvin #337",
  1017821936: "Mental Marvin #338",
  1017821973: "Mental Marvin #339",
  1017822026: "Mental Marvin #340",
  1017822067: "Mental Marvin #341",
  1017822103: "Mental Marvin #342",
  1017822145: "Mental Marvin #343",
  1017822195: "Mental Marvin #344",
  1017822251: "Mental Marvin #345",
  1017822313: "Mental Marvin #346",
  1017822357: "Mental Marvin #347",
  1017822383: "Mental Marvin #348",
  1017822424: "Mental Marvin #349",
  1017822464: "Mental Marvin #350",
  1017822528: "Mental Marvin #351",
  1017822566: "Mental Marvin #352",
  1017822594: "Mental Marvin #353",
  1017822622: "Mental Marvin #354",
  1017822670: "Mental Marvin #355",
  1017822711: "Mental Marvin #356",
  1017822748: "Mental Marvin #357",
  1017822907: "Mental Marvin #358",
  1017822962: "Mental Marvin #359",
  1017823021: "Mental Marvin #360",
  1017823077: "Mental Marvin #361",
  1017823112: "Mental Marvin #362",
  1017823169: "Mental Marvin #363",
  1017823213: "Mental Marvin #364",
  1017823256: "Mental Marvin #365",
  1017823287: "Mental Marvin #366",
  1017823332: "Mental Marvin #367",
  1017823427: "Mental Marvin #368",
  1017823482: "Mental Marvin #369",
  1017823505: "Mental Marvin #370",
  1017823548: "Mental Marvin #371",
  1017823594: "Mental Marvin #372",
  1017823633: "Mental Marvin #373",
  1017823674: "Mental Marvin #374",
  1017823736: "Mental Marvin #375",
  1017823894: "Mental Marvin #376",
  1017823950: "Mental Marvin #377",
  1017823999: "Mental Marvin #378",
  1017824067: "Mental Marvin #379",
  1017824107: "Mental Marvin #380",
  1017824143: "Mental Marvin #381",
  1017824180: "Mental Marvin #382",
  1017824224: "Mental Marvin #383",
  1017824269: "Mental Marvin #384",
  1017824309: "Mental Marvin #385",
  1017824344: "Mental Marvin #386",
  1017824404: "Mental Marvin #387",
  1017824448: "Mental Marvin #388",
  1017824545: "Mental Marvin #389",
  1017824578: "Mental Marvin #390",
  1017824622: "Mental Marvin #391",
  1017824661: "Mental Marvin #392",
  1017824687: "Mental Marvin #393",
  1017824742: "Mental Marvin #394",
  1017824789: "Mental Marvin #395",
  1017824822: "Mental Marvin #396",
  1017824861: "Mental Marvin #397",
  1017824890: "Mental Marvin #398",
  1017824932: "Mental Marvin #399",
  1017824964: "Mental Marvin #400",
  1017825000: "Mental Marvin #401",
  1017825036: "Mental Marvin #402",
  1017825128: "Mental Marvin #403",
  1017825160: "Mental Marvin #404",
  1017825206: "Mental Marvin #405",
  1017825266: "Mental Marvin #406",
  1017825319: "Mental Marvin #407",
  1017825377: "Mental Marvin #408",
  1017825411: "Mental Marvin #409",
  1017825468: "Mental Marvin #410",
  1017825515: "Mental Marvin #411",
  1017825565: "Mental Marvin #412",
  1017825609: "Mental Marvin #413",
  1017825664: "Mental Marvin #414",
  1017825709: "Mental Marvin #415",
  1017825786: "Mental Marvin #416",
  1017825846: "Mental Marvin #417",
  1017825907: "Mental Marvin #418",
  1017825953: "Mental Marvin #419",
  1017826002: "Mental Marvin #420",
  1017826047: "Mental Marvin #421",
  1017826087: "Mental Marvin #422",
  1017826145: "Mental Marvin #423",
  1017826355: "Mental Marvin #424",
  1017826450: "Mental Marvin #425",
  1017826519: "Mental Marvin #426",
  1017826579: "Mental Marvin #427",
  1017826644: "Mental Marvin #428",
  1017826687: "Mental Marvin #429",
  1017826735: "Mental Marvin #430",
  1017826786: "Mental Marvin #431",
  1017827045: "Mental Marvin #432",
  1017827258: "Mental Marvin #433",
  1017827486: "Mental Marvin #434",
  1017827670: "Mental Marvin #435",
  1017827738: "Mental Marvin #436",
  1017827791: "Mental Marvin #437",
  1017827837: "Mental Marvin #438",
  1017827894: "Mental Marvin #439",
  1017827946: "Mental Marvin #440",
  1017827998: "Mental Marvin #441",
  1017828064: "Mental Marvin #442",
  1017828132: "Mental Marvin #443",
  1017828169: "Mental Marvin #444",
  1017828206: "Mental Marvin #445",
  1017828254: "Mental Marvin #446",
  1017828298: "Mental Marvin #447",
  1017828327: "Mental Marvin #448",
  1017828364: "Mental Marvin #449",
  1017828393: "Mental Marvin #450",
  1017828436: "Mental Marvin #451",
  1017828482: "Mental Marvin #452",
  1017828515: "Mental Marvin #453",
  1017828577: "Mental Marvin #454",
  1017828623: "Mental Marvin #455",
  1017828659: "Mental Marvin #456",
  1017828711: "Mental Marvin #457",
  1017828741: "Mental Marvin #458",
  1017828797: "Mental Marvin #459",
  1017828830: "Mental Marvin #460",
  1017828884: "Mental Marvin #461",
  1017828922: "Mental Marvin #462",
  1017828990: "Mental Marvin #463",
  1017829027: "Mental Marvin #464",
  1017829072: "Mental Marvin #465",
  1017829113: "Mental Marvin #466",
  1017829146: "Mental Marvin #467",
  1017829196: "Mental Marvin #468",
  1017829240: "Mental Marvin #469",
  1017829283: "Mental Marvin #470",
  1017829346: "Mental Marvin #471",
  1017829388: "Mental Marvin #472",
  1017829425: "Mental Marvin #473",
  1017829508: "Mental Marvin #474",
  1017829573: "Mental Marvin #475",
  1017829611: "Mental Marvin #476",
  1017829654: "Mental Marvin #477",
  1017829698: "Mental Marvin #478",
  1017829731: "Mental Marvin #479",
  1017829775: "Mental Marvin #480",
  1017829830: "Mental Marvin #481",
  1017829872: "Mental Marvin #482",
  1017829911: "Mental Marvin #483",
  1017829967: "Mental Marvin #484",
  1017830004: "Mental Marvin #485",
  1017830047: "Mental Marvin #486",
  1017830088: "Mental Marvin #487",
  1017830131: "Mental Marvin #488",
  1017830171: "Mental Marvin #489",
  1017830212: "Mental Marvin #490",
  1017830251: "Mental Marvin #491",
  1017830297: "Mental Marvin #492",
  1017830335: "Mental Marvin #493",
  1017830390: "Mental Marvin #494",
  1017830434: "Mental Marvin #495",
  1017830455: "Mental Marvin #496",
  1017830492: "Mental Marvin #497",
  1017830525: "Mental Marvin #498",
  1017830567: "Mental Marvin #499",
  1017830633: "Mental Marvin #500",
  1017830669: "Mental Marvin #501",
  1017830710: "Mental Marvin #502",
  1017830759: "Mental Marvin #503",
  1017830777: "Mental Marvin #504",
  1017830801: "Mental Marvin #505",
  1017830840: "Mental Marvin #506",
  1017830884: "Mental Marvin #507",
  1017830916: "Mental Marvin #508",
  1017830955: "Mental Marvin #509",
  1017830992: "Mental Marvin #510",
  1017831032: "Mental Marvin #511",
  1017831078: "Mental Marvin #512",
  1017831119: "Mental Marvin #513",
  1017831158: "Mental Marvin #514",
  1017831208: "Mental Marvin #515",
  1017831240: "Mental Marvin #516",
  1017831288: "Mental Marvin #517",
  1017831319: "Mental Marvin #518",
  1017831361: "Mental Marvin #519",
  1017831400: "Mental Marvin #520",
  1017831431: "Mental Marvin #521",
  1017831469: "Mental Marvin #522",
  1017831508: "Mental Marvin #523",
  1017831553: "Mental Marvin #524",
  1017831595: "Mental Marvin #525",
  1017831652: "Mental Marvin #526",
  1017831697: "Mental Marvin #527",
  1017831746: "Mental Marvin #528",
  1017831773: "Mental Marvin #529",
  1017831940: "Mental Marvin #530",
  1017832111: "Mental Marvin #531",
  1017832259: "Mental Marvin #532",
  1017832318: "Mental Marvin #533",
  1017832375: "Mental Marvin #534",
  1017832417: "Mental Marvin #535",
  1017832452: "Mental Marvin #536",
  1017832497: "Mental Marvin #537",
  1017832527: "Mental Marvin #538",
  1017832578: "Mental Marvin #539",
  1017832614: "Mental Marvin #540",
  1017832654: "Mental Marvin #541",
  1017832689: "Mental Marvin #542",
  1017832734: "Mental Marvin #543",
  1017832764: "Mental Marvin #544",
  1017832788: "Mental Marvin #545",
  1017832828: "Mental Marvin #546",
  1017832873: "Mental Marvin #547",
  1017832919: "Mental Marvin #548",
  1017832952: "Mental Marvin #549",
  1017833012: "Mental Marvin #550",
  1017833061: "Mental Marvin #551",
  1017833092: "Mental Marvin #552",
  1017833135: "Mental Marvin #553",
  1017833170: "Mental Marvin #554",
  1017833218: "Mental Marvin #555",
  1017833252: "Mental Marvin #556",
  1017833315: "Mental Marvin #557",
  1017833377: "Mental Marvin #558",
  1017833422: "Mental Marvin #559",
  1017833447: "Mental Marvin #560",
  1017833488: "Mental Marvin #561",
  1017833531: "Mental Marvin #562",
  1017833564: "Mental Marvin #563",
  1017833609: "Mental Marvin #564",
  1017833641: "Mental Marvin #565",
  1017833672: "Mental Marvin #566",
  1017833823: "Mental Marvin #567",
  1017833911: "Mental Marvin #568",
  1017833968: "Mental Marvin #569",
  1017834011: "Mental Marvin #570",
  1017834070: "Mental Marvin #571",
  1017834166: "Mental Marvin #572",
  1017834249: "Mental Marvin #573",
  1017834283: "Mental Marvin #574",
  1017834382: "Mental Marvin #575",
  1017834477: "Mental Marvin #576",
  1017834535: "Mental Marvin #577",
  1017834597: "Mental Marvin #578",
  1017834668: "Mental Marvin #579",
  1017834727: "Mental Marvin #580",
  1017834787: "Mental Marvin #581",
  1017834872: "Mental Marvin #582",
  1017834906: "Mental Marvin #583",
  1017834943: "Mental Marvin #584",
  1017834985: "Mental Marvin #585",
  1017835028: "Mental Marvin #586",
  1017835081: "Mental Marvin #587",
  1017835240: "Mental Marvin #588",
  1017835305: "Mental Marvin #589",
  1017835347: "Mental Marvin #590",
  1017835385: "Mental Marvin #591",
  1017835444: "Mental Marvin #592",
  1017835515: "Mental Marvin #593",
  1017835545: "Mental Marvin #594",
  1017835591: "Mental Marvin #595",
  1017835638: "Mental Marvin #596",
  1017835670: "Mental Marvin #597",
  1017835716: "Mental Marvin #598",
  1017835751: "Mental Marvin #599",
  1017835808: "Mental Marvin #600",
  1017835851: "Mental Marvin #601",
  1017835879: "Mental Marvin #602",
  1017835915: "Mental Marvin #603",
  1017835956: "Mental Marvin #604",
  1017836008: "Mental Marvin #605",
  1017836064: "Mental Marvin #606",
  1017836122: "Mental Marvin #607",
  1017836160: "Mental Marvin #608",
  1017836188: "Mental Marvin #609",
  1017836215: "Mental Marvin #610",
  1017836296: "Mental Marvin #611",
  1017836344: "Mental Marvin #612",
  1017836387: "Mental Marvin #613",
  1017836415: "Mental Marvin #614",
  1017836459: "Mental Marvin #615",
  1017836510: "Mental Marvin #616",
  1017836565: "Mental Marvin #617",
  1017836615: "Mental Marvin #618",
  1017836679: "Mental Marvin #619",
  1017836716: "Mental Marvin #620",
  1017836772: "Mental Marvin #621",
  1017836868: "Mental Marvin #622",
  1017836924: "Mental Marvin #623",
  1017836952: "Mental Marvin #624",
  1017836984: "Mental Marvin #625",
  1017837044: "Mental Marvin #626",
  1017837084: "Mental Marvin #627",
  1017837118: "Mental Marvin #628",
  1017837164: "Mental Marvin #629",
  1017837207: "Mental Marvin #630",
  1017837258: "Mental Marvin #631",
  1017837295: "Mental Marvin #632",
  1017837334: "Mental Marvin #633",
  1017837363: "Mental Marvin #634",
  1017837401: "Mental Marvin #635",
  1017837461: "Mental Marvin #636",
  1017837494: "Mental Marvin #637",
  1017837528: "Mental Marvin #638",
  1017837566: "Mental Marvin #639",
  1017837592: "Mental Marvin #640",
  1017837634: "Mental Marvin #641",
  1017837675: "Mental Marvin #642",
  1017837709: "Mental Marvin #643",
  1017837759: "Mental Marvin #644",
  1017837865: "Mental Marvin #645",
  1017837903: "Mental Marvin #646",
  1017837979: "Mental Marvin #647",
  1017838008: "Mental Marvin #648",
  1017838052: "Mental Marvin #649",
  1017838114: "Mental Marvin #650",
  1017838149: "Mental Marvin #651",
  1017838189: "Mental Marvin #652",
  1017838220: "Mental Marvin #653",
  1017838255: "Mental Marvin #654",
  1017838334: "Mental Marvin #655",
  1017838370: "Mental Marvin #656",
  1017838407: "Mental Marvin #657",
  1017838496: "Mental Marvin #658",
  1017838580: "Mental Marvin #659",
  1017838624: "Mental Marvin #660",
  1017838670: "Mental Marvin #661",
  1017838741: "Mental Marvin #662",
  1017838777: "Mental Marvin #663",
  1017838810: "Mental Marvin #664",
  1017838841: "Mental Marvin #665",
  1017838899: "Mental Marvin #666",
  1017838946: "Mental Marvin #667",
  1017838973: "Mental Marvin #668",
  1017839005: "Mental Marvin #669",
  1017839039: "Mental Marvin #670",
  1017839090: "Mental Marvin #671",
  1017839120: "Mental Marvin #672",
  1017839147: "Mental Marvin #673",
  1017839173: "Mental Marvin #674",
  1017839217: "Mental Marvin #675",
  1017839244: "Mental Marvin #676",
  1017839293: "Mental Marvin #677",
  1017839327: "Mental Marvin #678",
  1017839361: "Mental Marvin #679",
  1017839389: "Mental Marvin #680",
  1017839429: "Mental Marvin #681",
  1017839466: "Mental Marvin #682",
  1017839515: "Mental Marvin #683",
  1017839573: "Mental Marvin #684",
  1017839666: "Mental Marvin #685",
  1017839742: "Mental Marvin #686",
  1017839783: "Mental Marvin #687",
  1017839812: "Mental Marvin #688",
  1017839846: "Mental Marvin #689",
  1017839933: "Mental Marvin #690",
  1017839973: "Mental Marvin #691",
  1017840018: "Mental Marvin #692",
  1017840056: "Mental Marvin #693",
  1017840098: "Mental Marvin #694",
  1017840152: "Mental Marvin #695",
  1017840180: "Mental Marvin #696",
  1017840212: "Mental Marvin #697",
  1017840239: "Mental Marvin #698",
  1017840277: "Mental Marvin #699",
  1017840310: "Mental Marvin #700",
  1017840362: "Mental Marvin #701",
  1017840416: "Mental Marvin #702",
  1017840454: "Mental Marvin #703",
  1017840492: "Mental Marvin #704",
  1017840531: "Mental Marvin #705",
  1017840593: "Mental Marvin #706",
  1017840636: "Mental Marvin #707",
  1017840683: "Mental Marvin #708",
  1017840734: "Mental Marvin #709",
  1017840788: "Mental Marvin #710",
  1017840839: "Mental Marvin #711",
  1017840898: "Mental Marvin #712",
  1017840939: "Mental Marvin #713",
  1017840969: "Mental Marvin #714",
  1017841023: "Mental Marvin #715",
  1017841071: "Mental Marvin #716",
  1017841106: "Mental Marvin #717",
  1017841153: "Mental Marvin #718",
  1017841189: "Mental Marvin #719",
  1017841225: "Mental Marvin #720",
  1017841321: "Mental Marvin #721",
  1017841354: "Mental Marvin #722",
  1017841405: "Mental Marvin #723",
  1017841448: "Mental Marvin #724",
  1017841486: "Mental Marvin #725",
  1017841587: "Mental Marvin #726",
  1017841609: "Mental Marvin #727",
  1017841652: "Mental Marvin #728",
  1017841695: "Mental Marvin #729",
  1017841759: "Mental Marvin #730",
  1017841791: "Mental Marvin #731",
  1017841825: "Mental Marvin #732",
  1017841860: "Mental Marvin #733",
  1017841890: "Mental Marvin #734",
  1017841926: "Mental Marvin #735",
  1017841957: "Mental Marvin #736",
  1017841991: "Mental Marvin #737",
  1017842050: "Mental Marvin #738",
  1017842115: "Mental Marvin #739",
  1017842157: "Mental Marvin #740",
  1017842201: "Mental Marvin #741",
  1017842255: "Mental Marvin #742",
  1017842291: "Mental Marvin #743",
  1017842329: "Mental Marvin #744",
  1017842389: "Mental Marvin #745",
  1017842430: "Mental Marvin #746",
  1017842508: "Mental Marvin #747",
  1017842540: "Mental Marvin #748",
  1017842578: "Mental Marvin #749",
  1017842628: "Mental Marvin #750",
  1017842665: "Mental Marvin #751",
  1017842705: "Mental Marvin #752",
  1017842744: "Mental Marvin #753",
  1017842787: "Mental Marvin #754",
  1017842820: "Mental Marvin #755",
  1017842856: "Mental Marvin #756",
  1017842908: "Mental Marvin #757",
  1017842950: "Mental Marvin #758",
  1017842989: "Mental Marvin #759",
  1017843027: "Mental Marvin #760",
  1017843097: "Mental Marvin #761",
  1017843166: "Mental Marvin #762",
  1017843194: "Mental Marvin #763",
  1017843245: "Mental Marvin #764",
  1017843312: "Mental Marvin #765",
  1017843347: "Mental Marvin #766",
  1017843380: "Mental Marvin #767",
  1017843435: "Mental Marvin #768",
  1017843472: "Mental Marvin #769",
  1017843515: "Mental Marvin #770",
  1017843550: "Mental Marvin #771",
  1017843593: "Mental Marvin #772",
  1017843638: "Mental Marvin #773",
  1017843676: "Mental Marvin #774",
  1017843717: "Mental Marvin #775",
  1017843753: "Mental Marvin #776",
  1017843783: "Mental Marvin #777",
  1017843832: "Mental Marvin #778",
  1017843914: "Mental Marvin #779",
  1017843951: "Mental Marvin #780",
  1017843993: "Mental Marvin #781",
  1017844028: "Mental Marvin #782",
  1017844089: "Mental Marvin #783",
  1017844152: "Mental Marvin #784",
  1017844199: "Mental Marvin #785",
  1017844276: "Mental Marvin #786",
  1017844337: "Mental Marvin #787",
  1017844397: "Mental Marvin #788",
  1017844427: "Mental Marvin #789",
  1017844464: "Mental Marvin #790",
  1017844519: "Mental Marvin #791",
  1017844549: "Mental Marvin #792",
  1017844580: "Mental Marvin #793",
  1017844639: "Mental Marvin #794",
  1017844798: "Mental Marvin #795",
  1017844872: "Mental Marvin #796",
  1017844950: "Mental Marvin #797",
  1017845015: "Mental Marvin #798",
  1017845045: "Mental Marvin #799",
  1017845076: "Mental Marvin #800",
  1017845121: "Mental Marvin #801",
  1017845159: "Mental Marvin #802",
  1017845201: "Mental Marvin #803",
  1017845248: "Mental Marvin #804",
  1017845281: "Mental Marvin #805",
  1017845335: "Mental Marvin #806",
  1017845390: "Mental Marvin #807",
  1017845425: "Mental Marvin #808",
  1017845469: "Mental Marvin #809",
  1017845511: "Mental Marvin #810",
  1017845552: "Mental Marvin #811",
  1017845594: "Mental Marvin #812",
  1017845631: "Mental Marvin #813",
  1017845669: "Mental Marvin #814",
  1017845703: "Mental Marvin #815",
  1017845748: "Mental Marvin #816",
  1017845809: "Mental Marvin #817",
  1017845852: "Mental Marvin #818",
  1017845885: "Mental Marvin #819",
  1017845934: "Mental Marvin #820",
  1017845959: "Mental Marvin #821",
  1017845992: "Mental Marvin #822",
  1017846019: "Mental Marvin #823",
  1017846062: "Mental Marvin #824",
  1017846155: "Mental Marvin #825",
  1017846220: "Mental Marvin #826",
  1017846269: "Mental Marvin #827",
  1017846295: "Mental Marvin #828",
  1017846326: "Mental Marvin #829",
  1017846351: "Mental Marvin #830",
  1017846382: "Mental Marvin #831",
  1017846423: "Mental Marvin #832",
  1017846472: "Mental Marvin #833",
  1017846504: "Mental Marvin #834",
  1017846550: "Mental Marvin #835",
  1017846592: "Mental Marvin #836",
  1017846657: "Mental Marvin #837",
  1017846692: "Mental Marvin #838",
  1017846726: "Mental Marvin #839",
  1017846788: "Mental Marvin #840",
  1017846823: "Mental Marvin #841",
  1017846875: "Mental Marvin #842",
  1017846914: "Mental Marvin #843",
  1017846950: "Mental Marvin #844",
  1017846990: "Mental Marvin #845",
  1017847013: "Mental Marvin #846",
  1017847044: "Mental Marvin #847",
  1017847080: "Mental Marvin #848",
  1017847123: "Mental Marvin #849",
  1017847180: "Mental Marvin #850",
  1017847231: "Mental Marvin #851",
  1017847280: "Mental Marvin #852",
  1017847347: "Mental Marvin #853",
  1017847406: "Mental Marvin #854",
  1017847432: "Mental Marvin #855",
  1017847484: "Mental Marvin #856",
  1017847521: "Mental Marvin #857",
  1017847570: "Mental Marvin #858",
  1017847631: "Mental Marvin #859",
  1017847666: "Mental Marvin #860",
  1017847699: "Mental Marvin #861",
  1017847730: "Mental Marvin #862",
  1017847773: "Mental Marvin #863",
  1017847821: "Mental Marvin #864",
  1017847860: "Mental Marvin #865",
  1017847897: "Mental Marvin #866",
  1017847927: "Mental Marvin #867",
  1017848029: "Mental Marvin #868",
  1017848065: "Mental Marvin #869",
  1017848121: "Mental Marvin #870",
  1017848155: "Mental Marvin #871",
  1017848177: "Mental Marvin #872",
  1017848216: "Mental Marvin #873",
  1017848293: "Mental Marvin #874",
  1017848326: "Mental Marvin #875",
  1017848364: "Mental Marvin #876",
  1017848406: "Mental Marvin #877",
  1017848461: "Mental Marvin #878",
  1017848501: "Mental Marvin #879",
  1017848538: "Mental Marvin #880",
  1017848565: "Mental Marvin #881",
  1017848616: "Mental Marvin #882",
  1017848644: "Mental Marvin #883",
  1017848684: "Mental Marvin #884",
  1017848725: "Mental Marvin #885",
  1017848773: "Mental Marvin #886",
  1017848813: "Mental Marvin #887",
  1017848851: "Mental Marvin #888",
  1017848899: "Mental Marvin #889",
  1017848928: "Mental Marvin #890",
  1017848967: "Mental Marvin #891",
  1017849008: "Mental Marvin #892",
  1017849055: "Mental Marvin #893",
  1017849100: "Mental Marvin #894",
  1017849138: "Mental Marvin #895",
  1017849181: "Mental Marvin #896",
  1017849225: "Mental Marvin #897",
  1017849288: "Mental Marvin #898",
  1017849319: "Mental Marvin #899",
  1017849360: "Mental Marvin #900",
  1017849405: "Mental Marvin #901",
  1017849439: "Mental Marvin #902",
  1017849487: "Mental Marvin #903",
  1017849521: "Mental Marvin #904",
  1017849560: "Mental Marvin #905",
  1017849606: "Mental Marvin #906",
  1017849681: "Mental Marvin #907",
  1017849745: "Mental Marvin #908",
  1017849780: "Mental Marvin #909",
  1017849818: "Mental Marvin #910",
  1017849851: "Mental Marvin #911",
  1017849905: "Mental Marvin #912",
  1017849989: "Mental Marvin #913",
  1017850022: "Mental Marvin #914",
  1017850070: "Mental Marvin #915",
  1017850100: "Mental Marvin #916",
  1017850118: "Mental Marvin #917",
  1017850155: "Mental Marvin #918",
  1017850197: "Mental Marvin #919",
  1017850245: "Mental Marvin #920",
  1017850288: "Mental Marvin #921",
  1017850325: "Mental Marvin #922",
  1017850354: "Mental Marvin #923",
  1017850377: "Mental Marvin #924",
  1017850404: "Mental Marvin #925",
  1017850477: "Mental Marvin #926",
  1017850516: "Mental Marvin #927",
  1017850556: "Mental Marvin #928",
  1017850593: "Mental Marvin #929",
  1017850630: "Mental Marvin #930",
  1017850658: "Mental Marvin #931",
  1017850700: "Mental Marvin #932",
  1017850746: "Mental Marvin #933",
  1017850840: "Mental Marvin #934",
  1017850873: "Mental Marvin #935",
  1017850921: "Mental Marvin #936",
  1017850964: "Mental Marvin #937",
  1017851000: "Mental Marvin #938",
  1017851026: "Mental Marvin #939",
  1017851072: "Mental Marvin #940",
  1017851117: "Mental Marvin #941",
  1017851148: "Mental Marvin #942",
  1017851171: "Mental Marvin #943",
  1017851220: "Mental Marvin #944",
  1017851264: "Mental Marvin #945",
  1017851299: "Mental Marvin #946",
  1017851332: "Mental Marvin #947",
  1017851362: "Mental Marvin #948",
  1017851404: "Mental Marvin #949",
  1017851441: "Mental Marvin #950",
  1017851484: "Mental Marvin #951",
  1017851514: "Mental Marvin #952",
  1017851566: "Mental Marvin #953",
  1017851619: "Mental Marvin #954",
  1017851639: "Mental Marvin #955",
  1017851671: "Mental Marvin #956",
  1017851690: "Mental Marvin #957",
  1017851735: "Mental Marvin #958",
  1017851763: "Mental Marvin #959",
  1017851798: "Mental Marvin #960",
  1017851836: "Mental Marvin #961",
  1017851888: "Mental Marvin #962",
  1017851933: "Mental Marvin #963",
  1017851955: "Mental Marvin #964",
  1017851990: "Mental Marvin #965",
  1017852039: "Mental Marvin #966",
  1017852062: "Mental Marvin #967",
  1017852095: "Mental Marvin #968",
  1017852120: "Mental Marvin #969",
  1017852169: "Mental Marvin #970",
  1017852369: "Mental Marvin #971",
  1017852431: "Mental Marvin #972",
  1017852477: "Mental Marvin #973",
  1017852525: "Mental Marvin #974",
  1017852562: "Mental Marvin #975",
  1017852596: "Mental Marvin #976",
  1017852616: "Mental Marvin #977",
  1017852657: "Mental Marvin #978",
  1017852691: "Mental Marvin #979",
  1017852735: "Mental Marvin #980",
  1017852794: "Mental Marvin #981",
  1017852828: "Mental Marvin #982",
  1017852850: "Mental Marvin #983",
  1017852888: "Mental Marvin #984",
  1017852917: "Mental Marvin #985",
  1017852976: "Mental Marvin #986",
  1017853010: "Mental Marvin #987",
  1017853034: "Mental Marvin #988",
  1017853060: "Mental Marvin #989",
  1017853100: "Mental Marvin #990",
  1017853134: "Mental Marvin #991",
  1017853193: "Mental Marvin #992",
  1017853253: "Mental Marvin #993",
  1017853316: "Mental Marvin #994",
  1017853342: "Mental Marvin #995",
  1017853385: "Mental Marvin #996",
  1017853456: "Mental Marvin #997",
  1017853517: "Mental Marvin #998",
  1017853566: "Mental Marvin #999",
  1017853595: "Mental Marvin #1000",
  1017853618: "Mental Marvin #1001",
  1017853672: "Mental Marvin #1002",
  1017853703: "Mental Marvin #1003",
  1017853767: "Mental Marvin #1004",
  1017853800: "Mental Marvin #1005",
  1017853836: "Mental Marvin #1006",
  1017853870: "Mental Marvin #1007",
  1017853914: "Mental Marvin #1008",
  1017853954: "Mental Marvin #1009",
  1017853999: "Mental Marvin #1010",
  1017854033: "Mental Marvin #1011",
  1017854056: "Mental Marvin #1012",
  1017854083: "Mental Marvin #1013",
  1017854134: "Mental Marvin #1014",
  1017854168: "Mental Marvin #1015",
  1017854198: "Mental Marvin #1016",
  1017854238: "Mental Marvin #1017",
  1017854285: "Mental Marvin #1018",
  1017854319: "Mental Marvin #1019",
  1017854344: "Mental Marvin #1020",
  1017854376: "Mental Marvin #1021",
  1017854421: "Mental Marvin #1022",
  1017854463: "Mental Marvin #1023",
  1017854501: "Mental Marvin #1024",
  1017854566: "Mental Marvin #1025",
  1017854604: "Mental Marvin #1026",
  1017854655: "Mental Marvin #1027",
  1017854696: "Mental Marvin #1028",
  1017854729: "Mental Marvin #1029",
  1017854780: "Mental Marvin #1030",
  1017854877: "Mental Marvin #1031",
  1017854923: "Mental Marvin #1032",
  1017854972: "Mental Marvin #1033",
  1017855035: "Mental Marvin #1034",
  1017855097: "Mental Marvin #1035",
  1017855133: "Mental Marvin #1036",
  1017855160: "Mental Marvin #1037",
  1017855199: "Mental Marvin #1038",
  1017855228: "Mental Marvin #1039",
  1017855283: "Mental Marvin #1040",
  1017855306: "Mental Marvin #1041",
  1017855354: "Mental Marvin #1042",
  1017855383: "Mental Marvin #1043",
  1017855420: "Mental Marvin #1044",
  1017855454: "Mental Marvin #1045",
  1017855486: "Mental Marvin #1046",
  1017855530: "Mental Marvin #1047",
  1017855563: "Mental Marvin #1048",
  1017855608: "Mental Marvin #1049",
  1017855653: "Mental Marvin #1050",
  1017855705: "Mental Marvin #1051",
  1017855739: "Mental Marvin #1052",
  1017855793: "Mental Marvin #1053",
  1017855870: "Mental Marvin #1054",
  1017855902: "Mental Marvin #1055",
  1017855933: "Mental Marvin #1056",
  1017855968: "Mental Marvin #1057",
  1017856016: "Mental Marvin #1058",
  1017856040: "Mental Marvin #1059",
  1017856070: "Mental Marvin #1060",
  1017856090: "Mental Marvin #1061",
  1017856138: "Mental Marvin #1062",
  1017856175: "Mental Marvin #1063",
  1017856202: "Mental Marvin #1064",
  1017856225: "Mental Marvin #1065",
  1017856263: "Mental Marvin #1066",
  1017856291: "Mental Marvin #1067",
  1017856333: "Mental Marvin #1068",
  1017856361: "Mental Marvin #1069",
  1017856402: "Mental Marvin #1070",
  1017856460: "Mental Marvin #1071",
  1017856494: "Mental Marvin #1072",
  1017856523: "Mental Marvin #1073",
  1017856573: "Mental Marvin #1074",
  1017856606: "Mental Marvin #1075",
  1017856643: "Mental Marvin #1076",
  1017856674: "Mental Marvin #1077",
  1017856709: "Mental Marvin #1078",
  1017856754: "Mental Marvin #1079",
  1017856794: "Mental Marvin #1080",
  1017856818: "Mental Marvin #1081",
  1017856875: "Mental Marvin #1082",
  1017856916: "Mental Marvin #1083",
  1017856943: "Mental Marvin #1084",
  1017856960: "Mental Marvin #1085",
  1017857001: "Mental Marvin #1086",
  1017857033: "Mental Marvin #1087",
  1017857068: "Mental Marvin #1088",
  1017857106: "Mental Marvin #1089",
  1017857149: "Mental Marvin #1090",
  1017857196: "Mental Marvin #1091",
  1017857227: "Mental Marvin #1092",
  1017857265: "Mental Marvin #1093",
  1017857346: "Mental Marvin #1094",
  1017857408: "Mental Marvin #1095",
  1017857454: "Mental Marvin #1096",
  1017857494: "Mental Marvin #1097",
  1017857619: "Mental Marvin #1098",
  1017857684: "Mental Marvin #1099",
  1017857710: "Mental Marvin #1100",
  1017857908: "Mental Marvin #1101",
  1017858044: "Mental Marvin #1102",
  1017858175: "Mental Marvin #1103",
  1017858230: "Mental Marvin #1104",
  1017858292: "Mental Marvin #1105",
  1017858474: "Mental Marvin #1106",
  1017858537: "Mental Marvin #1107",
  1017858644: "Mental Marvin #1108",
  1017858684: "Mental Marvin #1109",
  1017858858: "Mental Marvin #1110",
  1017858910: "Mental Marvin #1111",
  1017858965: "Mental Marvin #1112",
  1017859019: "Mental Marvin #1113",
  1017859066: "Mental Marvin #1114",
  1017859128: "Mental Marvin #1115",
  1017859162: "Mental Marvin #1116",
  1017859196: "Mental Marvin #1117",
  1017859244: "Mental Marvin #1118",
  1017859296: "Mental Marvin #1119",
  1017859329: "Mental Marvin #1120",
  1017859381: "Mental Marvin #1121",
  1017859418: "Mental Marvin #1122",
  1017859449: "Mental Marvin #1123",
  1017859477: "Mental Marvin #1124",
  1017859502: "Mental Marvin #1125",
  1017859545: "Mental Marvin #1126",
  1017859580: "Mental Marvin #1127",
  1017859621: "Mental Marvin #1128",
  1017859650: "Mental Marvin #1129",
  1017859694: "Mental Marvin #1130",
  1017859735: "Mental Marvin #1131",
  1017859799: "Mental Marvin #1132",
  1017859829: "Mental Marvin #1133",
  1017859872: "Mental Marvin #1134",
  1017859927: "Mental Marvin #1135",
  1017859977: "Mental Marvin #1136",
  1017860037: "Mental Marvin #1137",
  1017860099: "Mental Marvin #1138",
  1017860150: "Mental Marvin #1139",
  1017860244: "Mental Marvin #1140",
  1017860288: "Mental Marvin #1141",
  1017860339: "Mental Marvin #1142",
  1017860388: "Mental Marvin #1143",
  1017860443: "Mental Marvin #1144",
  1017860479: "Mental Marvin #1145",
  1017860556: "Mental Marvin #1146",
  1017860595: "Mental Marvin #1147",
  1017860649: "Mental Marvin #1148",
  1017860683: "Mental Marvin #1149",
  1017860745: "Mental Marvin #1150",
  1017860770: "Mental Marvin #1151",
  1017860812: "Mental Marvin #1152",
  1017860834: "Mental Marvin #1153",
  1017860911: "Mental Marvin #1154",
  1017860944: "Mental Marvin #1155",
  1017861018: "Mental Marvin #1156",
  1017861067: "Mental Marvin #1157",
  1017861108: "Mental Marvin #1158",
  1017861139: "Mental Marvin #1159",
  1017861179: "Mental Marvin #1160",
  1017861219: "Mental Marvin #1161",
  1017861272: "Mental Marvin #1162",
  1017861298: "Mental Marvin #1163",
  1017861343: "Mental Marvin #1164",
  1017861380: "Mental Marvin #1165",
  1017861452: "Mental Marvin #1166",
  1017861497: "Mental Marvin #1167",
  1017861577: "Mental Marvin #1168",
  1017900274: "Mental Marvin #1169",
  1017900776: "Mental Marvin #1170",
  1017901327: "Mental Marvin #1171",
  1017901812: "Mental Marvin #1172",
  1017902320: "Mental Marvin #1173",
  1017902835: "Mental Marvin #1174",
  1017903346: "Mental Marvin #1175",
  1017903891: "Mental Marvin #1176",
  1017904514: "Mental Marvin #1177",
  1017905049: "Mental Marvin #1178",
  1017905424: "Mental Marvin #1179",
  1017905474: "Mental Marvin #1180",
  1017905511: "Mental Marvin #1181",
  1017905564: "Mental Marvin #1182",
  1017905618: "Mental Marvin #1183",
  1017905661: "Mental Marvin #1184",
  1017905711: "Mental Marvin #1185",
  1017905739: "Mental Marvin #1186",
  1017905779: "Mental Marvin #1187",
  1017905818: "Mental Marvin #1188",
  1017905870: "Mental Marvin #1189",
  1017905911: "Mental Marvin #1190",
  1017905945: "Mental Marvin #1191",
  1017905993: "Mental Marvin #1192",
  1017906074: "Mental Marvin #1193",
  1017906121: "Mental Marvin #1194",
  1017906490: "Mental Marvin #1195",
  1017906686: "Mental Marvin #1196",
  1017906783: "Mental Marvin #1197",
  1017906866: "Mental Marvin #1198",
  1017907010: "Mental Marvin #1199",
  1017907450: "Mental Marvin #1200",
  1017907878: "Mental Marvin #1201",
  1017908145: "Mental Marvin #1202",
  1017908226: "Mental Marvin #1203",
  1017908298: "Mental Marvin #1204",
  1017908398: "Mental Marvin #1205",
  1017908442: "Mental Marvin #1206",
  1017908465: "Mental Marvin #1207",
  1017908514: "Mental Marvin #1208",
  1017908561: "Mental Marvin #1209",
  1017908591: "Mental Marvin #1210",
  1017908635: "Mental Marvin #1211",
  1017908689: "Mental Marvin #1212",
  1017908750: "Mental Marvin #1213",
  1017908784: "Mental Marvin #1214",
  1017908851: "Mental Marvin #1215",
  1017908910: "Mental Marvin #1216",
  1017908949: "Mental Marvin #1217",
  1017909003: "Mental Marvin #1218",
  1017909044: "Mental Marvin #1219",
  1017909141: "Mental Marvin #1220",
  1017909193: "Mental Marvin #1221",
  1017909237: "Mental Marvin #1222",
  1017909310: "Mental Marvin #1223",
  1017909396: "Mental Marvin #1224",
  1017909438: "Mental Marvin #1225",
  1017909485: "Mental Marvin #1226",
  1017909535: "Mental Marvin #1227",
  1017909590: "Mental Marvin #1228",
  1017909621: "Mental Marvin #1229",
  1017909841: "Mental Marvin #1230",
  1017910436: "Mental Marvin #1231",
  1017910983: "Mental Marvin #1232",
  1017911506: "Mental Marvin #1233",
  1017912041: "Mental Marvin #1234",
  1017912561: "Mental Marvin #1235",
  1017913100: "Mental Marvin #1236",
  1017913640: "Mental Marvin #1237",
  1017914170: "Mental Marvin #1238",
  1017914687: "Mental Marvin #1239",
  1017915253: "Mental Marvin #1240",
  1017915832: "Mental Marvin #1241",
  1017916342: "Mental Marvin #1242",
  1017916376: "Mental Marvin #1243",
  1017916426: "Mental Marvin #1244",
  1017916461: "Mental Marvin #1245",
  1017916504: "Mental Marvin #1246",
  1017916563: "Mental Marvin #1247",
  1017916618: "Mental Marvin #1248",
  1017916701: "Mental Marvin #1249",
  1017916753: "Mental Marvin #1250",
  1017916803: "Mental Marvin #1251",
  1017916888: "Mental Marvin #1252",
  1017916932: "Mental Marvin #1253",
  1017916980: "Mental Marvin #1254",
  1017917082: "Mental Marvin #1255",
  1017917130: "Mental Marvin #1256",
  1017917179: "Mental Marvin #1257",
  1017917226: "Mental Marvin #1258",
  1017917355: "Mental Marvin #1259",
  1017917410: "Mental Marvin #1260",
  1017917473: "Mental Marvin #1261",
  1017917524: "Mental Marvin #1262",
  1017917557: "Mental Marvin #1263",
  1017917603: "Mental Marvin #1264",
  1017917653: "Mental Marvin #1265",
  1017917705: "Mental Marvin #1266",
  1017917756: "Mental Marvin #1267",
  1017917799: "Mental Marvin #1268",
  1017917836: "Mental Marvin #1269",
  1017917870: "Mental Marvin #1270",
  1017917913: "Mental Marvin #1271",
  1017917962: "Mental Marvin #1272",
  1017917996: "Mental Marvin #1273",
  1017918071: "Mental Marvin #1274",
  1017918128: "Mental Marvin #1275",
  1017918168: "Mental Marvin #1276",
  1017918237: "Mental Marvin #1277",
  1017918300: "Mental Marvin #1278",
  1017918360: "Mental Marvin #1279",
  1017918410: "Mental Marvin #1280",
  1017918444: "Mental Marvin #1281",
  1017918493: "Mental Marvin #1282",
  1017918517: "Mental Marvin #1283",
  1017918577: "Mental Marvin #1284",
  1017918628: "Mental Marvin #1285",
  1017918682: "Mental Marvin #1286",
  1017918746: "Mental Marvin #1287",
  1017918814: "Mental Marvin #1288",
  1017918867: "Mental Marvin #1289",
  1017918921: "Mental Marvin #1290",
  1017918949: "Mental Marvin #1291",
  1017919063: "Mental Marvin #1292",
  1017919129: "Mental Marvin #1293",
  1017919172: "Mental Marvin #1294",
  1017919217: "Mental Marvin #1295",
  1017919278: "Mental Marvin #1296",
  1017919321: "Mental Marvin #1297",
  1017919359: "Mental Marvin #1298",
  1017919398: "Mental Marvin #1299",
  1017919454: "Mental Marvin #1300",
  1017919496: "Mental Marvin #1301",
  1017919541: "Mental Marvin #1302",
  1017919577: "Mental Marvin #1303",
  1017919615: "Mental Marvin #1304",
  1017919650: "Mental Marvin #1305",
  1017919692: "Mental Marvin #1306",
  1017919720: "Mental Marvin #1307",
  1017919763: "Mental Marvin #1308",
  1017919796: "Mental Marvin #1309",
  1017919839: "Mental Marvin #1310",
  1017919869: "Mental Marvin #1311",
  1017919943: "Mental Marvin #1312",
  1017919991: "Mental Marvin #1313",
  1017920039: "Mental Marvin #1314",
  1017920106: "Mental Marvin #1315",
  1017920150: "Mental Marvin #1316",
  1017920184: "Mental Marvin #1317",
  1017920221: "Mental Marvin #1318",
  1017920262: "Mental Marvin #1319",
  1017920333: "Mental Marvin #1320",
  1017920400: "Mental Marvin #1321",
  1017920455: "Mental Marvin #1322",
  1017920492: "Mental Marvin #1323",
  1017920533: "Mental Marvin #1324",
  1017920652: "Mental Marvin #1325",
  1017920714: "Mental Marvin #1326",
  1017920769: "Mental Marvin #1327",
  1017920875: "Mental Marvin #1328",
  1017920911: "Mental Marvin #1329",
  1017920957: "Mental Marvin #1330",
  1017920989: "Mental Marvin #1331",
  1017921037: "Mental Marvin #1332",
  1017921101: "Mental Marvin #1333",
  1017921133: "Mental Marvin #1334",
  1017921176: "Mental Marvin #1335",
  1017921238: "Mental Marvin #1336",
  1017921280: "Mental Marvin #1337",
  1017921334: "Mental Marvin #1338",
  1017921384: "Mental Marvin #1339",
  1017921462: "Mental Marvin #1340",
  1017921515: "Mental Marvin #1341",
  1017921550: "Mental Marvin #1342",
  1017921605: "Mental Marvin #1343",
  1017921665: "Mental Marvin #1344",
  1017921700: "Mental Marvin #1345",
  1017921748: "Mental Marvin #1346",
  1017921798: "Mental Marvin #1347",
  1017921836: "Mental Marvin #1348",
  1017921873: "Mental Marvin #1349",
  1017921910: "Mental Marvin #1350",
  1017921979: "Mental Marvin #1351",
  1017922163: "Mental Marvin #1352",
  1017922222: "Mental Marvin #1353",
  1017922295: "Mental Marvin #1354",
  1017922341: "Mental Marvin #1355",
  1017922393: "Mental Marvin #1356",
  1017922442: "Mental Marvin #1357",
  1017922477: "Mental Marvin #1358",
  1017922506: "Mental Marvin #1359",
  1017922562: "Mental Marvin #1360",
  1017922614: "Mental Marvin #1361",
  1017922662: "Mental Marvin #1362",
  1017922716: "Mental Marvin #1363",
  1017922770: "Mental Marvin #1364",
  1017922805: "Mental Marvin #1365",
  1017922863: "Mental Marvin #1366",
  1017922888: "Mental Marvin #1367",
  1017922941: "Mental Marvin #1368",
  1017922978: "Mental Marvin #1369",
  1017923030: "Mental Marvin #1370",
  1017923108: "Mental Marvin #1371",
  1017923150: "Mental Marvin #1372",
  1017923186: "Mental Marvin #1373",
  1017923217: "Mental Marvin #1374",
  1017923274: "Mental Marvin #1375",
  1017923312: "Mental Marvin #1376",
  1017923357: "Mental Marvin #1377",
  1017923383: "Mental Marvin #1378",
  1017923436: "Mental Marvin #1379",
  1017923472: "Mental Marvin #1380",
  1017923509: "Mental Marvin #1381",
  1017923546: "Mental Marvin #1382",
  1017923576: "Mental Marvin #1383",
  1017923641: "Mental Marvin #1384",
  1017923696: "Mental Marvin #1385",
  1017923735: "Mental Marvin #1386",
  1017923782: "Mental Marvin #1387",
  1017923813: "Mental Marvin #1388",
  1017923840: "Mental Marvin #1389",
  1017923944: "Mental Marvin #1390",
  1017923962: "Mental Marvin #1391",
  1017924010: "Mental Marvin #1392",
  1017924064: "Mental Marvin #1393",
  1017924118: "Mental Marvin #1394",
  1017924236: "Mental Marvin #1395",
  1017924315: "Mental Marvin #1396",
  1017924355: "Mental Marvin #1397",
  1017924400: "Mental Marvin #1398",
  1017924432: "Mental Marvin #1399",
  1017924481: "Mental Marvin #1400",
  1017924520: "Mental Marvin #1401",
  1017924561: "Mental Marvin #1402",
  1017924599: "Mental Marvin #1403",
  1017924641: "Mental Marvin #1404",
  1017924669: "Mental Marvin #1405",
  1017924720: "Mental Marvin #1406",
  1017924762: "Mental Marvin #1407",
  1017924803: "Mental Marvin #1408",
  1017924861: "Mental Marvin #1409",
  1017924908: "Mental Marvin #1410",
  1017924966: "Mental Marvin #1411",
  1017925009: "Mental Marvin #1412",
  1017925057: "Mental Marvin #1413",
  1017925082: "Mental Marvin #1414",
  1017925125: "Mental Marvin #1415",
  1017925160: "Mental Marvin #1416",
  1017925198: "Mental Marvin #1417",
  1017925244: "Mental Marvin #1418",
  1017925272: "Mental Marvin #1419",
  1017925311: "Mental Marvin #1420",
  1017925341: "Mental Marvin #1421",
  1017925359: "Mental Marvin #1422",
  1017925406: "Mental Marvin #1423",
  1017925452: "Mental Marvin #1424",
  1017925488: "Mental Marvin #1425",
  1017925533: "Mental Marvin #1426",
  1017925574: "Mental Marvin #1427",
  1017925615: "Mental Marvin #1428",
  1017925649: "Mental Marvin #1429",
  1017925689: "Mental Marvin #1430",
  1017925724: "Mental Marvin #1431",
  1017925780: "Mental Marvin #1432",
  1017925889: "Mental Marvin #1433",
  1017925920: "Mental Marvin #1434",
  1017925968: "Mental Marvin #1435",
  1017926032: "Mental Marvin #1436",
  1017926062: "Mental Marvin #1437",
  1017926104: "Mental Marvin #1438",
  1017926151: "Mental Marvin #1439",
  1017926203: "Mental Marvin #1440",
  1017926265: "Mental Marvin #1441",
  1017926310: "Mental Marvin #1442",
  1017926367: "Mental Marvin #1443",
  1017926413: "Mental Marvin #1444",
  1017926463: "Mental Marvin #1445",
  1017926502: "Mental Marvin #1446",
  1017926556: "Mental Marvin #1447",
  1017926597: "Mental Marvin #1448",
  1017926650: "Mental Marvin #1449",
  1017926690: "Mental Marvin #1450",
  1017926731: "Mental Marvin #1451",
  1017926770: "Mental Marvin #1452",
  1017926824: "Mental Marvin #1453",
  1017926862: "Mental Marvin #1454",
  1017926898: "Mental Marvin #1455",
  1017926943: "Mental Marvin #1456",
  1017926983: "Mental Marvin #1457",
  1017927110: "Mental Marvin #1458",
  1017927158: "Mental Marvin #1459",
  1017927204: "Mental Marvin #1460",
  1017927252: "Mental Marvin #1461",
  1017927324: "Mental Marvin #1462",
  1017927356: "Mental Marvin #1463",
  1017927478: "Mental Marvin #1464",
  1017927542: "Mental Marvin #1465",
  1017927583: "Mental Marvin #1466",
  1017927642: "Mental Marvin #1467",
  1017927685: "Mental Marvin #1468",
  1017927758: "Mental Marvin #1469",
  1017927798: "Mental Marvin #1470",
  1017927868: "Mental Marvin #1471",
  1017927916: "Mental Marvin #1472",
  1017927946: "Mental Marvin #1473",
  1017927978: "Mental Marvin #1474",
  1017928018: "Mental Marvin #1475",
  1017928077: "Mental Marvin #1476",
  1017928100: "Mental Marvin #1477",
  1017928123: "Mental Marvin #1478",
  1017928153: "Mental Marvin #1479",
  1017928196: "Mental Marvin #1480",
  1017928245: "Mental Marvin #1481",
  1017928290: "Mental Marvin #1482",
  1017928328: "Mental Marvin #1483",
  1017928365: "Mental Marvin #1484",
  1017928401: "Mental Marvin #1485",
  1017928445: "Mental Marvin #1486",
  1017928502: "Mental Marvin #1487",
  1017928543: "Mental Marvin #1488",
  1017928596: "Mental Marvin #1489",
  1017928648: "Mental Marvin #1490",
  1017928698: "Mental Marvin #1491",
  1017928763: "Mental Marvin #1492",
  1017928799: "Mental Marvin #1493",
  1017928833: "Mental Marvin #1494",
  1017928860: "Mental Marvin #1495",
  1017928911: "Mental Marvin #1496",
  1017928944: "Mental Marvin #1497",
  1017928982: "Mental Marvin #1498",
  1017929013: "Mental Marvin #1499",
  1017929048: "Mental Marvin #1500",
  1017929081: "Mental Marvin #1501",
  1017929115: "Mental Marvin #1502",
  1017929137: "Mental Marvin #1503",
  1017929189: "Mental Marvin #1504",
  1017929213: "Mental Marvin #1505",
  1017929267: "Mental Marvin #1506",
  1017929328: "Mental Marvin #1507",
  1017929378: "Mental Marvin #1508",
  1017929408: "Mental Marvin #1509",
  1017929437: "Mental Marvin #1510",
  1017929470: "Mental Marvin #1511",
  1017929511: "Mental Marvin #1512",
  1017929542: "Mental Marvin #1513",
  1017929574: "Mental Marvin #1514",
  1017929621: "Mental Marvin #1515",
  1017929670: "Mental Marvin #1516",
  1017929720: "Mental Marvin #1517",
  1017929751: "Mental Marvin #1518",
  1017929791: "Mental Marvin #1519",
  1017929838: "Mental Marvin #1520",
  1017929904: "Mental Marvin #1521",
  1017929939: "Mental Marvin #1522",
  1017929971: "Mental Marvin #1523",
  1017930003: "Mental Marvin #1524",
  1017930050: "Mental Marvin #1525",
  1017930081: "Mental Marvin #1526",
  1017930112: "Mental Marvin #1527",
  1017930373: "Mental Marvin #1528",
  1017930505: "Mental Marvin #1529",
  1017930741: "Mental Marvin #1530",
  1017930814: "Mental Marvin #1531",
  1017931063: "Mental Marvin #1532",
  1017931134: "Mental Marvin #1533",
  1017931165: "Mental Marvin #1534",
  1017931209: "Mental Marvin #1535",
  1017931261: "Mental Marvin #1536",
  1017931308: "Mental Marvin #1537",
  1017931339: "Mental Marvin #1538",
  1017931454: "Mental Marvin #1539",
  1017931514: "Mental Marvin #1540",
  1017931581: "Mental Marvin #1541",
  1017931607: "Mental Marvin #1542",
  1017931672: "Mental Marvin #1543",
  1017931719: "Mental Marvin #1544",
  1017931794: "Mental Marvin #1545",
  1017931827: "Mental Marvin #1546",
  1017931861: "Mental Marvin #1547",
  1017931909: "Mental Marvin #1548",
  1017932014: "Mental Marvin #1549",
  1017932074: "Mental Marvin #1550",
  1017932121: "Mental Marvin #1551",
  1017932160: "Mental Marvin #1552",
  1017932205: "Mental Marvin #1553",
  1017932249: "Mental Marvin #1554",
  1017932292: "Mental Marvin #1555",
  1017932335: "Mental Marvin #1556",
  1017932366: "Mental Marvin #1557",
  1017932402: "Mental Marvin #1558",
  1017932447: "Mental Marvin #1559",
  1017932497: "Mental Marvin #1560",
  1017932556: "Mental Marvin #1561",
  1017932583: "Mental Marvin #1562",
  1017932616: "Mental Marvin #1563",
  1017932671: "Mental Marvin #1564",
  1017932704: "Mental Marvin #1565",
  1017932737: "Mental Marvin #1566",
  1017932790: "Mental Marvin #1567",
  1017932852: "Mental Marvin #1568",
  1017932927: "Mental Marvin #1569",
  1017932980: "Mental Marvin #1570",
  1017933070: "Mental Marvin #1571",
  1017933188: "Mental Marvin #1572",
  1017933213: "Mental Marvin #1573",
  1017933249: "Mental Marvin #1574",
  1017933295: "Mental Marvin #1575",
  1017933339: "Mental Marvin #1576",
  1017933380: "Mental Marvin #1577",
  1017933411: "Mental Marvin #1578",
  1017933469: "Mental Marvin #1579",
  1017933544: "Mental Marvin #1580",
  1017933605: "Mental Marvin #1581",
  1017933641: "Mental Marvin #1582",
  1017933679: "Mental Marvin #1583",
  1017933709: "Mental Marvin #1584",
  1017933761: "Mental Marvin #1585",
  1017933813: "Mental Marvin #1586",
  1017933853: "Mental Marvin #1587",
  1017933888: "Mental Marvin #1588",
  1017933937: "Mental Marvin #1589",
  1017933988: "Mental Marvin #1590",
  1017934040: "Mental Marvin #1591",
  1017934103: "Mental Marvin #1592",
  1017934143: "Mental Marvin #1593",
  1017934184: "Mental Marvin #1594",
  1017934221: "Mental Marvin #1595",
  1017934265: "Mental Marvin #1596",
  1017934305: "Mental Marvin #1597",
  1017934341: "Mental Marvin #1598",
  1017934372: "Mental Marvin #1599",
  1017934408: "Mental Marvin #1600",
  1017934452: "Mental Marvin #1601",
  1017934543: "Mental Marvin #1602",
  1017934590: "Mental Marvin #1603",
  1017934645: "Mental Marvin #1604",
  1017934702: "Mental Marvin #1605",
  1017934744: "Mental Marvin #1606",
  1017934784: "Mental Marvin #1607",
  1017934825: "Mental Marvin #1608",
  1017934861: "Mental Marvin #1609",
  1017934896: "Mental Marvin #1610",
  1017934936: "Mental Marvin #1611",
  1017934971: "Mental Marvin #1612",
  1017935006: "Mental Marvin #1613",
  1017935034: "Mental Marvin #1614",
  1017935080: "Mental Marvin #1615",
  1017935135: "Mental Marvin #1616",
  1017935167: "Mental Marvin #1617",
  1017935209: "Mental Marvin #1618",
  1017935255: "Mental Marvin #1619",
  1017935302: "Mental Marvin #1620",
  1017935350: "Mental Marvin #1621",
  1017935388: "Mental Marvin #1622",
  1017935426: "Mental Marvin #1623",
  1017935519: "Mental Marvin #1624",
  1017935572: "Mental Marvin #1625",
  1017935606: "Mental Marvin #1626",
  1017935683: "Mental Marvin #1627",
  1017935735: "Mental Marvin #1628",
  1017935771: "Mental Marvin #1629",
  1017935812: "Mental Marvin #1630",
  1017935869: "Mental Marvin #1631",
  1017936017: "Mental Marvin #1632",
  1017936098: "Mental Marvin #1633",
  1017936217: "Mental Marvin #1634",
  1017936482: "Mental Marvin #1635",
  1017936559: "Mental Marvin #1636",
  1017936613: "Mental Marvin #1637",
  1017936652: "Mental Marvin #1638",
  1017936776: "Mental Marvin #1639",
  1017936909: "Mental Marvin #1640",
  1017937030: "Mental Marvin #1641",
  1017937233: "Mental Marvin #1642",
  1017937369: "Mental Marvin #1643",
  1017937613: "Mental Marvin #1644",
  1017937809: "Mental Marvin #1645",
  1017938009: "Mental Marvin #1646",
  1017938140: "Mental Marvin #1647",
  1017938238: "Mental Marvin #1648",
  1017938286: "Mental Marvin #1649",
  1017938329: "Mental Marvin #1650",
  1017938356: "Mental Marvin #1651",
  1017938479: "Mental Marvin #1652",
  1017938627: "Mental Marvin #1653",
  1017938720: "Mental Marvin #1654",
  1017938749: "Mental Marvin #1655",
  1017939174: "Mental Marvin #1656",
  1017940026: "Mental Marvin #1657",
  1017940489: "Mental Marvin #1658",
  1017940863: "Mental Marvin #1659",
  1017941238: "Mental Marvin #1660",
  1017941557: "Mental Marvin #1661",
  1017941962: "Mental Marvin #1662",
  1017942318: "Mental Marvin #1663",
  1017942545: "Mental Marvin #1664",
  1017942650: "Mental Marvin #1665",
  1017942725: "Mental Marvin #1666",
  1017942787: "Mental Marvin #1667",
  1017942831: "Mental Marvin #1668",
  1017942879: "Mental Marvin #1669",
  1017942923: "Mental Marvin #1670",
  1017942963: "Mental Marvin #1671",
  1017943035: "Mental Marvin #1672",
  1017943078: "Mental Marvin #1673",
  1017943229: "Mental Marvin #1674",
  1017943268: "Mental Marvin #1675",
  1017943318: "Mental Marvin #1676",
  1017943387: "Mental Marvin #1677",
  1017943441: "Mental Marvin #1678",
  1017943485: "Mental Marvin #1679",
  1017943531: "Mental Marvin #1680",
  1017943603: "Mental Marvin #1681",
  1017943650: "Mental Marvin #1682",
  1017943695: "Mental Marvin #1683",
  1017943744: "Mental Marvin #1684",
  1017943783: "Mental Marvin #1685",
  1017943807: "Mental Marvin #1686",
  1017943864: "Mental Marvin #1687",
  1017943916: "Mental Marvin #1688",
  1017943957: "Mental Marvin #1689",
  1017943993: "Mental Marvin #1690",
  1017944041: "Mental Marvin #1691",
  1017944087: "Mental Marvin #1692",
  1017944174: "Mental Marvin #1693",
  1017944201: "Mental Marvin #1694",
  1017944229: "Mental Marvin #1695",
  1017944292: "Mental Marvin #1696",
  1017944472: "Mental Marvin #1697",
  1017944615: "Mental Marvin #1698",
  1017944721: "Mental Marvin #1699",
  1017944782: "Mental Marvin #1700",
  1017944845: "Mental Marvin #1701",
  1017944865: "Mental Marvin #1702",
  1017944906: "Mental Marvin #1703",
  1017944953: "Mental Marvin #1704",
  1017945027: "Mental Marvin #1705",
  1017945083: "Mental Marvin #1706",
  1017945117: "Mental Marvin #1707",
  1017945209: "Mental Marvin #1708",
  1017945282: "Mental Marvin #1709",
  1017945466: "Mental Marvin #1710",
  1017945696: "Mental Marvin #1711",
  1017945949: "Mental Marvin #1712",
  1017946063: "Mental Marvin #1713",
  1017946163: "Mental Marvin #1714",
  1017946577: "Mental Marvin #1715",
  1017946785: "Mental Marvin #1716",
  1017946941: "Mental Marvin #1717",
  1017947006: "Mental Marvin #1718",
  1017947066: "Mental Marvin #1719",
  1017947133: "Mental Marvin #1720",
  1017947181: "Mental Marvin #1721",
  1017947224: "Mental Marvin #1722",
  1017947273: "Mental Marvin #1723",
  1017947314: "Mental Marvin #1724",
  1017947347: "Mental Marvin #1725",
  1017947385: "Mental Marvin #1726",
  1017947431: "Mental Marvin #1727",
  1017947491: "Mental Marvin #1728",
  1017947556: "Mental Marvin #1729",
  1017947591: "Mental Marvin #1730",
  1017947654: "Mental Marvin #1731",
  1017947688: "Mental Marvin #1732",
  1017947728: "Mental Marvin #1733",
  1017947782: "Mental Marvin #1734",
  1017947824: "Mental Marvin #1735",
  1017947919: "Mental Marvin #1736",
  1017948069: "Mental Marvin #1737",
  1017948933: "Mental Marvin #1738",
  1017949270: "Mental Marvin #1739",
  1017949454: "Mental Marvin #1740",
  1017949593: "Mental Marvin #1741",
  1017949628: "Mental Marvin #1742",
  1017949650: "Mental Marvin #1743",
  1017949705: "Mental Marvin #1744",
  1017949741: "Mental Marvin #1745",
  1017949804: "Mental Marvin #1746",
  1017949864: "Mental Marvin #1747",
  1017950005: "Mental Marvin #1748",
  1017950057: "Mental Marvin #1749",
  1017950202: "Mental Marvin #1750",
  1017950244: "Mental Marvin #1751",
  1017950313: "Mental Marvin #1752",
  1017950470: "Mental Marvin #1753",
  1017950533: "Mental Marvin #1754",
  1017950572: "Mental Marvin #1755",
  1017950628: "Mental Marvin #1756",
  1017950961: "Mental Marvin #1757",
  1017951096: "Mental Marvin #1758",
  1017951412: "Mental Marvin #1759",
  1017951581: "Mental Marvin #1760",
  1017951945: "Mental Marvin #1761",
  1017952060: "Mental Marvin #1762",
  1017952389: "Mental Marvin #1763",
  1017952746: "Mental Marvin #1764",
  1017953116: "Mental Marvin #1765",
  1017953376: "Mental Marvin #1766",
  1017953480: "Mental Marvin #1767",
  1017953660: "Mental Marvin #1768",
  1017954111: "Mental Marvin #1769",
  1017954618: "Mental Marvin #1770",
  1017954695: "Mental Marvin #1771",
  1017955101: "Mental Marvin #1772",
  1017955333: "Mental Marvin #1773",
  1017955760: "Mental Marvin #1774",
  1017955886: "Mental Marvin #1775",
  1017955971: "Mental Marvin #1776",
  1017956044: "Mental Marvin #1777",
  1017956087: "Mental Marvin #1778",
  1017956159: "Mental Marvin #1779",
  1017956210: "Mental Marvin #1780",
  1017956262: "Mental Marvin #1781",
  1017956307: "Mental Marvin #1782",
  1017956375: "Mental Marvin #1783",
  1017956443: "Mental Marvin #1784",
  1017956490: "Mental Marvin #1785",
  1017956534: "Mental Marvin #1786",
  1017956564: "Mental Marvin #1787",
  1017956612: "Mental Marvin #1788",
  1017956645: "Mental Marvin #1789",
  1017956676: "Mental Marvin #1790",
  1017956714: "Mental Marvin #1791",
  1017956860: "Mental Marvin #1792",
  1017956922: "Mental Marvin #1793",
  1017956983: "Mental Marvin #1794",
  1017957032: "Mental Marvin #1795",
  1017957104: "Mental Marvin #1796",
  1017957171: "Mental Marvin #1797",
  1017957199: "Mental Marvin #1798",
  1017957244: "Mental Marvin #1799",
  1017957360: "Mental Marvin #1800",
  1017957415: "Mental Marvin #1801",
  1017957480: "Mental Marvin #1802",
  1017957525: "Mental Marvin #1803",
  1017957566: "Mental Marvin #1804",
  1017957618: "Mental Marvin #1805",
  1017957669: "Mental Marvin #1806",
  1017957710: "Mental Marvin #1807",
  1017957771: "Mental Marvin #1808",
  1017957851: "Mental Marvin #1809",
  1017957944: "Mental Marvin #1810",
  1017957990: "Mental Marvin #1811",
  1017958104: "Mental Marvin #1812",
  1017958175: "Mental Marvin #1813",
  1017958280: "Mental Marvin #1814",
  1017958315: "Mental Marvin #1815",
  1017958405: "Mental Marvin #1816",
  1017958493: "Mental Marvin #1817",
  1017958612: "Mental Marvin #1818",
  1017958721: "Mental Marvin #1819",
  1017958766: "Mental Marvin #1820",
  1017958864: "Mental Marvin #1821",
  1017958888: "Mental Marvin #1822",
  1017958932: "Mental Marvin #1823",
  1017958975: "Mental Marvin #1824",
  1017959031: "Mental Marvin #1825",
  1017959081: "Mental Marvin #1826",
  1017959122: "Mental Marvin #1827",
  1017959197: "Mental Marvin #1828",
  1017959239: "Mental Marvin #1829",
  1017959276: "Mental Marvin #1830",
  1017959317: "Mental Marvin #1831",
  1017959357: "Mental Marvin #1832",
  1017959385: "Mental Marvin #1833",
  1017959436: "Mental Marvin #1834",
  1017959470: "Mental Marvin #1835",
  1017959537: "Mental Marvin #1836",
  1017959599: "Mental Marvin #1837",
  1017959627: "Mental Marvin #1838",
  1017959657: "Mental Marvin #1839",
  1017959702: "Mental Marvin #1840",
  1017959741: "Mental Marvin #1841",
  1017959787: "Mental Marvin #1842",
  1017959841: "Mental Marvin #1843",
  1017959937: "Mental Marvin #1844",
  1017959982: "Mental Marvin #1845",
  1017960069: "Mental Marvin #1846",
  1017960129: "Mental Marvin #1847",
  1017960214: "Mental Marvin #1848",
  1017960253: "Mental Marvin #1849",
  1017960283: "Mental Marvin #1850",
  1017960317: "Mental Marvin #1851",
  1017960369: "Mental Marvin #1852",
  1017960520: "Mental Marvin #1853",
  1017960744: "Mental Marvin #1854",
  1017960982: "Mental Marvin #1855",
  1017961136: "Mental Marvin #1856",
  1017961194: "Mental Marvin #1857",
  1017961241: "Mental Marvin #1858",
  1017961278: "Mental Marvin #1859",
  1017961323: "Mental Marvin #1860",
  1017961352: "Mental Marvin #1861",
  1017962049: "Mental Marvin #1862",
  1017962261: "Mental Marvin #1863",
  1017962389: "Mental Marvin #1864",
  1017962488: "Mental Marvin #1865",
  1017962563: "Mental Marvin #1866",
  1017962618: "Mental Marvin #1867",
  1017962682: "Mental Marvin #1868",
  1017962823: "Mental Marvin #1869",
  1017962850: "Mental Marvin #1870",
  1017962888: "Mental Marvin #1871",
  1017962919: "Mental Marvin #1872",
  1017962975: "Mental Marvin #1873",
  1017963033: "Mental Marvin #1874",
  1017963076: "Mental Marvin #1875",
  1017963133: "Mental Marvin #1876",
  1017963174: "Mental Marvin #1877",
  1017963208: "Mental Marvin #1878",
  1017963249: "Mental Marvin #1879",
  1017963298: "Mental Marvin #1880",
  1017963350: "Mental Marvin #1881",
  1017963387: "Mental Marvin #1882",
  1017963450: "Mental Marvin #1883",
  1017963494: "Mental Marvin #1884",
  1017963534: "Mental Marvin #1885",
  1017963574: "Mental Marvin #1886",
  1017963607: "Mental Marvin #1887",
  1017963649: "Mental Marvin #1888",
  1017963720: "Mental Marvin #1889",
  1017963757: "Mental Marvin #1890",
  1017963887: "Mental Marvin #1891",
  1017964036: "Mental Marvin #1892",
  1017964126: "Mental Marvin #1893",
  1017964163: "Mental Marvin #1894",
  1017964198: "Mental Marvin #1895",
  1017964239: "Mental Marvin #1896",
  1017964297: "Mental Marvin #1897",
  1017964325: "Mental Marvin #1898",
  1017964382: "Mental Marvin #1899",
  1017964424: "Mental Marvin #1900",
  1017964454: "Mental Marvin #1901",
  1017964497: "Mental Marvin #1902",
  1017964536: "Mental Marvin #1903",
  1017964634: "Mental Marvin #1904",
  1017964669: "Mental Marvin #1905",
  1017964716: "Mental Marvin #1906",
  1017964775: "Mental Marvin #1907",
  1017964830: "Mental Marvin #1908",
  1017964883: "Mental Marvin #1909",
  1017964953: "Mental Marvin #1910",
  1017964984: "Mental Marvin #1911",
  1017965022: "Mental Marvin #1912",
  1017965055: "Mental Marvin #1913",
  1017965117: "Mental Marvin #1914",
  1017965226: "Mental Marvin #1915",
  1017965293: "Mental Marvin #1916",
  1017965348: "Mental Marvin #1917",
  1017965430: "Mental Marvin #1918",
  1017965459: "Mental Marvin #1919",
  1017965510: "Mental Marvin #1920",
  1017965561: "Mental Marvin #1921",
  1017965627: "Mental Marvin #1922",
  1017965766: "Mental Marvin #1923",
  1017965824: "Mental Marvin #1924",
  1017965869: "Mental Marvin #1925",
  1017965905: "Mental Marvin #1926",
  1017965942: "Mental Marvin #1927",
  1017966178: "Mental Marvin #1928",
  1017966357: "Mental Marvin #1929",
  1017966642: "Mental Marvin #1930",
  1017966705: "Mental Marvin #1931",
  1017966856: "Mental Marvin #1932",
  1017966925: "Mental Marvin #1933",
  1017966952: "Mental Marvin #1934",
  1017967028: "Mental Marvin #1935",
  1017967140: "Mental Marvin #1936",
  1017967192: "Mental Marvin #1937",
  1017967251: "Mental Marvin #1938",
  1017967293: "Mental Marvin #1939",
  1017967349: "Mental Marvin #1940",
  1017967405: "Mental Marvin #1941",
  1017967438: "Mental Marvin #1942",
  1017967481: "Mental Marvin #1943",
  1017967537: "Mental Marvin #1944",
  1017967564: "Mental Marvin #1945",
  1017967603: "Mental Marvin #1946",
  1017967648: "Mental Marvin #1947",
  1017967696: "Mental Marvin #1948",
  1017967770: "Mental Marvin #1949",
  1017967808: "Mental Marvin #1950",
  1017967860: "Mental Marvin #1951",
  1017967924: "Mental Marvin #1952",
  1017967984: "Mental Marvin #1953",
  1017968031: "Mental Marvin #1954",
  1017968091: "Mental Marvin #1955",
  1017968140: "Mental Marvin #1956",
  1017968170: "Mental Marvin #1957",
  1017968220: "Mental Marvin #1958",
  1017968301: "Mental Marvin #1959",
  1017968354: "Mental Marvin #1960",
  1017968388: "Mental Marvin #1961",
  1017968426: "Mental Marvin #1962",
  1017968480: "Mental Marvin #1963",
  1017968543: "Mental Marvin #1964",
  1017968601: "Mental Marvin #1965",
  1017968654: "Mental Marvin #1966",
  1017968684: "Mental Marvin #1967",
  1017968743: "Mental Marvin #1968",
  1017968812: "Mental Marvin #1969",
  1017968868: "Mental Marvin #1970",
  1017969129: "Mental Marvin #1971",
  1017969267: "Mental Marvin #1972",
  1017969328: "Mental Marvin #1973",
  1017969406: "Mental Marvin #1974",
  1017969491: "Mental Marvin #1975",
  1017969579: "Mental Marvin #1976",
  1017969609: "Mental Marvin #1977",
  1017969645: "Mental Marvin #1978",
  1017969717: "Mental Marvin #1979",
  1017969793: "Mental Marvin #1980",
  1017969844: "Mental Marvin #1981",
  1017969900: "Mental Marvin #1982",
  1017969952: "Mental Marvin #1983",
  1017970004: "Mental Marvin #1984",
  1017970037: "Mental Marvin #1985",
  1017970074: "Mental Marvin #1986",
  1017970118: "Mental Marvin #1987",
  1017970169: "Mental Marvin #1988",
  1017970214: "Mental Marvin #1989",
  1017970276: "Mental Marvin #1990",
  1017970321: "Mental Marvin #1991",
  1017970378: "Mental Marvin #1992",
  1017970416: "Mental Marvin #1993",
  1017970556: "Mental Marvin #1994",
  1017970647: "Mental Marvin #1995",
  1017970709: "Mental Marvin #1996",
  1017970754: "Mental Marvin #1997",
  1017970802: "Mental Marvin #1998",
  1017970854: "Mental Marvin #1999",
  1017970937: "Mental Marvin #2000",
  1017970980: "Mental Marvin #2001",
  1017971060: "Mental Marvin #2002",
  1017971103: "Mental Marvin #2003",
  1017971165: "Mental Marvin #2004",
  1017971221: "Mental Marvin #2005",
  1017971276: "Mental Marvin #2006",
  1017971347: "Mental Marvin #2007",
  1017971417: "Mental Marvin #2008",
  1017971491: "Mental Marvin #2009",
  1017971575: "Mental Marvin #2010",
  1017971626: "Mental Marvin #2011",
  1017971692: "Mental Marvin #2012",
  1017971744: "Mental Marvin #2013",
  1017971785: "Mental Marvin #2014",
  1017971839: "Mental Marvin #2015",
  1017971976: "Mental Marvin #2016",
  1017972107: "Mental Marvin #2017",
  1017972213: "Mental Marvin #2018",
  1017972278: "Mental Marvin #2019",
  1017972424: "Mental Marvin #2020",
  1017972482: "Mental Marvin #2021",
  1017972569: "Mental Marvin #2022",
  1017972620: "Mental Marvin #2023",
  1017972702: "Mental Marvin #2024",
  1017972756: "Mental Marvin #2025",
  1017972834: "Mental Marvin #2026",
  1017972892: "Mental Marvin #2027",
  1017972958: "Mental Marvin #2028",
  1017972997: "Mental Marvin #2029",
  1017973050: "Mental Marvin #2030",
  1017973093: "Mental Marvin #2031",
  1017973137: "Mental Marvin #2032",
  1017973162: "Mental Marvin #2033",
  1017973212: "Mental Marvin #2034",
  1017973243: "Mental Marvin #2035",
  1017973291: "Mental Marvin #2036",
  1017973326: "Mental Marvin #2037",
  1017973351: "Mental Marvin #2038",
  1017973417: "Mental Marvin #2039",
  1017973467: "Mental Marvin #2040",
  1017973510: "Mental Marvin #2041",
  1017973560: "Mental Marvin #2042",
  1017973608: "Mental Marvin #2043",
  1017973677: "Mental Marvin #2044",
  1017973722: "Mental Marvin #2045",
  1017973780: "Mental Marvin #2046",
  1017973821: "Mental Marvin #2047",
  1017973887: "Mental Marvin #2048",
  1017973920: "Mental Marvin #2049",
  1017974017: "Mental Marvin #2050",
  1017974090: "Mental Marvin #2051",
  1017974134: "Mental Marvin #2052",
  1017974184: "Mental Marvin #2053",
  1017974214: "Mental Marvin #2054",
  1017974252: "Mental Marvin #2055",
  1017974298: "Mental Marvin #2056",
  1017974358: "Mental Marvin #2057",
  1017974402: "Mental Marvin #2058",
  1017974431: "Mental Marvin #2059",
  1017974477: "Mental Marvin #2060",
  1017974513: "Mental Marvin #2061",
  1017974542: "Mental Marvin #2062",
  1017974567: "Mental Marvin #2063",
  1017974632: "Mental Marvin #2064",
  1017974673: "Mental Marvin #2065",
  1017974719: "Mental Marvin #2066",
  1017974762: "Mental Marvin #2067",
  1017974815: "Mental Marvin #2068",
  1017974855: "Mental Marvin #2069",
  1017974898: "Mental Marvin #2070",
  1017974938: "Mental Marvin #2071",
  1017974977: "Mental Marvin #2072",
  1017975014: "Mental Marvin #2073",
  1017975055: "Mental Marvin #2074",
  1017975104: "Mental Marvin #2075",
  1017975157: "Mental Marvin #2076",
  1017975198: "Mental Marvin #2077",
  1017975259: "Mental Marvin #2078",
  1017975283: "Mental Marvin #2079",
  1017975361: "Mental Marvin #2080",
  1017975400: "Mental Marvin #2081",
  1017975442: "Mental Marvin #2082",
  1017975506: "Mental Marvin #2083",
  1017975566: "Mental Marvin #2084",
  1017975631: "Mental Marvin #2085",
  1017975660: "Mental Marvin #2086",
  1017975702: "Mental Marvin #2087",
  1017975746: "Mental Marvin #2088",
  1017975791: "Mental Marvin #2089",
  1017975841: "Mental Marvin #2090",
  1017975890: "Mental Marvin #2091",
  1017975940: "Mental Marvin #2092",
  1017975991: "Mental Marvin #2093",
  1017976026: "Mental Marvin #2094",
  1017976082: "Mental Marvin #2095",
  1017976124: "Mental Marvin #2096",
  1017976180: "Mental Marvin #2097",
  1017976220: "Mental Marvin #2098",
  1017976262: "Mental Marvin #2099",
  1017976349: "Mental Marvin #2100",
  1017976399: "Mental Marvin #2101",
  1017976435: "Mental Marvin #2102",
  1017976477: "Mental Marvin #2103",
  1017976523: "Mental Marvin #2104",
  1017976567: "Mental Marvin #2105",
  1017976627: "Mental Marvin #2106",
  1017976664: "Mental Marvin #2107",
  1017976813: "Mental Marvin #2108",
  1017976875: "Mental Marvin #2109",
  1017976981: "Mental Marvin #2110",
  1017977029: "Mental Marvin #2111",
  1017977072: "Mental Marvin #2112",
  1017977145: "Mental Marvin #2113",
  1017977198: "Mental Marvin #2114",
  1017977253: "Mental Marvin #2115",
  1017977301: "Mental Marvin #2116",
  1017977341: "Mental Marvin #2117",
  1017977382: "Mental Marvin #2118",
  1017977527: "Mental Marvin #2119",
  1017977622: "Mental Marvin #2120",
  1017977663: "Mental Marvin #2121",
  1017977739: "Mental Marvin #2122",
  1017977819: "Mental Marvin #2123",
  1017977882: "Mental Marvin #2124",
  1017977923: "Mental Marvin #2125",
  1017977986: "Mental Marvin #2126",
  1017978065: "Mental Marvin #2127",
  1017978129: "Mental Marvin #2128",
  1017978165: "Mental Marvin #2129",
  1017978206: "Mental Marvin #2130",
  1017978260: "Mental Marvin #2131",
  1017978316: "Mental Marvin #2132",
  1017978365: "Mental Marvin #2133",
  1017978430: "Mental Marvin #2134",
  1017978459: "Mental Marvin #2135",
  1017978501: "Mental Marvin #2136",
  1017978525: "Mental Marvin #2137",
  1017978581: "Mental Marvin #2138",
  1017978643: "Mental Marvin #2139",
  1017978704: "Mental Marvin #2140",
  1017978746: "Mental Marvin #2141",
  1017978786: "Mental Marvin #2142",
  1017978820: "Mental Marvin #2143",
  1017978965: "Mental Marvin #2144",
  1017979025: "Mental Marvin #2145",
  1017979063: "Mental Marvin #2146",
  1017979100: "Mental Marvin #2147",
  1017979156: "Mental Marvin #2148",
  1017979191: "Mental Marvin #2149",
  1017979224: "Mental Marvin #2150",
  1017979292: "Mental Marvin #2151",
  1017979351: "Mental Marvin #2152",
  1017979381: "Mental Marvin #2153",
  1017979410: "Mental Marvin #2154",
  1017979446: "Mental Marvin #2155",
  1017979484: "Mental Marvin #2156",
  1017979526: "Mental Marvin #2157",
  1017979572: "Mental Marvin #2158",
  1017979626: "Mental Marvin #2159",
  1017979679: "Mental Marvin #2160",
  1017979727: "Mental Marvin #2161",
  1017979779: "Mental Marvin #2162",
  1017979818: "Mental Marvin #2163",
  1017979868: "Mental Marvin #2164",
  1017979914: "Mental Marvin #2165",
  1017979948: "Mental Marvin #2166",
  1017979983: "Mental Marvin #2167",
  1017980086: "Mental Marvin #2168",
  1017980145: "Mental Marvin #2169",
  1017980214: "Mental Marvin #2170",
  1017980269: "Mental Marvin #2171",
  1017980338: "Mental Marvin #2172",
  1017980385: "Mental Marvin #2173",
  1017980422: "Mental Marvin #2174",
  1017980483: "Mental Marvin #2175",
  1017980553: "Mental Marvin #2176",
  1017980623: "Mental Marvin #2177",
  1017980675: "Mental Marvin #2178",
  1017980722: "Mental Marvin #2179",
  1017980789: "Mental Marvin #2180",
  1017980832: "Mental Marvin #2181",
  1017980857: "Mental Marvin #2182",
  1017980891: "Mental Marvin #2183",
  1017980926: "Mental Marvin #2184",
  1017980977: "Mental Marvin #2185",
  1017981007: "Mental Marvin #2186",
  1017981037: "Mental Marvin #2187",
  1017981090: "Mental Marvin #2188",
  1017981194: "Mental Marvin #2189",
  1017981216: "Mental Marvin #2190",
  1017981258: "Mental Marvin #2191",
  1017981304: "Mental Marvin #2192",
  1017981349: "Mental Marvin #2193",
  1017981373: "Mental Marvin #2194",
  1017981412: "Mental Marvin #2195",
  1017981458: "Mental Marvin #2196",
  1017981488: "Mental Marvin #2197",
  1017981520: "Mental Marvin #2198",
  1017981544: "Mental Marvin #2199",
  1017981597: "Mental Marvin #2200",
  1017981627: "Mental Marvin #2201",
  1017981700: "Mental Marvin #2202",
  1017981731: "Mental Marvin #2203",
  1017982117: "Mental Marvin #2204",
  1017982165: "Mental Marvin #2205",
  1017982200: "Mental Marvin #2206",
  1017982242: "Mental Marvin #2207",
  1017982283: "Mental Marvin #2208",
  1017982331: "Mental Marvin #2209",
  1017982372: "Mental Marvin #2210",
  1017982454: "Mental Marvin #2211",
  1017982499: "Mental Marvin #2212",
  1017982566: "Mental Marvin #2213",
  1017982612: "Mental Marvin #2214",
  1017982665: "Mental Marvin #2215",
  1017982711: "Mental Marvin #2216",
  1017982745: "Mental Marvin #2217",
  1017982778: "Mental Marvin #2218",
  1017982832: "Mental Marvin #2219",
  1017982881: "Mental Marvin #2220",
  1017982918: "Mental Marvin #2221",
  1017982978: "Mental Marvin #2222",
  1017983072: "Mental Marvin #2223",
  1017983394: "Mental Marvin #2224",
  1017983507: "Mental Marvin #2225",
  1017983661: "Mental Marvin #2226",
  1017983821: "Mental Marvin #2227",
  1017983866: "Mental Marvin #2228",
  1017983914: "Mental Marvin #2229",
  1017983968: "Mental Marvin #2230",
  1017984007: "Mental Marvin #2231",
  1017984061: "Mental Marvin #2232",
  1017984106: "Mental Marvin #2233",
  1017984149: "Mental Marvin #2234",
  1017984204: "Mental Marvin #2235",
  1017984265: "Mental Marvin #2236",
  1017984329: "Mental Marvin #2237",
  1017984362: "Mental Marvin #2238",
  1017984404: "Mental Marvin #2239",
  1017984459: "Mental Marvin #2240",
  1017984501: "Mental Marvin #2241",
  1017984543: "Mental Marvin #2242",
  1017984587: "Mental Marvin #2243",
  1017984631: "Mental Marvin #2244",
  1017984675: "Mental Marvin #2245",
  1017984703: "Mental Marvin #2246",
  1017984746: "Mental Marvin #2247",
  1017984783: "Mental Marvin #2248",
  1017984827: "Mental Marvin #2249",
  1017984872: "Mental Marvin #2250",
  1017984913: "Mental Marvin #2251",
  1017984968: "Mental Marvin #2252",
  1017985010: "Mental Marvin #2253",
  1017985038: "Mental Marvin #2254",
  1017985074: "Mental Marvin #2255",
  1017985116: "Mental Marvin #2256",
  1017985182: "Mental Marvin #2257",
  1017985230: "Mental Marvin #2258",
  1017985264: "Mental Marvin #2259",
  1017985307: "Mental Marvin #2260",
  1017985351: "Mental Marvin #2261",
  1017985384: "Mental Marvin #2262",
  1017985415: "Mental Marvin #2263",
  1017985477: "Mental Marvin #2264",
  1017985512: "Mental Marvin #2265",
  1017985551: "Mental Marvin #2266",
  1017985591: "Mental Marvin #2267",
  1017985657: "Mental Marvin #2268",
  1017985690: "Mental Marvin #2269",
  1017985731: "Mental Marvin #2270",
  1017985762: "Mental Marvin #2271",
  1017985835: "Mental Marvin #2272",
  1017985873: "Mental Marvin #2273",
  1017985915: "Mental Marvin #2274",
  1017986030: "Mental Marvin #2275",
  1017986077: "Mental Marvin #2276",
  1017986130: "Mental Marvin #2277",
  1017986203: "Mental Marvin #2278",
  1017986237: "Mental Marvin #2279",
  1017986303: "Mental Marvin #2280",
  1017986334: "Mental Marvin #2281",
  1017986374: "Mental Marvin #2282",
  1017986413: "Mental Marvin #2283",
  1017986463: "Mental Marvin #2284",
  1017986515: "Mental Marvin #2285",
  1017986551: "Mental Marvin #2286",
  1017986588: "Mental Marvin #2287",
  1017986637: "Mental Marvin #2288",
  1017986678: "Mental Marvin #2289",
  1017986710: "Mental Marvin #2290",
  1017986751: "Mental Marvin #2291",
  1017986806: "Mental Marvin #2292",
  1017986846: "Mental Marvin #2293",
  1017986885: "Mental Marvin #2294",
  1017986930: "Mental Marvin #2295",
  1017986974: "Mental Marvin #2296",
  1017986999: "Mental Marvin #2297",
  1017987038: "Mental Marvin #2298",
  1017987085: "Mental Marvin #2299",
  1017987163: "Mental Marvin #2300",
  1017987186: "Mental Marvin #2301",
  1017987214: "Mental Marvin #2302",
  1017987252: "Mental Marvin #2303",
  1017987294: "Mental Marvin #2304",
  1017987342: "Mental Marvin #2305",
  1017987405: "Mental Marvin #2306",
  1017987464: "Mental Marvin #2307",
  1017987520: "Mental Marvin #2308",
  1017987563: "Mental Marvin #2309",
  1017987598: "Mental Marvin #2310",
  1017987639: "Mental Marvin #2311",
  1017987682: "Mental Marvin #2312",
  1017987724: "Mental Marvin #2313",
  1017987754: "Mental Marvin #2314",
  1017987791: "Mental Marvin #2315",
  1017987843: "Mental Marvin #2316",
  1017987907: "Mental Marvin #2317",
  1017987935: "Mental Marvin #2318",
  1017987987: "Mental Marvin #2319",
  1017988053: "Mental Marvin #2320",
  1017988122: "Mental Marvin #2321",
  1017988165: "Mental Marvin #2322",
  1017988206: "Mental Marvin #2323",
  1017988264: "Mental Marvin #2324",
  1017988325: "Mental Marvin #2325",
  1017988360: "Mental Marvin #2326",
  1017988462: "Mental Marvin #2327",
  1017988591: "Mental Marvin #2328",
  1017988677: "Mental Marvin #2329",
  1017988712: "Mental Marvin #2330",
  1017988770: "Mental Marvin #2331",
  1017988890: "Mental Marvin #2332",
  1017988916: "Mental Marvin #2333",
  1017988949: "Mental Marvin #2334",
  1017988984: "Mental Marvin #2335",
  1017989040: "Mental Marvin #2336",
  1017989104: "Mental Marvin #2337",
  1017989144: "Mental Marvin #2338",
  1017989199: "Mental Marvin #2339",
  1017989274: "Mental Marvin #2340",
  1017989313: "Mental Marvin #2341",
  1017989377: "Mental Marvin #2342",
  1017989408: "Mental Marvin #2343",
  1017989460: "Mental Marvin #2344",
  1017989512: "Mental Marvin #2345",
  1017989600: "Mental Marvin #2346",
  1017989633: "Mental Marvin #2347",
  1017989675: "Mental Marvin #2348",
  1017989710: "Mental Marvin #2349",
  1017989751: "Mental Marvin #2350",
  1017989807: "Mental Marvin #2351",
  1017989840: "Mental Marvin #2352",
  1017989879: "Mental Marvin #2353",
  1017989918: "Mental Marvin #2354",
  1017989961: "Mental Marvin #2355",
  1017990012: "Mental Marvin #2356",
  1017990053: "Mental Marvin #2357",
  1017990088: "Mental Marvin #2358",
  1017990121: "Mental Marvin #2359",
  1017990164: "Mental Marvin #2360",
  1017990215: "Mental Marvin #2361",
  1017990265: "Mental Marvin #2362",
  1017990302: "Mental Marvin #2363",
  1017990460: "Mental Marvin #2364",
  1017990578: "Mental Marvin #2365",
  1017990664: "Mental Marvin #2366",
  1017990736: "Mental Marvin #2367",
  1017990783: "Mental Marvin #2368",
  1017990841: "Mental Marvin #2369",
  1017990888: "Mental Marvin #2370",
  1017990960: "Mental Marvin #2371",
  1017991024: "Mental Marvin #2372",
  1017991068: "Mental Marvin #2373",
  1017991107: "Mental Marvin #2374",
  1017991133: "Mental Marvin #2375",
  1017991175: "Mental Marvin #2376",
  1017991254: "Mental Marvin #2377",
  1017991289: "Mental Marvin #2378",
  1017991336: "Mental Marvin #2379",
  1017991389: "Mental Marvin #2380",
  1017991445: "Mental Marvin #2381",
  1017991511: "Mental Marvin #2382",
  1017991546: "Mental Marvin #2383",
  1017991720: "Mental Marvin #2384",
  1017991816: "Mental Marvin #2385",
  1017991859: "Mental Marvin #2386",
  1017991914: "Mental Marvin #2387",
  1017992010: "Mental Marvin #2388",
  1017992042: "Mental Marvin #2389",
  1017992074: "Mental Marvin #2390",
  1017992170: "Mental Marvin #2391",
  1017992226: "Mental Marvin #2392",
  1017992264: "Mental Marvin #2393",
  1017992312: "Mental Marvin #2394",
  1017992424: "Mental Marvin #2395",
  1017992461: "Mental Marvin #2396",
  1017992513: "Mental Marvin #2397",
  1017992572: "Mental Marvin #2398",
  1017992599: "Mental Marvin #2399",
  1017992647: "Mental Marvin #2400",
  1017992711: "Mental Marvin #2401",
  1017992806: "Mental Marvin #2402",
  1017992843: "Mental Marvin #2403",
  1017993261: "Mental Marvin #2404",
  1017993314: "Mental Marvin #2405",
  1017993360: "Mental Marvin #2406",
  1017993391: "Mental Marvin #2407",
  1017993449: "Mental Marvin #2408",
  1017993533: "Mental Marvin #2409",
  1017993614: "Mental Marvin #2410",
  1017993652: "Mental Marvin #2411",
  1017994068: "Mental Marvin #2412",
  1017994142: "Mental Marvin #2413",
  1017994168: "Mental Marvin #2414",
  1017994206: "Mental Marvin #2415",
  1017994246: "Mental Marvin #2416",
  1017994290: "Mental Marvin #2417",
  1017994328: "Mental Marvin #2418",
  1017994380: "Mental Marvin #2419",
  1017994425: "Mental Marvin #2420",
  1017994481: "Mental Marvin #2421",
  1017994533: "Mental Marvin #2422",
  1017994598: "Mental Marvin #2423",
  1017994661: "Mental Marvin #2424",
  1017994768: "Mental Marvin #2425",
  1017994878: "Mental Marvin #2426",
  1017994910: "Mental Marvin #2427",
  1017994962: "Mental Marvin #2428",
  1017995056: "Mental Marvin #2429",
  1017995108: "Mental Marvin #2430",
  1017995147: "Mental Marvin #2431",
  1017995203: "Mental Marvin #2432",
  1017995260: "Mental Marvin #2433",
  1017995306: "Mental Marvin #2434",
  1017995354: "Mental Marvin #2435",
  1017995411: "Mental Marvin #2436",
  1017995547: "Mental Marvin #2437",
  1017995583: "Mental Marvin #2438",
  1017995620: "Mental Marvin #2439",
  1017995659: "Mental Marvin #2440",
  1017995722: "Mental Marvin #2441",
  1017995757: "Mental Marvin #2442",
  1017995803: "Mental Marvin #2443",
  1017995851: "Mental Marvin #2444",
  1017996018: "Mental Marvin #2445",
  1017996058: "Mental Marvin #2446",
  1017996098: "Mental Marvin #2447",
  1017996148: "Mental Marvin #2448",
  1017996188: "Mental Marvin #2449",
  1017996235: "Mental Marvin #2450",
  1017996288: "Mental Marvin #2451",
  1017996351: "Mental Marvin #2452",
  1017996401: "Mental Marvin #2453",
  1017996432: "Mental Marvin #2454",
  1017996490: "Mental Marvin #2455",
  1017996548: "Mental Marvin #2456",
  1017996583: "Mental Marvin #2457",
  1017996626: "Mental Marvin #2458",
  1017996689: "Mental Marvin #2459",
  1017996744: "Mental Marvin #2460",
  1017996803: "Mental Marvin #2461",
  1017996853: "Mental Marvin #2462",
  1017996889: "Mental Marvin #2463",
  1017996949: "Mental Marvin #2464",
  1017996992: "Mental Marvin #2465",
  1017997055: "Mental Marvin #2466",
  1017997189: "Mental Marvin #2467",
  1017997237: "Mental Marvin #2468",
  1017997286: "Mental Marvin #2469",
  1017997355: "Mental Marvin #2470",
  1017997389: "Mental Marvin #2471",
  1017997446: "Mental Marvin #2472",
  1017997501: "Mental Marvin #2473",
  1017997554: "Mental Marvin #2474",
  1017997756: "Mental Marvin #2475",
  1017997802: "Mental Marvin #2476",
  1017997838: "Mental Marvin #2477",
  1017997893: "Mental Marvin #2478",
  1017997923: "Mental Marvin #2479",
  1017997992: "Mental Marvin #2480",
  1017998028: "Mental Marvin #2481",
  1017998101: "Mental Marvin #2482",
  1017998158: "Mental Marvin #2483",
  1017998204: "Mental Marvin #2484",
  1017998274: "Mental Marvin #2485",
  1017998318: "Mental Marvin #2486",
  1017998374: "Mental Marvin #2487",
  1017998439: "Mental Marvin #2488",
  1017998477: "Mental Marvin #2489",
  1017998523: "Mental Marvin #2490",
  1017998573: "Mental Marvin #2491",
  1017998643: "Mental Marvin #2492",
  1017998718: "Mental Marvin #2493",
  1017998750: "Mental Marvin #2494",
  1017998786: "Mental Marvin #2495",
  1017998825: "Mental Marvin #2496",
  1017998862: "Mental Marvin #2497",
  1017998901: "Mental Marvin #2498",
  1017998956: "Mental Marvin #2499",
  1017999011: "Mental Marvin #2500",
  1017999052: "Mental Marvin #2501",
  1017999107: "Mental Marvin #2502",
  1017999151: "Mental Marvin #2503",
  1017999195: "Mental Marvin #2504",
  1017999232: "Mental Marvin #2505",
  1017999266: "Mental Marvin #2506",
  1017999374: "Mental Marvin #2507",
  1017999469: "Mental Marvin #2508",
  1017999502: "Mental Marvin #2509",
  1017999543: "Mental Marvin #2510",
  1017999580: "Mental Marvin #2511",
  1017999634: "Mental Marvin #2512",
  1017999695: "Mental Marvin #2513",
  1017999853: "Mental Marvin #2514",
  1017999921: "Mental Marvin #2515",
  1017999991: "Mental Marvin #2516",
  1018000027: "Mental Marvin #2517",
  1018000121: "Mental Marvin #2518",
  1018000174: "Mental Marvin #2519",
  1018000236: "Mental Marvin #2520",
  1018000268: "Mental Marvin #2521",
  1018000298: "Mental Marvin #2522",
  1018000345: "Mental Marvin #2523",
  1018000404: "Mental Marvin #2524",
  1018000436: "Mental Marvin #2525",
  1018000468: "Mental Marvin #2526",
  1018001570: "Mental Marvin #2527",
  1018001632: "Mental Marvin #2528",
  1018001694: "Mental Marvin #2529",
  1018001760: "Mental Marvin #2530",
  1018001887: "Mental Marvin #2531",
  1018002018: "Mental Marvin #2532",
  1018002052: "Mental Marvin #2533",
  1018002102: "Mental Marvin #2534",
  1018002168: "Mental Marvin #2535",
  1018002223: "Mental Marvin #2536",
  1018002264: "Mental Marvin #2537",
  1018002299: "Mental Marvin #2538",
  1018002338: "Mental Marvin #2539",
  1018002389: "Mental Marvin #2540",
  1018002460: "Mental Marvin #2541",
  1018002498: "Mental Marvin #2542",
  1018002535: "Mental Marvin #2543",
  1018002589: "Mental Marvin #2544",
  1018002624: "Mental Marvin #2545",
  1018002678: "Mental Marvin #2546",
  1018002729: "Mental Marvin #2547",
  1018002779: "Mental Marvin #2548",
  1018002838: "Mental Marvin #2549",
  1018002876: "Mental Marvin #2550",
  1018002914: "Mental Marvin #2551",
  1018002964: "Mental Marvin #2552",
  1018003013: "Mental Marvin #2553",
  1018003048: "Mental Marvin #2554",
  1018003111: "Mental Marvin #2555",
  1018003169: "Mental Marvin #2556",
  1018003205: "Mental Marvin #2557",
  1018003278: "Mental Marvin #2558",
  1018003326: "Mental Marvin #2559",
  1018003373: "Mental Marvin #2560",
  1018003414: "Mental Marvin #2561",
  1018003472: "Mental Marvin #2562",
  1018003522: "Mental Marvin #2563",
  1018003586: "Mental Marvin #2564",
  1018003634: "Mental Marvin #2565",
  1018003690: "Mental Marvin #2566",
  1018003733: "Mental Marvin #2567",
  1018003784: "Mental Marvin #2568",
  1018003859: "Mental Marvin #2569",
  1018003922: "Mental Marvin #2570",
  1018003984: "Mental Marvin #2571",
  1018004284: "Mental Marvin #2572",
  1018004392: "Mental Marvin #2573",
  1018004434: "Mental Marvin #2574",
  1018004484: "Mental Marvin #2575",
  1018004549: "Mental Marvin #2576",
  1018004598: "Mental Marvin #2577",
  1018004637: "Mental Marvin #2578",
  1018004692: "Mental Marvin #2579",
  1018004744: "Mental Marvin #2580",
  1018004818: "Mental Marvin #2581",
  1018004873: "Mental Marvin #2582",
  1018004907: "Mental Marvin #2583",
  1018004960: "Mental Marvin #2584",
  1018005010: "Mental Marvin #2585",
  1018005052: "Mental Marvin #2586",
  1018005131: "Mental Marvin #2587",
  1018005169: "Mental Marvin #2588",
  1018005226: "Mental Marvin #2589",
  1018005259: "Mental Marvin #2590",
  1018005302: "Mental Marvin #2591",
  1018005364: "Mental Marvin #2592",
  1018005400: "Mental Marvin #2593",
  1018005454: "Mental Marvin #2594",
  1018005497: "Mental Marvin #2595",
  1018005555: "Mental Marvin #2596",
  1018005604: "Mental Marvin #2597",
  1018005694: "Mental Marvin #2598",
  1018005736: "Mental Marvin #2599",
  1018005781: "Mental Marvin #2600",
  1018005834: "Mental Marvin #2601",
  1018005873: "Mental Marvin #2602",
  1018005909: "Mental Marvin #2603",
  1018005977: "Mental Marvin #2604",
  1018006013: "Mental Marvin #2605",
  1018006072: "Mental Marvin #2606",
  1018006143: "Mental Marvin #2607",
  1018006211: "Mental Marvin #2608",
  1018006353: "Mental Marvin #2609",
  1018006410: "Mental Marvin #2610",
  1018006448: "Mental Marvin #2611",
  1018006514: "Mental Marvin #2612",
  1018006546: "Mental Marvin #2613",
  1018006597: "Mental Marvin #2614",
  1018006665: "Mental Marvin #2615",
  1018006717: "Mental Marvin #2616",
  1018006861: "Mental Marvin #2617",
  1018006937: "Mental Marvin #2618",
  1018006997: "Mental Marvin #2619",
  1018007073: "Mental Marvin #2620",
  1018007126: "Mental Marvin #2621",
  1018007167: "Mental Marvin #2622",
  1018007207: "Mental Marvin #2623",
  1018007289: "Mental Marvin #2624",
  1018007324: "Mental Marvin #2625",
  1018007365: "Mental Marvin #2626",
  1018007451: "Mental Marvin #2627",
  1018007509: "Mental Marvin #2628",
  1018007541: "Mental Marvin #2629",
  1018007591: "Mental Marvin #2630",
  1018007629: "Mental Marvin #2631",
  1018007668: "Mental Marvin #2632",
  1018007705: "Mental Marvin #2633",
  1018007778: "Mental Marvin #2634",
  1018007807: "Mental Marvin #2635",
  1018007877: "Mental Marvin #2636",
  1018007990: "Mental Marvin #2637",
  1018008062: "Mental Marvin #2638",
  1018008177: "Mental Marvin #2639",
  1018008277: "Mental Marvin #2640",
  1018008337: "Mental Marvin #2641",
  1018008400: "Mental Marvin #2642",
  1018008447: "Mental Marvin #2643",
  1018008502: "Mental Marvin #2644",
  1018008533: "Mental Marvin #2645",
  1018008593: "Mental Marvin #2646",
  1018008654: "Mental Marvin #2647",
  1018008726: "Mental Marvin #2648",
  1018008816: "Mental Marvin #2649",
  1018008877: "Mental Marvin #2650",
  1018008905: "Mental Marvin #2651",
  1018008995: "Mental Marvin #2652",
  1018009053: "Mental Marvin #2653",
  1018009190: "Mental Marvin #2654",
  1018009226: "Mental Marvin #2655",
  1018009284: "Mental Marvin #2656",
  1018009363: "Mental Marvin #2657",
  1018009433: "Mental Marvin #2658",
  1018009467: "Mental Marvin #2659",
  1018009518: "Mental Marvin #2660",
  1018009571: "Mental Marvin #2661",
  1018009640: "Mental Marvin #2662",
  1018009824: "Mental Marvin #2663",
  1018009915: "Mental Marvin #2664",
  1018010073: "Mental Marvin #2665",
  1018010152: "Mental Marvin #2666",
  1018010181: "Mental Marvin #2667",
  1018010246: "Mental Marvin #2668",
  1018010289: "Mental Marvin #2669",
  1018010335: "Mental Marvin #2670",
  1018010381: "Mental Marvin #2671",
  1018010434: "Mental Marvin #2672",
  1018010477: "Mental Marvin #2673",
  1018010533: "Mental Marvin #2674",
  1018010608: "Mental Marvin #2675",
  1018010646: "Mental Marvin #2676",
  1018010736: "Mental Marvin #2677",
  1018010790: "Mental Marvin #2678",
  1018010876: "Mental Marvin #2679",
  1018010951: "Mental Marvin #2680",
  1018011049: "Mental Marvin #2681",
  1018011176: "Mental Marvin #2682",
  1018011398: "Mental Marvin #2683",
  1018011515: "Mental Marvin #2684",
  1018011593: "Mental Marvin #2685",
  1018011682: "Mental Marvin #2686",
  1018011903: "Mental Marvin #2687",
  1018012055: "Mental Marvin #2688",
  1018012125: "Mental Marvin #2689",
  1018012184: "Mental Marvin #2690",
  1018012271: "Mental Marvin #2691",
  1018012359: "Mental Marvin #2692",
  1018012397: "Mental Marvin #2693",
  1018012497: "Mental Marvin #2694",
  1018012556: "Mental Marvin #2695",
  1018012635: "Mental Marvin #2696",
  1018012699: "Mental Marvin #2697",
  1018012758: "Mental Marvin #2698",
  1018012837: "Mental Marvin #2699",
  1018012891: "Mental Marvin #2700",
  1018012910: "Mental Marvin #2701",
  1018012951: "Mental Marvin #2702",
  1018013039: "Mental Marvin #2703",
  1018013128: "Mental Marvin #2704",
  1018013173: "Mental Marvin #2705",
  1018013245: "Mental Marvin #2706",
  1018013282: "Mental Marvin #2707",
  1018013340: "Mental Marvin #2708",
  1018013402: "Mental Marvin #2709",
  1018013446: "Mental Marvin #2710",
  1018013475: "Mental Marvin #2711",
  1018013531: "Mental Marvin #2712",
  1018013580: "Mental Marvin #2713",
  1018013639: "Mental Marvin #2714",
  1018013680: "Mental Marvin #2715",
  1018013794: "Mental Marvin #2716",
  1018013849: "Mental Marvin #2717",
  1018013900: "Mental Marvin #2718",
  1018013931: "Mental Marvin #2719",
  1018013982: "Mental Marvin #2720",
  1018014039: "Mental Marvin #2721",
  1018014105: "Mental Marvin #2722",
  1018014157: "Mental Marvin #2723",
  1018014210: "Mental Marvin #2724",
  1018014253: "Mental Marvin #2725",
  1018014304: "Mental Marvin #2726",
  1018014366: "Mental Marvin #2727",
  1018014478: "Mental Marvin #2728",
  1018014528: "Mental Marvin #2729",
  1018014560: "Mental Marvin #2730",
  1018014619: "Mental Marvin #2731",
  1018014695: "Mental Marvin #2732",
  1018014743: "Mental Marvin #2733",
  1018014780: "Mental Marvin #2734",
  1018014889: "Mental Marvin #2735",
  1018014998: "Mental Marvin #2736",
  1018015612: "Mental Marvin #2737",
  1018016680: "Mental Marvin #2738",
  1018017239: "Mental Marvin #2739",
  1018017272: "Mental Marvin #2740",
  1018017327: "Mental Marvin #2741",
  1018017362: "Mental Marvin #2742",
  1018017425: "Mental Marvin #2743",
  1018017508: "Mental Marvin #2744",
  1018017561: "Mental Marvin #2745",
  1018017632: "Mental Marvin #2746",
  1018017657: "Mental Marvin #2747",
  1018017702: "Mental Marvin #2748",
  1018017739: "Mental Marvin #2749",
  1018017818: "Mental Marvin #2750",
  1018017924: "Mental Marvin #2751",
  1018017991: "Mental Marvin #2752",
  1018018031: "Mental Marvin #2753",
  1018018091: "Mental Marvin #2754",
  1018018151: "Mental Marvin #2755",
  1018018208: "Mental Marvin #2756",
  1018018250: "Mental Marvin #2757",
  1018018332: "Mental Marvin #2758",
  1018018430: "Mental Marvin #2759",
  1018018506: "Mental Marvin #2760",
  1018018592: "Mental Marvin #2761",
  1018018660: "Mental Marvin #2762",
  1018018743: "Mental Marvin #2763",
  1018018805: "Mental Marvin #2764",
  1018018869: "Mental Marvin #2765",
  1018018942: "Mental Marvin #2766",
  1018018998: "Mental Marvin #2767",
  1018019117: "Mental Marvin #2768",
  1018019180: "Mental Marvin #2769",
  1018019232: "Mental Marvin #2770",
  1018019279: "Mental Marvin #2771",
  1018019326: "Mental Marvin #2772",
  1018019429: "Mental Marvin #2773",
  1018019535: "Mental Marvin #2774",
  1018019602: "Mental Marvin #2775",
  1018019692: "Mental Marvin #2776",
  1018019755: "Mental Marvin #2777",
  1018019846: "Mental Marvin #2778",
  1018019971: "Mental Marvin #2779",
  1018020075: "Mental Marvin #2780",
  1018020134: "Mental Marvin #2781",
  1018020193: "Mental Marvin #2782",
  1018020274: "Mental Marvin #2783",
  1018020339: "Mental Marvin #2784",
  1018020372: "Mental Marvin #2785",
  1018020437: "Mental Marvin #2786",
  1018020488: "Mental Marvin #2787",
  1018020544: "Mental Marvin #2788",
  1018020601: "Mental Marvin #2789",
  1018020723: "Mental Marvin #2790",
  1018020821: "Mental Marvin #2791",
  1018020866: "Mental Marvin #2792",
  1018020962: "Mental Marvin #2793",
  1018021052: "Mental Marvin #2794",
  1018021144: "Mental Marvin #2795",
  1018021212: "Mental Marvin #2796",
  1018021262: "Mental Marvin #2797",
  1018021337: "Mental Marvin #2798",
  1018021422: "Mental Marvin #2799",
  1018021497: "Mental Marvin #2800",
  1018021560: "Mental Marvin #2801",
  1018021638: "Mental Marvin #2802",
  1018021704: "Mental Marvin #2803",
  1018021767: "Mental Marvin #2804",
  1018021815: "Mental Marvin #2805",
  1018021848: "Mental Marvin #2806",
  1018021888: "Mental Marvin #2807",
  1018021936: "Mental Marvin #2808",
  1018021970: "Mental Marvin #2809",
  1018022005: "Mental Marvin #2810",
  1018022135: "Mental Marvin #2811",
  1018022205: "Mental Marvin #2812",
  1018022292: "Mental Marvin #2813",
  1018022336: "Mental Marvin #2814",
  1018022380: "Mental Marvin #2815",
  1018022433: "Mental Marvin #2816",
  1018022475: "Mental Marvin #2817",
  1018022518: "Mental Marvin #2818",
  1018022557: "Mental Marvin #2819",
  1018022620: "Mental Marvin #2820",
  1018022676: "Mental Marvin #2821",
  1018022723: "Mental Marvin #2822",
  1018022769: "Mental Marvin #2823",
  1018022840: "Mental Marvin #2824",
  1018022900: "Mental Marvin #2825",
  1018022996: "Mental Marvin #2826",
  1018023049: "Mental Marvin #2827",
  1018023119: "Mental Marvin #2828",
  1018023147: "Mental Marvin #2829",
  1018023202: "Mental Marvin #2830",
  1018023287: "Mental Marvin #2831",
  1018023353: "Mental Marvin #2832",
  1018023430: "Mental Marvin #2833",
  1018023494: "Mental Marvin #2834",
  1018023605: "Mental Marvin #2835",
  1018023667: "Mental Marvin #2836",
  1018023713: "Mental Marvin #2837",
  1018023770: "Mental Marvin #2838",
  1018023795: "Mental Marvin #2839",
  1018023834: "Mental Marvin #2840",
  1018023884: "Mental Marvin #2841",
  1018023952: "Mental Marvin #2842",
  1018024065: "Mental Marvin #2843",
  1018024113: "Mental Marvin #2844",
  1018024154: "Mental Marvin #2845",
  1018024181: "Mental Marvin #2846",
  1018024234: "Mental Marvin #2847",
  1018024280: "Mental Marvin #2848",
  1018024337: "Mental Marvin #2849",
  1018024387: "Mental Marvin #2850",
  1018024442: "Mental Marvin #2851",
  1018024473: "Mental Marvin #2852",
  1018024499: "Mental Marvin #2853",
  1018024559: "Mental Marvin #2854",
  1018024632: "Mental Marvin #2855",
  1018024689: "Mental Marvin #2856",
  1018024720: "Mental Marvin #2857",
  1018024785: "Mental Marvin #2858",
  1018024832: "Mental Marvin #2859",
  1018024921: "Mental Marvin #2860",
  1018024970: "Mental Marvin #2861",
  1018025023: "Mental Marvin #2862",
  1018025091: "Mental Marvin #2863",
  1018025146: "Mental Marvin #2864",
  1018025187: "Mental Marvin #2865",
  1018025230: "Mental Marvin #2866",
  1018025275: "Mental Marvin #2867",
  1018025318: "Mental Marvin #2868",
  1018025354: "Mental Marvin #2869",
  1018025392: "Mental Marvin #2870",
  1018025464: "Mental Marvin #2871",
  1018025497: "Mental Marvin #2872",
  1018025553: "Mental Marvin #2873",
  1018025594: "Mental Marvin #2874",
  1018025646: "Mental Marvin #2875",
  1018025693: "Mental Marvin #2876",
  1018025815: "Mental Marvin #2877",
  1018025848: "Mental Marvin #2878",
  1018025916: "Mental Marvin #2879",
  1018025955: "Mental Marvin #2880",
  1018026022: "Mental Marvin #2881",
  1018026093: "Mental Marvin #2882",
  1018026135: "Mental Marvin #2883",
  1018026210: "Mental Marvin #2884",
  1018026315: "Mental Marvin #2885",
  1018026412: "Mental Marvin #2886",
  1018026516: "Mental Marvin #2887",
  1018026630: "Mental Marvin #2888",
  1018026682: "Mental Marvin #2889",
  1018026705: "Mental Marvin #2890",
  1018026728: "Mental Marvin #2891",
  1018026817: "Mental Marvin #2892",
  1018026921: "Mental Marvin #2893",
  1018026997: "Mental Marvin #2894",
  1018027043: "Mental Marvin #2895",
  1018027219: "Mental Marvin #2896",
  1018027292: "Mental Marvin #2897",
  1018027362: "Mental Marvin #2898",
  1018027438: "Mental Marvin #2899",
  1018027590: "Mental Marvin #2900",
  1018027632: "Mental Marvin #2901",
  1018027686: "Mental Marvin #2902",
  1018027736: "Mental Marvin #2903",
  1018027819: "Mental Marvin #2904",
  1018027891: "Mental Marvin #2905",
  1018027945: "Mental Marvin #2906",
  1018027985: "Mental Marvin #2907",
  1018028031: "Mental Marvin #2908",
  1018028078: "Mental Marvin #2909",
  1018028162: "Mental Marvin #2910",
  1018028240: "Mental Marvin #2911",
  1018028293: "Mental Marvin #2912",
  1018028336: "Mental Marvin #2913",
  1018028363: "Mental Marvin #2914",
  1018028418: "Mental Marvin #2915",
  1018028452: "Mental Marvin #2916",
  1018028501: "Mental Marvin #2917",
  1018028583: "Mental Marvin #2918",
  1018028647: "Mental Marvin #2919",
  1018028790: "Mental Marvin #2920",
  1018028849: "Mental Marvin #2921",
  1018028913: "Mental Marvin #2922",
  1018028959: "Mental Marvin #2923",
  1018028993: "Mental Marvin #2924",
  1018029020: "Mental Marvin #2925",
  1018029077: "Mental Marvin #2926",
  1018029124: "Mental Marvin #2927",
  1018029208: "Mental Marvin #2928",
  1018029284: "Mental Marvin #2929",
  1018029342: "Mental Marvin #2930",
  1018029450: "Mental Marvin #2931",
  1018029533: "Mental Marvin #2932",
  1018029577: "Mental Marvin #2933",
  1018029623: "Mental Marvin #2934",
  1018029666: "Mental Marvin #2935",
  1018029726: "Mental Marvin #2936",
  1018029750: "Mental Marvin #2937",
  1018029815: "Mental Marvin #2938",
  1018029847: "Mental Marvin #2939",
  1018029902: "Mental Marvin #2940",
  1018029947: "Mental Marvin #2941",
  1018030016: "Mental Marvin #2942",
  1018030094: "Mental Marvin #2943",
  1018030190: "Mental Marvin #2944",
  1018030258: "Mental Marvin #2945",
  1018030323: "Mental Marvin #2946",
  1018030419: "Mental Marvin #2947",
  1018030484: "Mental Marvin #2948",
  1018030528: "Mental Marvin #2949",
  1018030719: "Mental Marvin #2950",
  1018030778: "Mental Marvin #2951",
  1018030826: "Mental Marvin #2952",
  1018030874: "Mental Marvin #2953",
  1018030912: "Mental Marvin #2954",
  1018030973: "Mental Marvin #2955",
  1018031068: "Mental Marvin #2956",
  1018031119: "Mental Marvin #2957",
  1018031198: "Mental Marvin #2958",
  1018031246: "Mental Marvin #2959",
  1018031325: "Mental Marvin #2960",
  1018032008: "Mental Marvin #2961",
  1018032078: "Mental Marvin #2962",
  1018032117: "Mental Marvin #2963",
  1018032174: "Mental Marvin #2964",
  1018032219: "Mental Marvin #2965",
  1018032268: "Mental Marvin #2966",
  1018032333: "Mental Marvin #2967",
  1018032372: "Mental Marvin #2968",
  1018032406: "Mental Marvin #2969",
  1018032462: "Mental Marvin #2970",
  1018032517: "Mental Marvin #2971",
  1018033011: "Mental Marvin #2972",
  1018033060: "Mental Marvin #2973",
  1018033096: "Mental Marvin #2974",
  1018033151: "Mental Marvin #2975",
  1018033189: "Mental Marvin #2976",
  1018033234: "Mental Marvin #2977",
  1018033306: "Mental Marvin #2978",
  1018033396: "Mental Marvin #2979",
  1018033506: "Mental Marvin #2980",
  1018033558: "Mental Marvin #2981",
  1018033611: "Mental Marvin #2982",
  1018033669: "Mental Marvin #2983",
  1018033709: "Mental Marvin #2984",
  1018033753: "Mental Marvin #2985",
  1018033801: "Mental Marvin #2986",
  1018033885: "Mental Marvin #2987",
  1018033923: "Mental Marvin #2988",
  1018033971: "Mental Marvin #2989",
  1018034013: "Mental Marvin #2990",
  1018034106: "Mental Marvin #2991",
  1018034140: "Mental Marvin #2992",
  1018034206: "Mental Marvin #2993",
  1018034253: "Mental Marvin #2994",
  1018034329: "Mental Marvin #2995",
  1018034378: "Mental Marvin #2996",
  1018034419: "Mental Marvin #2997",
  1018034458: "Mental Marvin #2998",
  1018034510: "Mental Marvin #2999",
  1018034637: "Mental Marvin #3000",
  1018034686: "Mental Marvin #3001",
  1018034727: "Mental Marvin #3002",
  1018034806: "Mental Marvin #3003",
  1018034903: "Mental Marvin #3004",
  1018034962: "Mental Marvin #3005",
  1018035057: "Mental Marvin #3006",
  1018035631: "Mental Marvin #3007",
  1018036498: "Mental Marvin #3008",
  1018036824: "Mental Marvin #3009",
  1018037019: "Mental Marvin #3010",
  1018037222: "Mental Marvin #3011",
  1018037338: "Mental Marvin #3012",
  1018037432: "Mental Marvin #3013",
  1018037546: "Mental Marvin #3014",
  1018037624: "Mental Marvin #3015",
  1018037696: "Mental Marvin #3016",
  1018037771: "Mental Marvin #3017",
  1018037849: "Mental Marvin #3018",
  1018037929: "Mental Marvin #3019",
  1018038000: "Mental Marvin #3020",
  1018038077: "Mental Marvin #3021",
  1018038143: "Mental Marvin #3022",
  1018038277: "Mental Marvin #3023",
  1018038373: "Mental Marvin #3024",
  1018038439: "Mental Marvin #3025",
  1018038464: "Mental Marvin #3026",
  1018038530: "Mental Marvin #3027",
  1018038609: "Mental Marvin #3028",
  1018038674: "Mental Marvin #3029",
  1018038705: "Mental Marvin #3030",
  1018038749: "Mental Marvin #3031",
  1018038811: "Mental Marvin #3032",
  1018038864: "Mental Marvin #3033",
  1018039106: "Mental Marvin #3034",
  1018039286: "Mental Marvin #3035",
  1018039534: "Mental Marvin #3036",
  1018039665: "Mental Marvin #3037",
  1018039830: "Mental Marvin #3038",
  1018040160: "Mental Marvin #3039",
  1018040313: "Mental Marvin #3040",
  1018040580: "Mental Marvin #3041",
  1018040742: "Mental Marvin #3042",
  1018040920: "Mental Marvin #3043",
  1018041158: "Mental Marvin #3044",
  1018041298: "Mental Marvin #3045",
  1018041555: "Mental Marvin #3046",
  1018041726: "Mental Marvin #3047",
  1018041989: "Mental Marvin #3048",
  1018042035: "Mental Marvin #3049",
  1018042248: "Mental Marvin #3050",
  1018042496: "Mental Marvin #3051",
  1018042563: "Mental Marvin #3052",
  1018042822: "Mental Marvin #3053",
  1018042877: "Mental Marvin #3054",
  1018043092: "Mental Marvin #3055",
  1018043281: "Mental Marvin #3056",
  1018043545: "Mental Marvin #3057",
  1018043794: "Mental Marvin #3058",
  1018044052: "Mental Marvin #3059",
  1018044168: "Mental Marvin #3060",
  1018044315: "Mental Marvin #3061",
  1018044370: "Mental Marvin #3062",
  1018044422: "Mental Marvin #3063",
  1018044458: "Mental Marvin #3064",
  1018044751: "Mental Marvin #3065",
  1018046301: "Mental Marvin #3066",
  1018047086: "Mental Marvin #3067",
  1018047609: "Mental Marvin #3068",
  1018047815: "Mental Marvin #3069",
  1018047981: "Mental Marvin #3070",
  1018048091: "Mental Marvin #3071",
  1018048162: "Mental Marvin #3072",
  1018048282: "Mental Marvin #3073",
  1018048508: "Mental Marvin #3074",
  1018048708: "Mental Marvin #3075",
  1018048828: "Mental Marvin #3076",
  1018048962: "Mental Marvin #3077",
  1018049052: "Mental Marvin #3078",
  1018049167: "Mental Marvin #3079",
  1018049304: "Mental Marvin #3080",
  1018049657: "Mental Marvin #3081",
  1018049747: "Mental Marvin #3082",
  1018049803: "Mental Marvin #3083",
  1018049888: "Mental Marvin #3084",
  1018049944: "Mental Marvin #3085",
  1018050054: "Mental Marvin #3086",
  1018050102: "Mental Marvin #3087",
  1018050141: "Mental Marvin #3088",
  1018050183: "Mental Marvin #3089",
  1018050248: "Mental Marvin #3090",
  1018050287: "Mental Marvin #3091",
  1018050339: "Mental Marvin #3092",
  1018050373: "Mental Marvin #3093",
  1018050410: "Mental Marvin #3094",
  1018050451: "Mental Marvin #3095",
  1018050495: "Mental Marvin #3096",
  1018050549: "Mental Marvin #3097",
  1018050597: "Mental Marvin #3098",
  1018050631: "Mental Marvin #3099",
  1018050656: "Mental Marvin #3100",
  1018050726: "Mental Marvin #3101",
  1018050925: "Mental Marvin #3102",
  1018051023: "Mental Marvin #3103",
  1018051221: "Mental Marvin #3104",
  1018051320: "Mental Marvin #3105",
  1018051392: "Mental Marvin #3106",
  1018051449: "Mental Marvin #3107",
  1018051516: "Mental Marvin #3108",
  1018051677: "Mental Marvin #3109",
  1018051749: "Mental Marvin #3110",
  1018052000: "Mental Marvin #3111",
  1018052045: "Mental Marvin #3112",
  1018052076: "Mental Marvin #3113",
  1018052132: "Mental Marvin #3114",
  1018052181: "Mental Marvin #3115",
  1018052274: "Mental Marvin #3116",
  1018052319: "Mental Marvin #3117",
  1018052360: "Mental Marvin #3118",
  1018052420: "Mental Marvin #3119",
  1018052460: "Mental Marvin #3120",
  1018052532: "Mental Marvin #3121",
  1018052582: "Mental Marvin #3122",
  1018052679: "Mental Marvin #3123",
  1018053010: "Mental Marvin #3124",
  1018053183: "Mental Marvin #3125",
  1018053430: "Mental Marvin #3126",
  1018053648: "Mental Marvin #3127",
  1018053956: "Mental Marvin #3128",
  1018054019: "Mental Marvin #3129",
  1018054186: "Mental Marvin #3130",
  1018054720: "Mental Marvin #3131",
  1018054946: "Mental Marvin #3132",
  1018055085: "Mental Marvin #3133",
  1018055147: "Mental Marvin #3134",
  1018055203: "Mental Marvin #3135",
  1018055243: "Mental Marvin #3136",
  1018055321: "Mental Marvin #3137",
  1018055386: "Mental Marvin #3138",
  1018055678: "Mental Marvin #3139",
  1018055739: "Mental Marvin #3140",
  1018055787: "Mental Marvin #3141",
  1018055878: "Mental Marvin #3142",
  1018055924: "Mental Marvin #3143",
  1018055952: "Mental Marvin #3144",
  1018055993: "Mental Marvin #3145",
  1018056057: "Mental Marvin #3146",
  1018056205: "Mental Marvin #3147",
  1018056256: "Mental Marvin #3148",
  1018056293: "Mental Marvin #3149",
  1018056717: "Mental Marvin #3150",
  1018056921: "Mental Marvin #3151",
  1018057213: "Mental Marvin #3152",
  1018057337: "Mental Marvin #3153",
  1018057399: "Mental Marvin #3154",
  1018057466: "Mental Marvin #3155",
  1018057526: "Mental Marvin #3156",
  1018057635: "Mental Marvin #3157",
  1018057728: "Mental Marvin #3158",
  1018057939: "Mental Marvin #3159",
  1018058012: "Mental Marvin #3160",
  1018058057: "Mental Marvin #3161",
  1018058203: "Mental Marvin #3162",
  1018058258: "Mental Marvin #3163",
  1018058332: "Mental Marvin #3164",
  1018058377: "Mental Marvin #3165",
  1018058672: "Mental Marvin #3166",
  1018058872: "Mental Marvin #3167",
  1018058937: "Mental Marvin #3168",
  1018058965: "Mental Marvin #3169",
  1018059088: "Mental Marvin #3170",
  1018059253: "Mental Marvin #3171",
  1018059446: "Mental Marvin #3172",
  1018059640: "Mental Marvin #3173",
  1018059753: "Mental Marvin #3174",
  1018059830: "Mental Marvin #3175",
  1018059876: "Mental Marvin #3176",
  1018059899: "Mental Marvin #3177",
  1018059948: "Mental Marvin #3178",
  1018060010: "Mental Marvin #3179",
  1018060075: "Mental Marvin #3180",
  1018060132: "Mental Marvin #3181",
  1018060179: "Mental Marvin #3182",
  1018060222: "Mental Marvin #3183",
  1018060316: "Mental Marvin #3184",
  1018060387: "Mental Marvin #3185",
  1018060424: "Mental Marvin #3186",
  1018060470: "Mental Marvin #3187",
  1018060508: "Mental Marvin #3188",
  1018060659: "Mental Marvin #3189",
  1018060718: "Mental Marvin #3190",
  1018060840: "Mental Marvin #3191",
  1018060874: "Mental Marvin #3192",
  1018060908: "Mental Marvin #3193",
  1018060995: "Mental Marvin #3194",
  1018061030: "Mental Marvin #3195",
  1018061068: "Mental Marvin #3196",
  1018061116: "Mental Marvin #3197",
  1018061178: "Mental Marvin #3198",
  1018061223: "Mental Marvin #3199",
  1018061288: "Mental Marvin #3200",
  1018061310: "Mental Marvin #3201",
  1018061361: "Mental Marvin #3202",
  1018061399: "Mental Marvin #3203",
  1018061444: "Mental Marvin #3204",
  1018061479: "Mental Marvin #3205",
  1018062192: "Mental Marvin #3206",
  1018062228: "Mental Marvin #3207",
  1018062452: "Mental Marvin #3208",
  1018062845: "Mental Marvin #3209",
  1018063138: "Mental Marvin #3210",
  1018063351: "Mental Marvin #3211",
  1018063474: "Mental Marvin #3212",
  1018063625: "Mental Marvin #3213",
  1018063764: "Mental Marvin #3214",
  1018064074: "Mental Marvin #3215",
  1018064355: "Mental Marvin #3216",
  1018064384: "Mental Marvin #3217",
  1018064426: "Mental Marvin #3218",
  1018064482: "Mental Marvin #3219",
  1018064547: "Mental Marvin #3220",
  1018064585: "Mental Marvin #3221",
  1018064633: "Mental Marvin #3222",
  1018064685: "Mental Marvin #3223",
  1018064717: "Mental Marvin #3224",
  1018064759: "Mental Marvin #3225",
  1018064814: "Mental Marvin #3226",
  1018064868: "Mental Marvin #3227",
  1018064900: "Mental Marvin #3228",
  1018064951: "Mental Marvin #3229",
  1018065069: "Mental Marvin #3230",
  1018065154: "Mental Marvin #3231",
  1018065204: "Mental Marvin #3232",
  1018065234: "Mental Marvin #3233",
  1018065280: "Mental Marvin #3234",
  1018065309: "Mental Marvin #3235",
  1018065384: "Mental Marvin #3236",
  1018065428: "Mental Marvin #3237",
  1018065509: "Mental Marvin #3238",
  1018065615: "Mental Marvin #3239",
  1018065640: "Mental Marvin #3240",
  1018065684: "Mental Marvin #3241",
  1018065732: "Mental Marvin #3242",
  1018065773: "Mental Marvin #3243",
  1018065825: "Mental Marvin #3244",
  1018065874: "Mental Marvin #3245",
  1018065943: "Mental Marvin #3246",
  1018066042: "Mental Marvin #3247",
  1018066222: "Mental Marvin #3248",
  1018066304: "Mental Marvin #3249",
  1018066384: "Mental Marvin #3250",
  1018066454: "Mental Marvin #3251",
  1018066510: "Mental Marvin #3252",
  1018066557: "Mental Marvin #3253",
  1018066641: "Mental Marvin #3254",
  1018066721: "Mental Marvin #3255",
  1018066782: "Mental Marvin #3256",
  1018066834: "Mental Marvin #3257",
  1018066894: "Mental Marvin #3258",
  1018066941: "Mental Marvin #3259",
  1018066984: "Mental Marvin #3260",
  1018067056: "Mental Marvin #3261",
  1018067095: "Mental Marvin #3262",
  1018067129: "Mental Marvin #3263",
  1018067173: "Mental Marvin #3264",
  1018067206: "Mental Marvin #3265",
  1018067258: "Mental Marvin #3266",
  1018067304: "Mental Marvin #3267",
  1018067355: "Mental Marvin #3268",
  1018067417: "Mental Marvin #3269",
  1018067524: "Mental Marvin #3270",
  1018067574: "Mental Marvin #3271",
  1018067612: "Mental Marvin #3272",
  1018067643: "Mental Marvin #3273",
  1018067713: "Mental Marvin #3274",
  1018067754: "Mental Marvin #3275",
  1018067809: "Mental Marvin #3276",
  1018067858: "Mental Marvin #3277",
  1018067968: "Mental Marvin #3278",
  1018068032: "Mental Marvin #3279",
  1018068075: "Mental Marvin #3280",
  1018068111: "Mental Marvin #3281",
  1018068140: "Mental Marvin #3282",
  1018068171: "Mental Marvin #3283",
  1018068204: "Mental Marvin #3284",
  1018068250: "Mental Marvin #3285",
  1018068359: "Mental Marvin #3286",
  1018068434: "Mental Marvin #3287",
  1018068460: "Mental Marvin #3288",
  1018068498: "Mental Marvin #3289",
  1018068566: "Mental Marvin #3290",
  1018068640: "Mental Marvin #3291",
  1018068684: "Mental Marvin #3292",
  1018068733: "Mental Marvin #3293",
  1018068785: "Mental Marvin #3294",
  1018068816: "Mental Marvin #3295",
  1018068870: "Mental Marvin #3296",
  1018068915: "Mental Marvin #3297",
  1018068952: "Mental Marvin #3298",
  1018069020: "Mental Marvin #3299",
  1018069075: "Mental Marvin #3300",
  1018069099: "Mental Marvin #3301",
  1018069150: "Mental Marvin #3302",
  1018069225: "Mental Marvin #3303",
  1018069253: "Mental Marvin #3304",
  1018069304: "Mental Marvin #3305",
  1018069443: "Mental Marvin #3306",
  1018069562: "Mental Marvin #3307",
  1018069709: "Mental Marvin #3308",
  1018069836: "Mental Marvin #3309",
  1018069945: "Mental Marvin #3310",
  1018070040: "Mental Marvin #3311",
  1018070136: "Mental Marvin #3312",
  1018070243: "Mental Marvin #3313",
  1018070276: "Mental Marvin #3314",
  1018070376: "Mental Marvin #3315",
  1018070433: "Mental Marvin #3316",
  1018070536: "Mental Marvin #3317",
  1018070586: "Mental Marvin #3318",
  1018070707: "Mental Marvin #3319",
  1018070818: "Mental Marvin #3320",
  1018070977: "Mental Marvin #3321",
  1018071120: "Mental Marvin #3322",
  1018071230: "Mental Marvin #3323",
  1018071532: "Mental Marvin #3324",
  1018071780: "Mental Marvin #3325",
  1018072129: "Mental Marvin #3326",
  1018072295: "Mental Marvin #3327",
  1018072521: "Mental Marvin #3328",
  1018072584: "Mental Marvin #3329",
  1018072763: "Mental Marvin #3330",
  1018073043: "Mental Marvin #3331",
  1018073166: "Mental Marvin #3332",
  1018073313: "Mental Marvin #3333",
  1018073429: "Mental Marvin #3334",
  1018073525: "Mental Marvin #3335",
  1018073615: "Mental Marvin #3336",
  1018073716: "Mental Marvin #3337",
  1018073814: "Mental Marvin #3338",
  1018073941: "Mental Marvin #3339",
  1018073995: "Mental Marvin #3340",
  1018074033: "Mental Marvin #3341",
  1018074081: "Mental Marvin #3342",
  1018074216: "Mental Marvin #3343",
  1018074261: "Mental Marvin #3344",
  1018074327: "Mental Marvin #3345",
  1018074433: "Mental Marvin #3346",
  1018074542: "Mental Marvin #3347",
  1018074751: "Mental Marvin #3348",
  1018074855: "Mental Marvin #3349",
  1018074971: "Mental Marvin #3350",
  1018075106: "Mental Marvin #3351",
  1018075229: "Mental Marvin #3352",
  1018075278: "Mental Marvin #3353",
  1018075317: "Mental Marvin #3354",
  1018075429: "Mental Marvin #3355",
  1018075517: "Mental Marvin #3356",
  1018075576: "Mental Marvin #3357",
  1018075652: "Mental Marvin #3358",
  1018075739: "Mental Marvin #3359",
  1018075767: "Mental Marvin #3360",
  1018076006: "Mental Marvin #3361",
  1018076102: "Mental Marvin #3362",
  1018076157: "Mental Marvin #3363",
  1018076279: "Mental Marvin #3364",
  1018076393: "Mental Marvin #3365",
  1018076547: "Mental Marvin #3366",
  1018076658: "Mental Marvin #3367",
  1018076780: "Mental Marvin #3368",
  1018076876: "Mental Marvin #3369",
  1018077059: "Mental Marvin #3370",
  1018077270: "Mental Marvin #3371",
  1018077420: "Mental Marvin #3372",
  1018077587: "Mental Marvin #3373",
  1018077637: "Mental Marvin #3374",
  1018077747: "Mental Marvin #3375",
  1018077807: "Mental Marvin #3376",
  1018077842: "Mental Marvin #3377",
  1018077954: "Mental Marvin #3378",
  1018078074: "Mental Marvin #3379",
  1018078194: "Mental Marvin #3380",
  1018078298: "Mental Marvin #3381",
  1018078419: "Mental Marvin #3382",
  1018078578: "Mental Marvin #3383",
  1018078675: "Mental Marvin #3384",
  1018078780: "Mental Marvin #3385",
  1018078836: "Mental Marvin #3386",
  1018078885: "Mental Marvin #3387",
  1018078933: "Mental Marvin #3388",
  1018079015: "Mental Marvin #3389",
  1018079165: "Mental Marvin #3390",
  1018079361: "Mental Marvin #3391",
  1018079561: "Mental Marvin #3392",
  1018079669: "Mental Marvin #3393",
  1018079837: "Mental Marvin #3394",
  1018079972: "Mental Marvin #3395",
  1018080125: "Mental Marvin #3396",
  1018080306: "Mental Marvin #3397",
  1018080462: "Mental Marvin #3398",
  1018080533: "Mental Marvin #3399",
  1018080618: "Mental Marvin #3400",
  1018080684: "Mental Marvin #3401",
  1018080830: "Mental Marvin #3402",
  1018080886: "Mental Marvin #3403",
  1018080959: "Mental Marvin #3404",
  1018081055: "Mental Marvin #3405",
  1018081160: "Mental Marvin #3406",
  1018081301: "Mental Marvin #3407",
  1018081400: "Mental Marvin #3408",
  1018081472: "Mental Marvin #3409",
  1018081549: "Mental Marvin #3410",
  1018081683: "Mental Marvin #3411",
  1018081726: "Mental Marvin #3412",
  1018081774: "Mental Marvin #3413",
  1018081871: "Mental Marvin #3414",
  1018081909: "Mental Marvin #3415",
  1018081971: "Mental Marvin #3416",
  1018082004: "Mental Marvin #3417",
  1018082061: "Mental Marvin #3418",
  1018082100: "Mental Marvin #3419",
  1018082156: "Mental Marvin #3420",
  1018082202: "Mental Marvin #3421",
  1018082256: "Mental Marvin #3422",
  1018082345: "Mental Marvin #3423",
  1018082426: "Mental Marvin #3424",
  1018082504: "Mental Marvin #3425",
  1018082583: "Mental Marvin #3426",
  1018082641: "Mental Marvin #3427",
  1018082732: "Mental Marvin #3428",
  1018082794: "Mental Marvin #3429",
  1018082873: "Mental Marvin #3430",
  1018082952: "Mental Marvin #3431",
  1018083041: "Mental Marvin #3432",
  1018083089: "Mental Marvin #3433",
  1018083200: "Mental Marvin #3434",
  1018083290: "Mental Marvin #3435",
  1018083389: "Mental Marvin #3436",
  1018083459: "Mental Marvin #3437",
  1018083500: "Mental Marvin #3438",
  1018083549: "Mental Marvin #3439",
  1018083644: "Mental Marvin #3440",
  1018083703: "Mental Marvin #3441",
  1018083768: "Mental Marvin #3442",
  1018083896: "Mental Marvin #3443",
  1018083972: "Mental Marvin #3444",
  1018084023: "Mental Marvin #3445",
  1018084218: "Mental Marvin #3446",
  1018084295: "Mental Marvin #3447",
  1018084345: "Mental Marvin #3448",
  1018084419: "Mental Marvin #3449",
  1018084486: "Mental Marvin #3450",
  1018084555: "Mental Marvin #3451",
  1018084619: "Mental Marvin #3452",
  1018084783: "Mental Marvin #3453",
  1018084826: "Mental Marvin #3454",
  1018084862: "Mental Marvin #3455",
  1018084986: "Mental Marvin #3456",
  1018085046: "Mental Marvin #3457",
  1018085106: "Mental Marvin #3458",
  1018085167: "Mental Marvin #3459",
  1018085207: "Mental Marvin #3460",
  1018085388: "Mental Marvin #3461",
  1018085448: "Mental Marvin #3462",
  1018085484: "Mental Marvin #3463",
  1018085564: "Mental Marvin #3464",
  1018085655: "Mental Marvin #3465",
  1018085726: "Mental Marvin #3466",
  1018085888: "Mental Marvin #3467",
  1018085940: "Mental Marvin #3468",
  1018085999: "Mental Marvin #3469",
  1018086038: "Mental Marvin #3470",
  1018086094: "Mental Marvin #3471",
  1018086145: "Mental Marvin #3472",
  1018086211: "Mental Marvin #3473",
  1018086266: "Mental Marvin #3474",
  1018086315: "Mental Marvin #3475",
  1018086348: "Mental Marvin #3476",
  1018086424: "Mental Marvin #3477",
  1018086550: "Mental Marvin #3478",
  1018086585: "Mental Marvin #3479",
  1018086654: "Mental Marvin #3480",
  1018086681: "Mental Marvin #3481",
  1018086890: "Mental Marvin #3482",
  1018087048: "Mental Marvin #3483",
  1018087259: "Mental Marvin #3484",
  1018087434: "Mental Marvin #3485",
  1018087604: "Mental Marvin #3486",
  1018087819: "Mental Marvin #3487",
  1018087971: "Mental Marvin #3488",
  1018088138: "Mental Marvin #3489",
  1018088431: "Mental Marvin #3490",
  1018088664: "Mental Marvin #3491",
  1018088813: "Mental Marvin #3492",
  1018089014: "Mental Marvin #3493",
  1018089202: "Mental Marvin #3494",
  1018089309: "Mental Marvin #3495",
  1018089476: "Mental Marvin #3496",
  1018089706: "Mental Marvin #3497",
  1018090007: "Mental Marvin #3498",
  1018090189: "Mental Marvin #3499",
  1018090385: "Mental Marvin #3500",
  1018090543: "Mental Marvin #3501",
  1018090756: "Mental Marvin #3502",
  1018090859: "Mental Marvin #3503",
  1018091029: "Mental Marvin #3504",
  1018091240: "Mental Marvin #3505",
  1018091371: "Mental Marvin #3506",
  1018091503: "Mental Marvin #3507",
  1018091538: "Mental Marvin #3508",
  1018091683: "Mental Marvin #3509",
  1018091749: "Mental Marvin #3510",
  1018091800: "Mental Marvin #3511",
  1018091855: "Mental Marvin #3512",
  1018091894: "Mental Marvin #3513",
  1018091920: "Mental Marvin #3514",
  1018091978: "Mental Marvin #3515",
  1018092018: "Mental Marvin #3516",
  1018092081: "Mental Marvin #3517",
  1018092130: "Mental Marvin #3518",
  1018092163: "Mental Marvin #3519",
  1018092215: "Mental Marvin #3520",
  1018092394: "Mental Marvin #3521",
  1018092531: "Mental Marvin #3522",
  1018092571: "Mental Marvin #3523",
  1018092599: "Mental Marvin #3524",
  1018092664: "Mental Marvin #3525",
  1018092729: "Mental Marvin #3526",
  1018092788: "Mental Marvin #3527",
  1018092847: "Mental Marvin #3528",
  1018092897: "Mental Marvin #3529",
  1018092955: "Mental Marvin #3530",
  1018092995: "Mental Marvin #3531",
  1018093084: "Mental Marvin #3532",
  1018093140: "Mental Marvin #3533",
  1018093167: "Mental Marvin #3534",
  1018093203: "Mental Marvin #3535",
  1018093245: "Mental Marvin #3536",
  1018093297: "Mental Marvin #3537",
  1018093408: "Mental Marvin #3538",
  1018093466: "Mental Marvin #3539",
  1018093513: "Mental Marvin #3540",
  1018093554: "Mental Marvin #3541",
  1018093607: "Mental Marvin #3542",
  1018093666: "Mental Marvin #3543",
  1018093715: "Mental Marvin #3544",
  1018093793: "Mental Marvin #3545",
  1018093877: "Mental Marvin #3546",
  1018093932: "Mental Marvin #3547",
  1018094001: "Mental Marvin #3548",
  1018094062: "Mental Marvin #3549",
  1018094109: "Mental Marvin #3550",
  1018094189: "Mental Marvin #3551",
  1018094307: "Mental Marvin #3552",
  1018094359: "Mental Marvin #3553",
  1018094414: "Mental Marvin #3554",
  1018094443: "Mental Marvin #3555",
  1018094490: "Mental Marvin #3556",
  1018094544: "Mental Marvin #3557",
  1018094583: "Mental Marvin #3558",
  1018094631: "Mental Marvin #3559",
  1018094703: "Mental Marvin #3560",
  1018094750: "Mental Marvin #3561",
  1018094784: "Mental Marvin #3562",
  1018094824: "Mental Marvin #3563",
  1018094895: "Mental Marvin #3564",
  1018094924: "Mental Marvin #3565",
  1018094971: "Mental Marvin #3566",
  1018095017: "Mental Marvin #3567",
  1018095084: "Mental Marvin #3568",
  1018095122: "Mental Marvin #3569",
  1018095164: "Mental Marvin #3570",
  1018095218: "Mental Marvin #3571",
  1018095253: "Mental Marvin #3572",
  1018095300: "Mental Marvin #3573",
  1018095350: "Mental Marvin #3574",
  1018095393: "Mental Marvin #3575",
  1018095447: "Mental Marvin #3576",
  1018095489: "Mental Marvin #3577",
  1018095537: "Mental Marvin #3578",
  1018095567: "Mental Marvin #3579",
  1018095617: "Mental Marvin #3580",
  1018095690: "Mental Marvin #3581",
  1018095736: "Mental Marvin #3582",
  1018095796: "Mental Marvin #3583",
  1018095831: "Mental Marvin #3584",
  1018095893: "Mental Marvin #3585",
  1018095938: "Mental Marvin #3586",
  1018096008: "Mental Marvin #3587",
  1018096044: "Mental Marvin #3588",
  1018096098: "Mental Marvin #3589",
  1018096152: "Mental Marvin #3590",
  1018096203: "Mental Marvin #3591",
  1018096251: "Mental Marvin #3592",
  1018096303: "Mental Marvin #3593",
  1018096338: "Mental Marvin #3594",
  1018096392: "Mental Marvin #3595",
  1018096450: "Mental Marvin #3596",
  1018096502: "Mental Marvin #3597",
  1018096571: "Mental Marvin #3598",
  1018096625: "Mental Marvin #3599",
  1018096668: "Mental Marvin #3600",
} as { [key: string]: string };

export const ASSET_IDS = Object.keys(ASSET_NAMES).map((key) => parseInt(key));

export const ALGO_FORCE_IDS = [
  646451253, 646454068, 646455670, 646456801, 646460034, 646461767, 646462592,
  646465451, 646468718, 646469604, 646471833, 647475981, 661775295, 661777187,
  661788987, 661790759, 661795456, 661798142, 665457524, 667320043, 672258584,
  674025025, 676401364, 678043806, 681531338, 683278689, 687693061, 695740199,
  695742539, 696205135, 698091208, 702373772, 702380294, 705175191, 705176216,
  705613218, 710978223, 712147140, 713889806, 718708139, 722053045, 724487744,
  736972227, 743785031, 743786243, 743787107, 743789217, 748417443,
  758200778, 759560596, 761729779, 765182143, 768653904, 775026685, 781040582,
  784124805, 784125726, 784126657, 784129422, 784130893, 784131332, 787341510,
  797312603, 803477095, 803826232, 807244107, 811854702, 812789389,
  812790001, 819246129, 832413511, 840302315, 840303578, 840304572, 840306112,
  840306664, 840310984, 840311521, 843336735, 843393228, 846633933, 849838009,
  849838737, 849839609, 852107235, 853101115, 854196586, 856194265, 856195685,
  857108511, 859256059, 887245585, 887366357, 891523474, 892497308, 899722967,
  899723799, 905369354
];

export const SWEET_RIPLEY_IDS = [
  1134727305, 1134727309, 1134727313, 1134727318, 1134727320, 1134727324,
  1134727326, 1134727328, 1134727334, 1134727340, 1134727342, 1134727346,
  1134727353, 1134727359, 1134727361, 1134727364, 1134727366, 1134727384,
  1134727386, 1134727388, 1134727392, 1134727406, 1134727408, 1134727410,
  1134727445, 1134727447, 1134727449, 1134727451, 1134727453, 1134727455,
  1134727461, 1134727465, 1134727467, 1134727469, 1134727486, 1134727490,
  1134727492, 1134727494, 1134727500, 1134727502, 1134727504, 1134727508,
  1134727510, 1134727515, 1134727523, 1134727525, 1134727527, 1134727533,
  1134727535, 1134727537, 1134727541, 1134727543, 1134727545, 1134727548,
  1134727550, 1134727556, 1134727560, 1134727562, 1134727610, 1134727612,
  1134727614, 1134727616, 1134727620, 1134727622, 1134727632, 1134727634,
  1134727636, 1134727638, 1134727640, 1134727645, 1134727649, 1134727651,
  1134727653, 1134727655, 1134727657, 1134727662, 1134727664, 1134727666,
  1134727670, 1134727672, 1134727679, 1134727681, 1134727683, 1134727687,
  1134727690, 1134727692, 1134727694, 1134727696, 1134727698, 1134727700,
  1134727730, 1134727732, 1134727841, 1134727843, 1134727845, 1134727847,
  1134727849, 1134727851, 1134727855, 1134727857, 1134727859, 1134727861,
  1134727863, 1134727866, 1134727868, 1134727870, 1134727872, 1134727874,
  1134727878, 1134727883, 1134727885, 1134727887, 1134727889, 1134727893,
  1134727895, 1134727897, 1134727899, 1134727901, 1134727903, 1134727905,
  1134727907, 1134727913, 1134727915, 1134727917, 1134727919, 1134727921,
  1134727923, 1134727933, 1134727937, 1134727940, 1134727992, 1134727994,
  1134727996, 1134727998, 1134728000, 1134728017, 1134728020, 1134728022,
  1134728024, 1134728026, 1134728030, 1134728032, 1134728037, 1134728039,
  1134728041, 1134728047, 1134728049, 1134728053, 1134728057, 1134728063,
  1134728071, 1134728073, 1134728075, 1134728077, 1134728190, 1134728192,
  1134728194, 1134728196, 1134728205, 1134728207, 1134728213, 1134728215,
  1134728222, 1134728224, 1134728234, 1134728242, 1134728244, 1134728246,
  1134728249, 1134728251, 1134728253, 1134728255, 1134728257, 1134728273,
  1134728292, 1134728294, 1134728296, 1134728302, 1134728308, 1134728347,
  1134728349, 1134728351, 1134728355, 1134728357, 1134728360, 1134728362,
  1134728368, 1134728372, 1134728375, 1134728377, 1134728379, 1134728381,
  1134728383, 1134728385, 1134728390, 1134728392, 1134728394, 1134728396,
  1134728398, 1134728400, 1134728405, 1134728407, 1134728409, 1134728411,
  1134728415, 1134728419, 1134728423, 1134728427, 1134728429, 1134728438,
  1134728440, 1134728442, 1134728446, 1134728449, 1134728521, 1134728523,
  1134728527, 1134728529, 1134728533, 1134728535, 1134728537, 1134728539,
  1134728541, 1134728543, 1134728545, 1134728549, 1134728551, 1134728553,
  1134728555, 1134728561, 1134728563, 1134728565, 1134728567, 1134728569,
  1134728573, 1134728575, 1134728579, 1134728581, 1134728585, 1134728587,
  1134728589, 1134728602, 1134728606, 1134728609, 1134728611, 1134728615,
  1134728617, 1134728619, 1134728623, 1134727301, 1134727372, 1134727376,
  1134727382, 1134727412, 1134727484, 1134727519, 1134727521, 1134727675,
  1134727677, 1134727734, 1134727736, 1134727738, 1134727740, 1134727745,
  1134727837, 1134727925, 1134727927, 1134727929, 1134727931, 1134728007,
  1134728009, 1134728011, 1134728013, 1134728015, 1134728043, 1134728045,
  1134728081, 1134728083, 1134728086, 1134728088, 1134728090, 1134728092,
  1134728094, 1134728236, 1134728238, 1134728240, 1134728279, 1134728281,
  1134728285, 1134728387, 1134728525, 1134728557, 1134728559, 1134728596,
  1134728598, 1134733640, 1134738790, 1134744523, 1134735565, 1134727839,
  2152267931, 2152269468, 2159370302, 2159370296, 2159370341, 2159370313,
  2159369122, 2159369175, 2159369179, 2159370290, 2159369165, 2159370292,
  2159370308, 2159370332, 2159370287, 2159369177, 2159370318, 2159369173,
  2159369192, 2159370310, 2159369171, 2159370300, 2159370325, 2159369204,
  2159369206, 2159370294, 2159369183, 2159370315, 2167721298, 2167721289,
  2167721293, 2167721296, 2167721300, 2167721291, 2152231135, 2152267875,
  2152267877, 2152267881, 2152267883, 2152267886, 2152267888, 2152267890,
  2152267892, 2152267897, 2152267899, 2152267901, 2152267911, 2152267913,
  2152267915, 2152267917, 2152267920, 2152267922, 2152267925, 2152267927,
  2152267929, 2152267933, 2152269162, 2152269173, 2152269181, 2152269185,
  2152269190, 2152269192, 2152269195, 2152269197, 2152269199, 2152269201,
  2152269204, 2152269206, 2152269208, 2152269210, 2152269470, 2152269472,
  2152269474, 2152269476, 2152269478, 2152269515, 2152270020, 2152270022,
  2152270026, 2152270028, 2152270030, 2152270032, 2152270034, 2152270072,
  2152270074, 2152270078, 2152270084, 2152270089, 2152270107, 2152270109,
  2152270111, 2152270133, 2152270143, 2152270145, 2152270147, 2152270149,
  2152270151, 2152270153, 2152271190, 2152271192, 2152271194, 2152271196,
  2152271198, 2152271200, 2152271202, 2152271206, 2152271208, 2152271210,
  2152271212, 2152271214, 2152271217, 2152271219, 2152271221, 2152271226,
  2152271228, 2152271230, 2152271488, 2152271490, 2152271492, 2152271494,
  2152271498, 2152271500, 2152272523, 2152272525, 2152272528, 2152272531,
  2152272533, 2152272535, 2152272537, 2152272539, 2152272541, 2152272543,
  2152267909, 2159370339, 2159370285, 2159369167, 2159369186, 2159369194,
  2159369210, 2160057953, 2160057956, 2160057959, 2160057961, 2160057990,
  2160057992, 2160057994, 2160057996, 2160057999, 2160058004, 2160058006,
  2160058012, 2160058016, 2160058020, 2160058023, 2160058027, 2160058029,
  2159370337, 2159370335, 2159370329, 2159369188, 2159369181, 2159369163,
  2159369169, 2159369208, 2159370298, 2159369190, 2152272545, 2160336335,
  2160336337, 2160336341, 2160336343, 2160336345, 2160336347, 2160336349,
  2160336351, 2160336353, 2160336339, 2162257412, 2162257415, 2162257417,
  2162257419, 2162257421, 2162257424, 2162257426, 2162257428, 2162257430,
  2162257435, 2162257438, 2162257443, 2162257445, 2162257447, 2162257449,
  2152270104, 2152267879, 2164856925, 2164856927, 2164856937, 2164856939,
  2164856941, 2164856943, 2164856947, 2164856949, 2164856952,
];
export const COMIC_IDS = [807799816, 1077480134];

export const Comic1 = [
  "image6.jpg",
  "image7.jpg",
  "image8.jpg",
  "image9.jpg",
  "image10.jpg",
];

export const Comic2 = [
  "image1.jpg",
  "image2.jpg",
  "image3.jpg",
  "image4.jpg",
  "image5.jpg",
];

export const NFTGROUP1 = [
  1063784408, 1070275232, 1082237590, 1082247206, 1085657120, 1207882141,
  1207883335, 1077627663, 1286812359, 1286813527, 1286814430, 1286815592,
  1286818132, 1286819174, 1286821034, 1286822113, 1286823329, 1286824873,
];

export const NFTGROUP2 = [463919053, 474689452, 542789067, 928814791];

export const NFTGROUP3 = [1082253222, 1082252909, 1082242279];

export const COMIC_ASSET_ID = [
  2298695387, 2298695702, 2298695758, 2298695907, 2298695961, 2298696053,
  2298696172, 2298696293, 2298696825, 2298697025, 2298697067, 2298697216,
  2298697265, 2298697318, 2298697699, 2298697740, 2298697914, 2298697980,
  2298698017, 2298699230, 2298699359, 2298699394, 2298699453, 2298699533,
  2298699650, 2298699730, 2298699789, 2298699893, 2298700020, 2298700052,
  2298700129, 2298700262, 2298700311, 2298700383, 2298700455, 2298700508,
  2298700534, 2298700632, 2298700702, 2298700785, 2298700862, 2298700986,
  2298701062, 2298701129, 2298701183, 2298701268, 2298701316, 2298701397,
  2298701440, 2298701517, 2298701627, 2298701698, 2298701826, 2298701886,
  2298701952, 2298702011, 2298702065, 2298702130, 2298702207, 2298702311,
  2298702375, 2298702441, 2298702610, 2298702726, 2298702809, 2298702851,
  2298702941, 2298703133, 2298703603, 2298703731, 2298703776, 2298703826,
  2298703852, 2298703963, 2298704016, 2298704089, 2298704180, 2298704248,
  2298704300, 2298704433, 2298704487, 2298704605, 2298704688, 2298704805,
  2298704861, 2298704925, 2298705046, 2298705150, 2298705209, 2298705366,
  2298705445, 2298705524, 2298705587, 2298705643, 2298705762, 2298705799,
  2298705908, 2298705952, 2298706040, 2298706144, 2298706257, 2298706311,
  2298706345, 2298706390, 2298706627, 2298706696, 2298706795, 2298706887,
  2298706958, 2298707006, 2298707058, 2298707095, 2298707179, 2298707316,
  2298707397, 2298707521, 2298707564, 2298707680, 2298707763, 2298707839,
  2298708420, 2298708533, 2298708681, 2298708729, 2298708827, 2298708848,
  2298708985, 2298709032, 2298709080, 2298709193, 2298709242, 2298709334,
  2298709418, 2298709547, 2298709569, 2298709656, 2298709770, 2298709859,
  2298709891, 2298709997, 2298710149, 2298710175, 2298710286, 2298710328,
  2298710389, 2298710428, 2298710455, 2298710663, 2298710732, 2298710830,
  2298710935, 2298710958, 2298711023, 2298711051, 2298711112, 2298711168,
  2298711225, 2298711316, 2298711425, 2298711574, 2298711631, 2298711672,
  2298711723, 2298711819, 2298711929, 2298711972, 2298712021, 2298712080,
  2298712176, 2298712223, 2298712285, 2298712458, 2298712494, 2298713190,
  2298713291, 2298713371, 2298713462, 2298713505, 2298713588, 2298713612,
  2298713680, 2298713780, 2298713826, 2298713865, 2298713955, 2298714023,
  2298714100, 2298714157, 2298714193, 2298714297, 2298714347, 2298714400,
  2298714467, 2298714514, 2298714554, 2298714618, 2298714721, 2298714775,
  2298714846, 2298714947, 2298715173, 2298715223, 2298715259, 2298715328,
  2298715464, 2298715489, 2298715651, 2298715750, 2298715783, 2298715810,
  2298716008, 2298716161, 2298716234, 2298716299, 2298716331, 2298716381,
  2298716489, 2298716537, 2298716580, 2298716674, 2298716731, 2298716839,
  2298716909, 2298716962, 2298717026, 2298717483, 2298717816, 2298717996,
  2298718045, 2298718101, 2298718198, 2298718437, 2298718468, 2298718527,
  2298718593, 2298718645, 2298718706, 2298718739, 2298718775, 2298718862,
  2298718880, 2298718958, 2298719142, 2298719181, 2298719271, 2298719344,
  2298719420, 2298719481, 2298719557, 2298719621, 2298719644, 2298719741,
  2298719835, 2298719890, 2298719954, 2298719985, 2298720025, 2298720062,
  2298720218, 2298720281, 2298720312, 2298720372, 2298720394, 2298720587,
  2298720622, 2298720741, 2298720800, 2298720858, 2298720941, 2298721040,
  2298721068, 2298721153, 2298721176, 2298721306, 2298721347, 2298721414,
  2298721508, 2298721882, 2298722302, 2298722434, 2298722467, 2298722546,
  2298722682, 2298722716, 2298722772, 2298722844, 2298723131, 2298723185,
  2298723303, 2298723432, 2298723512, 2298723589, 2298723686, 2298723749,
  2298723796, 2298723957, 2298724021, 2298724053, 2298724103, 2298724274,
  2298724591, 2298724660, 2298724711, 2298724831, 2298724874, 2298724939,
  2298724988, 2298725046, 2298725109, 2298725152, 2298725369, 2298725407,
  2298725460, 2298725494, 2298725539, 2298725581, 2298725624, 2298725693,
  2298725727, 2298725791, 2298725969, 2298726008, 2298726075, 2298726291,
  2298726903, 2298726983, 2298727105, 2298727142, 2298727181, 2298727219,
  2298727300, 2298727421, 2298727491, 2298727542, 2298727592, 2298727702,
  2298727731, 2298727781, 2298727803, 2298727876, 2298727951, 2298728023,
  2298728143, 2298728242, 2298728292, 2298728381, 2298728447, 2298728480,
  2298728551, 2298728625, 2298728673, 2298728738, 2298728842, 2298728996,
  2298729064, 2298729107, 2298729143, 2298729240, 2298729265, 2298729370,
  2298729431, 2298729488, 2298729703, 2298729760, 2298729820, 2298729862,
  2298729916, 2298730051, 2298730081, 2298730133, 2298730162, 2298730223,
  2298730327, 2298730379, 2298730987, 2298731097, 2298731166, 2298731247,
  2298731313, 2298731440, 2298731523, 2298731576, 2298731601, 2298731741,
  2298731830, 2298731873, 2298731921, 2298732004, 2298732055, 2298732102,
  2298732173, 2298732222, 2298732286, 2298732393, 2298732441, 2298732500,
  2298732520, 2298732555, 2298732612, 2298732691, 2298732741, 2298732774,
  2298732822, 2298732883, 2298733027, 2298733080, 2298733120, 2298733215,
  2298733258, 2298733486, 2298733521, 2298733585, 2298733625, 2298733672,
  2298733774, 2298733877, 2298733933, 2298734011, 2298734065, 2298734151,
  2298734192, 2298734220, 2298734300, 2298734366, 2298734398, 2298734541,
  2298734591, 2298734664, 2298735103, 2298735303, 2298735427, 2298735481,
  2298735584, 2298735622, 2298736243, 2298736349, 2298736493, 2298736569,
  2298736618, 2298736697, 2298736751, 2298736777, 2298736831, 2298736883,
  2298736949, 2298737008, 2298737103, 2298737226, 2298737272, 2298737310,
];

export const TRINKETS = [689092612, 689109687, 689108622, 689106614];

export const MENTALAF = [1018096668,1018091978,1018067968,1018056293,1018053648,1018047609,1018030094,1018026022,1018019429,1018002460,1017998901,1017987464,1017985690,1017977198	
	,1017975361	,1017974214,1017967770,1017962563,1017928543,1017917836,1017851000,1017849181,1017836387,1017828254,1017827670,1017817126,1017810829,1017799991,1017793889,1017793648	
	,1017791272];

