import React, { useState } from "react";
import "./scratchy.css";
import ScratchCard from "simple-scratch-card";
import cardImage from "../../assets/prezident.webp";
import cover from "../../assets/blessed.webp";
import { useWalletInfo } from "./UserWalletHook.js";
import { useWallet } from "@txnlab/use-wallet";
import axios from "axios";
import { toast } from "react-toastify";
import { encode } from "uint8-to-base64";
import { API_ENDPOINT, API_ENDPOINT2 } from "../../constants.ts";
import { getUserComics, comic_open_request_txs } from "../utilities.ts";

const backgroundImage = {
  backgroundImage: `url(${cardImage})`,
};

const Scratchcard = () => {
  const [userFound, setUserFound] = useState(false);
  const { walletAddress } = useWalletInfo();
  const { signTransactions } = useWallet();

  const handleComplete = () => {
    setUserFound(true);
  };
  /* 
  async function scratchyReward(scratchyID) {
    if (!walletAddress) {
      toast.info("Please connect your wallet first!");
      return;
    }

    toast.info("Sign the transaction to open!");
    try {
      

      const transaction = await comic_open_request_txs(
        walletAddress,
        scratchyID
      );
      if (!transaction) {
        toast.info("Something went wrong while creating the transactions!");
        setIsBuyClicked(false);
        return;
      }

      let user_comic_open_tx; 

      
      const signedTransaction = await signTransactions([
        algosdk.encodeUnsignedTransaction(transaction),
      ]);

     
      user_comic_open_tx = encode(signedTransaction[0]);

      setBuyTxId("");
      playSound(open);
      
      toast.info("Unwrapping!!!");
      const res = await axios.post(`${API_ENDPOINT}/open-comic/`, {
        user_comic_open_tx,
      });

     
      if (res.status === 200) {
        const { transaction, assetid, rarity } = res.data;
        toast.success("Successfully unwrapped your comic!");
        setIsOpenComicClicked(false);
        playSound(wow);
       
        setComicRarity(rarity);
        const updatedUserComics = await getUserComics(walletAddress);
        setUserComics(updatedUserComics);

        
      } else {
        toast.error("Something went wrong while buying!");
        setIsBuyClicked(false);
      }
    } catch (error) {
      
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsBuyClicked(false);
    }
  } */

  return (
    <>
      {!walletAddress ? (
        <>We can't check for you JUNK if you dont connect your wallet</>
      ) : (
        <div className="scratch-container" style={backgroundImage}>
          <ScratchCard
            width={350}
            height={350}
            cover={cover}
            percent={80}
            onComplete={handleComplete}
          >
            <h1 className={`scratch-reward ${userFound ? "user-found" : ""}`}>
              You win SHIT!
            </h1>
          </ScratchCard>
        </div>
      )}
    </>
  );
};

export default Scratchcard;
