import React, { useState, useEffect } from "react";
import "./reader.css";
import { Link } from "react-router-dom";
import { useWallet } from "@txnlab/use-wallet";
import { getUserComics } from "../utilities.ts";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

// Import all the comic images at once using require.context
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

// Adjust the path to your images folder inside your 'src' or 'public' directory
const images = importAll(
  require.context("../../assets/comic/EP1", false, /\.(png|jpe?g|svg|webp)$/)
);

const ComicReader = () => {
  const [page, setPage] = useState(1);
  const [imageSrcs, setImageSrcs] = useState([]);
  const totalPages = Object.keys(images).length;
  const [loading, setLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState("");
  const { activeAccount } = useWallet();
  const [comicCount, setComicCount] = useState([]); // Initialized as an empty array

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const userAddressLocal = activeAccount?.address;

        if (userAddressLocal) {
          setWalletAddress(userAddressLocal);
          // Fetch comics data once wallet address is available
          const comicsRes = await getUserComics(userAddressLocal);
          setComicCount(comicsRes || []); // Ensure it's always an array
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after both operations complete
      }
    };

    fetchData();
  }, [activeAccount]);

  useEffect(() => {
    // Check if the device is mobile
    const isMobile = window.innerWidth <= 1024;

    const loadImages = () => {
      if (isMobile) {
        // For mobile devices, load only the current page
        const page1 = images[`page${page}.webp`] || images[`page${page}.jpg`];
        setImageSrcs([page1].filter(Boolean));
      } else {
        // For desktop, load the current pair of pages
        const page1 = images[`page${page}.webp`] || images[`page${page}.jpg`];
        const page2 =
          images[`page${page + 1}.webp`] || images[`page${page + 1}.jpg`];
        setImageSrcs([page1, page2].filter(Boolean));
      }
    };

    loadImages();
  }, [page]);

  const nextPage = () => {
    // Determine the maximum page the user can access
    const maxAccessiblePage =
      Array.isArray(comicCount) && comicCount.some((count) => count > 0)
        ? totalPages
        : 10;

    // Handle mobile and desktop navigation
    if (window.innerWidth <= 1024) {
      // Mobile: Navigate one page at a time
      if (page < maxAccessiblePage) {
        setPage((prevPage) => prevPage + 1);
      }
    } else {
      // Desktop: Navigate two pages at a time
      if (page + 2 <= maxAccessiblePage) {
        setPage((prevPage) => prevPage + 2);
      }
    }
  };

  const prevPage = () => {
    // Handle mobile and desktop navigation
    if (window.innerWidth <= 1024) {
      // Mobile: Navigate one page at a time
      setPage((prevPage) => Math.max(prevPage - 1, 1));
    } else {
      // Desktop: Navigate two pages at a time
      setPage((prevPage) => Math.max(prevPage - 2, 1));
    }
  };
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  // Disable the "Next" button based on comic ownership and the current page
  const maxAccessiblePage =
    Array.isArray(comicCount) && comicCount.some((count) => count > 0)
      ? totalPages
      : 10;
  const isNextDisabled =
    window.innerWidth <= 1024
      ? page >= maxAccessiblePage
      : page + 2 > maxAccessiblePage;

  return (
    <div className="readerwrapper">
      <div className="backtokiosk">
        <Link to="/market/comics" type="button" className="btn btn-info">
          BACK TO KIOSK
        </Link>
      </div>
      <div className="closeposition">
        <Link
          to="/"
          type="button"
          className="btn-close"
          aria-label="Close"
        ></Link>
      </div>
      <div className="reader">
        <button type="button" className="btn btn-info" onClick={prevPage}>
          <MdKeyboardDoubleArrowLeft />
        </button>
        <div className="pages">
          {imageSrcs.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Comic Page ${page + index}`}
              onContextMenu={handleContextMenu}
            />
          ))}
        </div>

        <button
          type="button"
          className="btn btn-info"
          onClick={nextPage}
          disabled={isNextDisabled}
        >
          <MdKeyboardDoubleArrowRight />
        </button>
        {isNextDisabled ? (
          walletAddress === "" || maxAccessiblePage === 10 ? (
            <div className="nocomic">
              <h4>
                To continue enjoying the Mental Marvin story you will have to
                buy a digital comic.
              </h4>
            </div>
          ) : (
            <div className="thankyou">
              Thank you for being mental! Hope you come back for more comics.
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default ComicReader;
