import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_ENDPOINT, API_ENDPOINT2, IPFS_PREFIX } from "../../constants.ts";
import TrinketModal from "./TrinketModal";
import TrinketModalLite from "./TrinketModalLite.js";
import "./trinkets.css";
import { Navigation, FreeMode } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import Progress from "../Progress.js";
import { useWalletInfo } from "../utils/UserWalletHook.js";
import Loader from "../Loader.js";

const placeholderImg = "/cube.webp";

const TrinketDisplay = () => {
  const [trinkets, setTrinkets] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal open status
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [selectedTrinket, setSelectedTrinket] = useState(null); // State for selected trinket
  const [loading, setLoading] = useState(true);
  SwiperCore.use([Navigation, FreeMode]);
  const { walletAddress } = useWalletInfo();

  const fetchTrinkets = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_ENDPOINT}/check-trinkets/`, {
        wallet_address: walletAddress || "1234", // Send wallet address from frontend
      });

      setTrinkets(response.data); // Trinkets along with redeemable status and category
    } catch (error) {
      console.error("Error fetching trinkets:", error);
    } finally {
      setLoading(false); // Set loading to false after the fetch completes
    }
  };

  useEffect(() => {
    // Only fetch trinkets if the wallet address is available
    {
      fetchTrinkets();
    }
  }, [walletAddress]);

  const refreshTrinketData = (updatedTrinket) => {
    setTrinkets(updatedTrinket);
  };

  /* const playSound = (sound) => {
    const audio = new Audio(sound);
    audio.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  }; */

  // Filter trinkets by category
  const treasureTrinkets = trinkets.filter(
    (trinket) => trinket.category === "Treasure"
  );
  const activityTrinkets = trinkets.filter(
    (trinket) => trinket.category === "Activity"
  );
  const marketTrinkets = trinkets.filter(
    (trinket) => trinket.category === "Market"
  );
  const mentalTrinkets = trinkets.filter(
    (trinket) => trinket.category === "MentalAF"
  );
  const junkTrinkets = trinkets.filter(
    (trinket) => trinket.category === "Junk"
  );

  // Function to open the modal
  const openModal = (trinket) => {
    setSelectedTrinket(trinket);
    // Set the selected trinket
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedTrinket(null); // Clear the selected trinket
  };

  const openModal2 = (trinket) => {
    setSelectedTrinket(trinket);
    // Set the selected trinket
    setIsModal2Open(true);
  };

  // Function to close the modal
  const closeModal2 = () => {
    setIsModal2Open(false); // Close the modal
    setSelectedTrinket(null); // Clear the selected trinket
  };

  const renderTrinketSwiper = (title, description, filteredTrinkets) => (
    <>
      <h3>{title}</h3>
      <h6>{description}</h6>
      <Swiper
        className="trinketslider"
        initialSlide={0}
        freeMode={true}
        breakpoints={{
          320: { slidesPerView: 3.5 },
          768: { slidesPerView: 4.5 },
          1024: { slidesPerView: 5.5 },
          1240: { slidesPerView: 6.5 },
        }}
      >
        {filteredTrinkets.length > 0 ? (
          filteredTrinkets
            .filter((trinket) => trinket.is_active)
            .map((trinket) => (
              <SwiperSlide key={trinket.asset_id}>
                {loading ? (
                  <img
                    className="placeholderimg"
                    src={placeholderImg}
                    alt="ForceCube"
                  />
                ) : (
                  <div
                    className={`trinket-slide ${
                      trinket.supply === 0
                        ? "supply-zero"
                        : trinket.last_claimed_at
                        ? "claimed"
                        : trinket.is_redeemable
                        ? "eligible"
                        : ""
                    }`}
                    onClick={() => openModal(trinket)}
                  >
                    <img
                      src={`${IPFS_PREFIX}/${trinket.image_cid}`}
                      alt={trinket.name}
                    />
                    <h5>{trinket.name}</h5>
                    <div className="trinket-remaining">
                      {trinket.supply !== 0 ? (
                        <>Remaining {trinket.supply}</>
                      ) : (
                        <>NO MORE JUNK</>
                      )}
                    </div>
                    {trinket.last_claimed_at ? (
                      <div className="trinket-progress claimed">CLAIMED</div>
                    ) : (
                      <div
                        className={`trinket-progress ${
                          trinket.is_redeemable ? "progress-full" : ""
                        }`}
                      >
                        {trinket.is_redeemable ? (
                          <h6>CLAIM NOW</h6>
                        ) : (
                          <h6>
                            {trinket.user_asset_quantity} /{" "}
                            {trinket.requirement.quantity}
                          </h6>
                        )}

                        <Progress
                          percentage={
                            (trinket.user_asset_quantity /
                              trinket.requirement.quantity) *
                            100
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </SwiperSlide>
            ))
        ) : (
          <p>No {title} trinkets available.</p>
        )}
      </Swiper>
    </>
  );

  return (
    <>
      {loading ? (
        <div className="trinket-container">
          <div className="trinketmain">
            {" "}
            <Loader />
          </div>
        </div>
      ) : (
        <div className="trinket-container">
          <TrinketModal
            isOpen={isModalOpen}
            onClose={closeModal}
            trinket={selectedTrinket}
            wallet={walletAddress}
            refreshTrinketData={refreshTrinketData}
          />
          <TrinketModalLite
            isOpen={isModal2Open}
            onClose={closeModal2}
            trinket={selectedTrinket}
          />
          <div className="trinketmain">
            <div className="myjunk-wrapper">
              <div>
                <h3>MY J.U.N.K.</h3>
                <p></p>
                <h6>
                  Junkyard of Unusual <br />
                  Nostalgic Knick-knacks
                </h6>
                <p></p>
                <h6>RANK:</h6>
                <h5>Clean AF</h5>
              </div>
              <Swiper
                className="trinketslider"
                initialSlide={0}
                freeMode={true}
                breakpoints={{
                  320: { slidesPerView: 3.5 },
                  768: { slidesPerView: 6.5 },
                  1024: { slidesPerView: 7.5 },
                }}
              >
                {trinkets.length > 0 ? (
                  trinkets
                    .filter(
                      (trinket) =>
                        trinket.currently_holding > 0 &&
                        trinket.reward_id !== 1047740777 &&
                        trinket.name !== "Cubes" &&
                        (trinket.category === "Junk" ||
                          trinket.category === "Treasure")
                    )
                    .map((trinket) => (
                      <SwiperSlide key={trinket.asset_id}>
                        {loading ? (
                          <img
                            className="placeholderimg"
                            src={placeholderImg}
                            alt="ForceCube"
                          />
                        ) : (
                          <div
                            className={`trinket-slide-junk claimed ${
                              trinket.user_asset_quantity >= 1 ? "claimed" : ""
                            }`}
                            onClick={() => openModal2(trinket)}
                          >
                            <img
                              src={`${IPFS_PREFIX}/${trinket.image_cid}`}
                              alt={trinket.name}
                            />
                            <h5>{trinket.name}</h5>
                            <div className="trinket-remaining">
                              QTY {trinket.currently_holding}
                            </div>
                          </div>
                        )}
                      </SwiperSlide>
                    ))
                ) : (
                  <p>No Scrap available.</p>
                )}
              </Swiper>
            </div>
            {renderTrinketSwiper(
              "DUMPSTER DIVER",
              "Collect JUNK and find some epic treasure",
              treasureTrinkets
            )}
            {renderTrinketSwiper(
              "MENTALLY ACTIVE",
              "Swap traits on your Marvin to get some JUNK",
              activityTrinkets
            )}
            {renderTrinketSwiper(
              "JUNK MOVER",
              "Make moves on the market and get some JUNK",
              marketTrinkets
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TrinketDisplay;
