const Progress = ({ percentage }) => {
  const cappedPercentage = Math.min(percentage, 100);

  return (
    <div className="trinket-progress-bar-wrapper">
      <div
        className="trinket-progress-bar"
        role="progressbar"
        style={{ width: `${cappedPercentage}%` }}
        aria-valuenow={cappedPercentage}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
      {/* {cappedPercentage} */}
    </div>
  );
};

export default Progress;
