// Import necessary libraries and components
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./collections.css";
import algoforce from "../../assets/collections/AF.jpg";
import marvin from "../../assets/collections/marvin.jpg";
import ripley from "../../assets/collections/ripley.jpg";
import addripley from "../../assets/collections/addripley.jpg";
import custombcg from "../../assets/collections/custombcg.jpg";
import cyberparts from "../../assets/collections/cyberparts.jpg";
import item from "../../assets/collections/item.jpg";
import mutate from "../../assets/collections/mutate.jpg";
import zombify from "../../assets/collections/zombify.jpg";
import traitshop from "../../assets/collections/traitshop.jpg";
import swapper from "../../assets/collections/swapper.jpg";
import mentalaf from "../../assets/collections/mentalaf.jpg";
import hunt from "../../assets/collections/hunt.jpg";
import React from "react";
import cube_icon from "../../assets/cube.webp";
import { BsFillStarFill } from "react-icons/bs";
import AssetBoxOptimal from "../assetboxoptimal.tsx";
import {
  ALGO_FORCE_IDS,
  ASSET_IDS,
  SWEET_RIPLEY_IDS,
} from "../../constants.ts";

// Collection guide
export default function Collections() {
  const [randomAssets, setRandomAssets] = useState([]);
  const [randomAssets2, setRandomAssets2] = useState([]);
  const [randomAssets3, setRandomAssets3] = useState([]);

  useEffect(() => {
    // Function to get 6 random unique items from ALGO_FORCE_ID
    const getRandomAssets = (array, count) => {
      const shuffled = [...array].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, count);
    };

    // Set random assets when component mounts
    const selectedAssets = getRandomAssets(ALGO_FORCE_IDS, 6);
    const selectedAssets2 = getRandomAssets(ASSET_IDS, 6);
    const selectedAssets3 = getRandomAssets(SWEET_RIPLEY_IDS, 6);
    setRandomAssets(selectedAssets);
    setRandomAssets2(selectedAssets2);
    setRandomAssets3(selectedAssets3);
  }, []);

  return (
    <div className="fixed-container">
      <div className="collectionmain">
        <h3>COLLECTIONS</h3>
        <div className="closeposition">
          <Link
            to="/"
            type="button"
            className="btn-close"
            aria-label="Close"
          ></Link>
        </div>
        <div className="collections">
          {" "}
          <div className="collection-window">
            <img src={algoforce} alt="algoforce" className="collection-img" />
            <div className="collection-info">
              <div className="collection-description">
                The Algo Force is an elite squad tasked with hunting down forked
                bots—rogue machines glitching out of their purpose. Led by the
                iron-willed Franky, they stop at nothing, wielding cutting-edge
                tech to capture the bots and hunt down Marvin, a bot glitched
                since his creation. But as they chase him, they edge closer to
                uncovering secrets that could shatter the very foundation of
                their mission.
              </div>
              <div className="collection-details">
                <h6>
                  <span>Type:</span> Hand Drawn - 1/1
                </h6>
                <h6>
                  <span>Pieces:</span> 100
                </h6>
                <h6>
                  <span>Standard:</span> ARC 69 - unmutable
                </h6>
              </div>
            </div>
            <h5 className="title-utilities">UTILITIES</h5>
            <div className="collection-utilities">
              <div className="fc-farm">
                <h6>WEEKLY FARM</h6>
                <div>
                  5 <img src={cube_icon} alt="cube" />
                </div>
              </div>
              <div className="upgrades">
                <div>
                  <h6>CUSTOM UPGRADE OPTIONS WITH TICKETS</h6>
                  <div className="upgrade-icons">
                    <img src={custombcg} alt="cube" />
                    <img src={cyberparts} alt="cube" />
                    <img src={item} alt="cube" />
                    <img src={mutate} alt="cube" />
                    <img src={zombify} alt="cube" />
                  </div>
                </div>
              </div>
              <div className="fc-farm">
                <h6>WEEKLY AIRDROP</h6>
                <p></p>
                <div>NONE</div>
              </div>
            </div>
            <div className="special-utilities">
              <h6>DYNAMIC CONTENT</h6>
              <div>
                <span>COMING SOON!</span>
                <img src={hunt} alt="hunt" />
              </div>
            </div>
            <div className="collection-items-wrapper">
              <h6>RANDOM COLLECTION ITEMS</h6>
              <div className="collection-items">
                {randomAssets.map((asset, index) => (
                  <AssetBoxOptimal
                    key={index}
                    asset_id={asset}
                    onNameReceived={undefined}
                    onImageUrlReceived={undefined}
                    onTraitsReceived={undefined}
                  />
                ))}
              </div>
            </div>
            <div className="collection-links">
              <h6>GET COLLECTABLES FROM THE SECONDARY MARKET</h6>
              <div>
                <a
                  href="https://www.asalytic.app/collection/algo-force"
                  target="blank"
                  className="marketlink"
                >
                  <img src="/asalytic.png" alt="asalytic.app" />{" "}
                </a>
              </div>
            </div>
          </div>
          <div className="collection-window marvin">
            <img src={marvin} alt="algoforce" className="collection-img" />
            <div className="collection-info">
              <div className="collection-description">
                The Mental Marvin collection dives into the chaotic journey of
                Marvin, a forked bot defying any norms. Each NFT captures a
                pivotal moment in his quest to uncover the mystery behind the
                forks. This fully customizable collection lets you craft your
                own vision of Marvin. Whether you’re drawn to wild, chaotic art,
                unapologetically junky designs, or the thrill of going
                completely off the rails, every piece lets you embrace the
                madness and make the world of Mental Marvin uniquely yours.
              </div>
              <div className="collection-details">
                <h6>
                  <span>Type:</span> Hand Drawn - Generative
                </h6>
                <h6>
                  <span>Pieces:</span> 3600
                </h6>
                <h6>
                  <span>Standard:</span> ARC 19 - Fully Mutable
                </h6>
              </div>
            </div>
            <h5 className="title-utilities">UTILITIES</h5>
            <div className="collection-utilities">
              <div className="fc-farm">
                <h6>WEEKLY FARM</h6>
                <div>
                  1 <img src={cube_icon} alt="cube" />
                </div>
              </div>
              <div className="upgrades">
                <div>
                  <div className="upgrade-icons marvin-icons">
                    <Link to="/marvin/swapper">
                      <img src={swapper} alt="swapper" />
                    </Link>

                    <Link to="/marvin/traitshop">
                      <img src={traitshop} alt="traitshop" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="fc-farm">
                <h6>WEEKLY AIRDROP</h6>
                <Link to="/marvin/specials">SPECIAL TRAIT BENEFITS</Link>
              </div>
            </div>
            <div className="special-utilities">
              <h6>DYNAMIC CONTENT</h6>
              <div>
                <Link to="/mentalaf">
                  <img src={mentalaf} alt="mentalaf" />
                </Link>
              </div>
            </div>
            <div className="collection-items-wrapper">
              <h6>RANDOM COLLECTION ITEMS</h6>
              <div className="collection-items">
                {randomAssets2.map((asset, index) => (
                  <AssetBoxOptimal
                    key={index}
                    asset_id={asset}
                    onNameReceived={undefined}
                    onImageUrlReceived={undefined}
                    onTraitsReceived={undefined}
                  />
                ))}
              </div>
            </div>
            <div className="collection-links">
              <h6>GET COLLECTABLES FROM THE SECONDARY MARKET</h6>
              <a
                href="https://www.asalytic.app/collection/mental-marvin"
                target="blank"
                className="marketlink"
              >
                <img src="/asalytic.png" alt="asalytic.app" />{" "}
              </a>
            </div>
          </div>
          <div className="collection-window ripley">
            <img src={ripley} alt="algoforce" className="collection-img" />
            <div className="collection-info">
              <div className="collection-description">
                A link between Marvin's mysterious past and his chaotic present,
                Ripley is known for her unparalleled knowledge of the ultimate
                technology of the Force Cubes. Now, she is caught in a constant
                internal battle between her sweet, compassionate side and the
                darker, more defiant part of herself. Ripley’s story is one of
                intellect, defiance, and uncovering truths she didn’t even
                realize she already knew—always torn between the good she wants
                to do and the chaos she feels inside.
              </div>
              <div className="collection-details">
                <h6>
                  <span>Type:</span> Hand Drawn - Generative
                </h6>
                <h6>
                  <span>Pieces:</span> 600
                </h6>
                <h6>
                  <span>Standard:</span> ARC 69 - unmutable
                </h6>
              </div>
            </div>
            <h5 className="title-utilities">UTILITIES</h5>
            <div className="collection-utilities">
              <div className="fc-farm">
                <h6>WEEKLY FARM</h6>
                <div>
                  3 <img src={cube_icon} alt="cube" />
                </div>
              </div>
              <div className="upgrades">
                <div>
                  <h6>CURRENTLY NO UPGRADES AVAILABLE - T.B.A.</h6>
                </div>
              </div>
              <div className="fc-farm">
                <h6>WEEKLY AIRDROP</h6>
                <p></p>
                <div>NONE</div>
              </div>
            </div>
            <div className="special-utilities">
              <h6>DYNAMIC CONTENT</h6>
              T.B.A.
            </div>
            <div className="collection-items-wrapper">
              <h6>RANDOM COLLECTION ITEMS</h6>
              <div className="collection-items">
                {randomAssets3.map((asset, index) => (
                  <AssetBoxOptimal
                    key={index}
                    asset_id={asset}
                    onNameReceived={undefined}
                    onImageUrlReceived={undefined}
                    onTraitsReceived={undefined}
                  />
                ))}
              </div>
            </div>

            <div className="collection-links">
              <h6>GET COLLECTABLES FROM THE SECONDARY MARKET</h6>
              <a
                href="https://www.asalytic.app/collection/mental-marvin"
                target="blank"
                className="marketlink"
              >
                <img src="/asalytic.png" alt="asalytic.app" />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
