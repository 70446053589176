import { useState, useEffect } from "react";
import { useWallet } from "@txnlab/use-wallet";

export const useWalletInfo = () => {
  const { activeAccount } = useWallet();
  const [walletAddress, setWalletAddress] = useState("");
  const [connectType, setConnectType] = useState("");

  useEffect(() => {
    if (activeAccount?.address && activeAccount?.providerId) {
      setWalletAddress(activeAccount.address);
      setConnectType(activeAccount.providerId);
    }
  }, [activeAccount]);

  return { walletAddress, connectType };
};
