import React, { useState, useEffect } from "react";
import { ASSET_NAMES } from "../constants.ts";
import AssetBox from "./assetboxoptimal.tsx";
import "./customSelect.css"; // Create a custom CSS file for styles
import { FaAngleDown } from "react-icons/fa6";

type SelectFieldProps = {
  text: string;
  selectedAsset: string;
  setSelectedAsset: (asset: string) => void;
  assets: number[];
};

export const SelectField = (props: SelectFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState<string>("");

  useEffect(() => {
    // Reset selectedAssetId when props.selectedAsset is empty
    if (props.selectedAsset === "") {
      setSelectedAssetId("");
    }
  }, [props.selectedAsset]);

  const handleAssetSelect = (assetId: string) => {
    setSelectedAssetId(assetId);
    props.setSelectedAsset(assetId);
    setIsOpen(false); // Close dropdown after selection
  };

  return (
    <div className="custom-select-container">
      {/* Custom dropdown */}
      <div
        className="custom-select"
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        {selectedAssetId ? (
          <div className="selected-asset">
            {/* Display selected asset image and name */}

            <span>{ASSET_NAMES[selectedAssetId]}</span>
          </div>
        ) : (
          <span>Select a Marvin</span>
        )}
        <span>
          <FaAngleDown />
        </span>
      </div>

      {/* Dropdown options */}
      {isOpen && (
        <div className="dropdown-options">
          {props.assets.map((asset) => {
            const assetName = ASSET_NAMES[asset];
            const numbersOnly = assetName.match(/\d+/g);

            return (
              <div
                key={asset}
                className="dropdown-option"
                onClick={() => handleAssetSelect(asset.toString())}
              >
                {/* Display asset image */}
                <AssetBox
                  asset_id={asset}
                  onNameReceived={undefined}
                  onTraitsReceived={undefined}
                  onImageUrlReceived={undefined}
                />
                <span>#{numbersOnly ? numbersOnly.join(" ") : assetName}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
