import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { getNFD } from "../utilities.ts";
import { API_ENDPOINT } from "../../constants.ts";
import { TbBrandDiscord } from "react-icons/tb";
const DiscordShareButton = ({ text, ipfsImageUrl, assetName, address }) => {
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleShareToDiscord = async () => {
    setLoading(true);
    setIsSent(false);

    try {
      const user = await getNFD(address);

      // Fetch the IPFS image URL and convert it to a blob
      const response = await fetch(ipfsImageUrl);
      const blob = await response.blob();

      // Create an HTML image element to load the blob
      const img = new Image();
      img.src = URL.createObjectURL(blob);

      img.onload = async () => {
        try {
          // Resize the image to 40% using a canvas
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          // Set the canvas dimensions to 40% of the original image
          canvas.width = img.width * 0.4;
          canvas.height = img.height * 0.4;
          context.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Convert the canvas to base64
          const resizedBase64 = canvas.toDataURL("image/png").split(",")[1]; // Remove data:image/png;base64, prefix

          // Prepare the data as a JSON object
          const data = {
            text,
            user: user, // Make sure to extract the user name
            file: resizedBase64,
            fileName: `${assetName}.png`, // File name for backend reference
            fileType: "image/png", // Specify file type
          };

          // Send JSON data to the backend
          const apiResponse = await axios.post(
            `${API_ENDPOINT}/share-to-discord`,
            data,
            {
              headers: { "Content-Type": "application/json" },
            }
          );

          if (apiResponse.status === 200) {
            toast.success("Shared your Marvin on Discord for Judgement!");
            setIsSent(true);
          } else {
            toast.error("Something went wrong");
          }
        } catch (error) {
          console.error("Error from back end:", error);
          toast.error("Failed to send your Marvin.");
        } finally {
          setLoading(false); // Ensure loading is stopped after the image is processed
        }
      };

      img.onerror = () => {
        toast.error("Error loading the image. Please try again.");
        setLoading(false);
      };
    } catch (error) {
      console.error("Error fetching or processing image:", error);
      toast.error("Error fetching the image, please try again.");
      setLoading(false); // Stop loading on failure
    }
  };

  return (
    <>
      {loading ? (
        <button className="btn btn-warning" type="button" disabled>
          <div className="spinner-border text-light mr-2" role="status"></div>
        </button>
      ) : (
        <button
          className="btn btn-info"
          onClick={() => {
            if (isSent) {
              window.open("https://discord.gg/gjtMeYFe8R", "_blank");
            } else {
              handleShareToDiscord();
            }
          }}
        >
          {isSent ? (
            <>
              <TbBrandDiscord className="trait-shop-discord" /> CHECK WHAT
              MARVIN THINKS!
            </>
          ) : (
            "SEND CREATION FOR MARVIN TO JUDGE!"
          )}
        </button>
      )}
    </>
  );
};

export default DiscordShareButton;
