import React, { useState, useEffect, useRef } from "react";
import useSWR from "swr";
import axios from "axios";
import { ipfsToData } from "./utilities.ts";
import { NODE_ENDPOINT } from "../constants.ts";
import AssetImage from "./asset_image.tsx";
import { useDebounce } from "use-debounce";

// Placeholder image URL
const placeholderImageUrl = "/cube.webp";

// Fetcher function for SWR
const fetcher = async (url, retries = 5, delay = 1000, backoffFactor = 2) => {
  const attemptFetch = async (retryCount, currentDelay) => {
    try {
      const response = await axios.get(url);
      const { url: assetUrl, reserve, name } = response.data.params;
      const { url: imageUrl, traits } = await ipfsToData(assetUrl, reserve);
      return { imageUrl, traits, name };
    } catch (error) {
      if (error.response && error.response.status === 429) {
        if (retryCount > 0) {
          console.warn(
            `Received 429, retrying in ${currentDelay}ms... attempts remaining: ${
              retryCount - 1
            }`
          );
          await new Promise((res) => setTimeout(res, currentDelay));
          return attemptFetch(retryCount - 1, currentDelay * backoffFactor);
        } else {
          console.error("Error fetching data after multiple retries:", error);
          throw error;
        }
      } else {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  };
  return attemptFetch(retries, delay);
};

// Throttle requests with a delay (e.g., 500ms)
const useThrottledSWR = (key, fetcher, delay = 500) => {
  const [debouncedKey] = useDebounce(key, delay);
  return useSWR(debouncedKey, fetcher);
};

const AssetBoxForSwap = ({
  asset_id,
  onNameReceived,
  onTraitsReceived,
  onImageUrlReceived,
}) => {
  const [url, setUrl] = useState(placeholderImageUrl);
  const [sentImageUrls, setSentImageUrls] = useState(new Set()); // Track sent non-modified image URLs

  // Ref to track latest imageUrl for the current asset_id
  const latestImageUrlRef = useRef(null);

  // Use debounced SWR hook for throttled fetching
  const { data, error } = useThrottledSWR(
    asset_id ? `${NODE_ENDPOINT}/v2/assets/${asset_id}` : null,
    fetcher
  );

  useEffect(() => {
    if (data) {
      const { imageUrl, traits, name } = data;
      const modifiedUrl = imageUrl
        ? imageUrl.replace(
            "https://ipfs.mentalmarvin.art/ipfs/",
            "https://ipfs.algonode.dev/ipfs/"
          ) + "?optimizer=image&width=200"
        : placeholderImageUrl;

      // Update the image URL state
      if (url !== modifiedUrl) {
        setUrl(modifiedUrl || placeholderImageUrl);
      }

      // Use a ref to avoid duplicate processing
      if (latestImageUrlRef.current !== imageUrl) {
        latestImageUrlRef.current = imageUrl;

        // Send non-modified imageUrl only if it's not in sentImageUrls
        if (imageUrl && !sentImageUrls.has(imageUrl)) {
          setSentImageUrls((prev) => new Set(prev).add(imageUrl));
          if (onImageUrlReceived) onImageUrlReceived(imageUrl, asset_id);
        }

        // Send name and traits
        if (onNameReceived) onNameReceived(name, asset_id);
        if (onTraitsReceived) onTraitsReceived(traits, asset_id);
      }
    }
  }, [
    data,
    onNameReceived,
    onTraitsReceived,
    onImageUrlReceived,
    asset_id,
    url,
    sentImageUrls,
  ]);

  if (error) return <div>Error loading asset</div>;

  return (
    <div>
      <AssetImage image_url={url} />
    </div>
  );
};

export default AssetBoxForSwap;
