import React, { useState, useEffect } from "react";
import axios from "axios";
import { IPFS_PREFIX, API_ENDPOINT2, API_ENDPOINT } from "../../constants.ts";
import "./trinketmodal.css";
import Progress from "../Progress.js";
import { claim_trinket_txs } from "../utilities.ts";
import AssetBoxOptimal from "../assetboxoptimal.tsx";
import { useWallet } from "@txnlab/use-wallet";
import algosdk from "algosdk";
import { toast } from "react-toastify";
import { encode } from "uint8-to-base64";
import success from "../../assets/sounds/success.mp3";

const TrinketModal = ({
  isOpen,
  onClose,
  trinket,
  wallet,
  refreshTrinketData,
}) => {
  const [isClaimClicked, setIsClaimClicked] = useState(false);
  const { signTransactions } = useWallet();
  const [txId, setTxId] = useState("");
  const [loading, setLoading] = useState(true);
  const [trinketData, setTrinketData] = useState(null);
  const [rewardName, setRewardName] = useState("");

  useEffect(() => {
    setTrinketData(trinket);

    setLoading(false); // Update state when prop changes
  }, [trinket]);

  const fetchTrinketData = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${API_ENDPOINT}/check-trinkets/`, {
        wallet_address: wallet, // Send wallet address from frontend
      });
      refreshTrinketData(res.data);

      setTrinketData(
        res.data.find((item) => item.trinket_id === trinket.trinket_id)
      );
      setLoading(false);
    } catch (error) {
      console.error("Failed to refresh trinket data:", error);
      toast.error("Failed to refresh trinket data");
    }
  };

  const playSound = () => {
    const audio = new Audio(success);
    audio.play();
  };

  if (!isOpen) return null; // Return nothing if the modal isn't open

  async function claimTrinket(wallet, trinket) {
    if (!wallet) {
      toast.info("Please connect your wallet first!");
      return;
    }
    setIsClaimClicked(true);
    toast.info("Please sign the transaction.");
    try {
      const transactions = await claim_trinket_txs(wallet, trinket);
      if (!transactions || transactions.length === 0) {
        toast.info("Something went wrong while creating the transactions!");
        setIsClaimClicked(false);
        return;
      }

      // Sign transactions using useWallet module
      const signedTransactions = await signTransactions(
        transactions.map((tx) => algosdk.encodeUnsignedTransaction(tx))
      );

      // Encode signed transactions
      const encodedTransactions = signedTransactions.map((tx) => encode(tx));
      let data;
      if (transactions.length === 2) {
        // If there are only two transactions, keep the current structure
        data = {
          trinket_claim_tx: encodedTransactions[0], // First transaction
          trinket_transfer_tx: encodedTransactions[1], // Second transaction
        };
      } else if (transactions.length === 3) {
        // If there are three transactions, adjust the structure
        data = {
          trinket_claim_tx: encodedTransactions[0], // First transaction
          opt_in_tx: encodedTransactions[1], // Second transaction (opt-in)
          trinket_transfer_tx: encodedTransactions[2], // Third transaction
        };
      } else {
        // Handle any other cases (if necessary)
        throw new Error("Unexpected number of transactions.");
      }

      // Send the encoded signed transactions to the server
      const res = await axios.post(`${API_ENDPOINT}/claim-trinket/`, data);

      // Handle server response
      if (res.status === 200) {
        const { transaction } = res.data;
        toast.success("Successfully claimed your JUNK");
        setTxId(transaction);
        playSound();
        await fetchTrinketData();
      } else {
        toast.error("Something went wrong while claiming your JUNK!");
      }
    } catch (error) {
      // Handle errors and specific status codes
      console.error("Error while sending trinket transaction:", error); // Log the error for debugging
      if (error.response) {
        const { status, data } = error.response;

        if (status === 400 || status === 403 || status === 404) {
          toast.error(data || "Something went wrong while claiming your JUNK!");
        } else {
          toast.error("Something went wrong!");
        }
      } else {
        toast.error("Something went wrong!");
      }
    } finally {
      setIsClaimClicked(false); // Reset isBuyClicked after the process
    }
  }
  if (loading) return <div>Loading...</div>;

  if (!trinketData) {
    return (
      <div className="modal-overlay">
        <div className="trinket-modal-content">No trinket data available.</div>
      </div>
    ); // Handle case when trinketData is null
  }
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="trinket-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="closeposition">
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
            aria-label="Close"
          ></button>
        </div>
        <h2>{trinketData.name}</h2>
        <p>{trinketData.description}</p>
        <img
          src={`${IPFS_PREFIX}/${trinketData.image_cid}`}
          alt={trinketData.name}
        />
        <div className="trinket-progress-wrapper">
          {trinketData.reward == "Junk" ? (
            <>
              <p></p>
            </>
          ) : (
            <>
              <div className="trinket-reward">
                <h6> Rewards you {trinketData.reward_quantity}</h6>
                <AssetBoxOptimal asset_id={trinketData.reward_id} />
              </div>
              <div className="trinket-reward">
                <h6> Requires {trinketData.requirement.quantity}</h6>
                {trinketData.requirement.asset_id_required ? (
                  <>
                    <AssetBoxOptimal
                      asset_id={trinketData.requirement.asset_id_required}
                      onNameReceived={(name) => setRewardName(name)}
                    />{" "}
                    {rewardName}
                  </>
                ) : (
                  <h6>
                    {trinketData.requirement.transaction_type.toUpperCase()}
                  </h6>
                )}
              </div>
            </>
          )}
        </div>
        {trinketData.reward == "Junk" ? (
          <>You currently hold {trinketData.user_asset_quantity} </>
        ) : (
          <>
            Progress
            {trinketData.last_claimed_at ? (
              <div className="trinket-claimed">
                <p>
                  CLAIMED{" "}
                  {new Date(trinketData.last_claimed_at).toLocaleDateString()}
                </p>
              </div>
            ) : (
              <div
                className={`trinket-progress ${
                  trinketData.is_redeemable ? "progress-full" : ""
                }`}
              >
                <h6>
                  {trinketData.user_asset_quantity} /{" "}
                  {trinketData.requirement.quantity}
                </h6>

                <Progress
                  percentage={
                    (trinketData.user_asset_quantity /
                      trinketData.requirement.quantity) *
                    100
                  }
                />
              </div>
            )}
          </>
        )}

        <p></p>
        {trinketData.reward == "Junk" ? (
          <h5>
            Total pieces available <span>{trinketData.supply}</span>{" "}
          </h5>
        ) : (
          <h5>
            Total JUNK Remaining <span>{trinketData.supply}</span>{" "}
          </h5>
        )}

        {trinketData.reward == "Junk" ? (
          <>
            {" "}
            Find JUNK in all the different places of Algo City and use it to
            make your life just a bit more Junkier.
          </>
        ) : (
          <>
            {" "}
            {trinketData.supply !== 0 ? (
              <>
                {isClaimClicked ? (
                  <button className="btn btn-warning" type="button" disabled>
                    <div
                      className="spinner-border text-light mr-2"
                      role="status"
                    ></div>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-info"
                    disabled={!trinketData.is_redeemable}
                    onClick={() => claimTrinket(wallet, trinketData)}
                  >
                    {trinketData.last_claimed_at
                      ? "CLAIMED"
                      : trinketData.is_redeemable
                      ? "Redeem Now"
                      : "Not Eligible"}
                  </button>
                )}
              </>
            ) : (
              <>
                <p></p>
                <h6>Users grabbed all the JUNK.</h6>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TrinketModal;
