import axios from "axios";
import { useState, useEffect } from "react";
import {
  API_ENDPOINT,
  ASSET_NAMES,
  CUBE_ASSET_ID,
  NODE_ENDPOINT,
  MENTALAF,
} from "../../constants.ts";
import {
  getAssetHolder,
  getBalance,
  mental_af_check_tx,
} from "../utilities.ts";
import { toast } from "react-toastify";
import loadingGIF from "../../assets/loading.gif";
import algosdk from "algosdk";
import { encode } from "uint8-to-base64";
import AssetBoxForSwap from "../asset_box_for_swap.tsx";
import React from "react";
import { useWallet } from "@txnlab/use-wallet";
import AssetBoxOptimal from "../assetboxoptimal.tsx";

export default function MentalAF() {
  const [loading, setLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [connectType, setWalletType] = useState("");
  const [afmentalAssetId, setAfmentalAssetId] = useState({
    ai: 0,
    s: "n",
  });
  const [assetHolder, setAssetHolder] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const { activeAccount, signTransactions } = useWallet();
  const MentalAF = MENTALAF;
  const PAGE_SIZE = 12;
  const [paging, setPaging] = useState(1);

  useEffect(() => {
    const userAddressLocal = activeAccount?.address;
    const connectTypeLocal = activeAccount?.providerId;
    if (userAddressLocal && connectTypeLocal) {
      setWalletAddress(userAddressLocal);
      setWalletType(connectTypeLocal);
      setLoading(false);
    }
  }, [activeAccount]);

  useEffect(() => {
    async function checkAfMentalAssetId() {
      const res = await axios.get(`${API_ENDPOINT}/mental-af/`);
      if (res.status === 200) {
        setAfmentalAssetId(res.data);
      } else {
        setAfmentalAssetId({
          ai: 0,
          s: "n",
        });
      }
    }
    checkAfMentalAssetId();
  }, []);

  useEffect(() => {
    if (afmentalAssetId.ai !== 0) {
      getAssetHolder(afmentalAssetId.ai).then((res) => {
        setAssetHolder(res);

        setLoading(false);
      });
    }
  }, [afmentalAssetId]);

  const buttonText = () => {
    if (assetHolder === walletAddress) {
      if (afmentalAssetId.s === "n") {
        return "Upgrade";
      } else {
        return "Already Upgraded";
      }
    } else if (assetHolder !== walletAddress) {
      return "You don't own this asset";
    }
  };

  async function handleMentalAfUpgrade() {
    if (isClicked) {
      return;
    }
    if (walletAddress === "") {
      toast.error("Please connect your wallet first!");
      return;
    } else if (afmentalAssetId.ai === 0) {
      toast.error("Something went wrong while fetching the asset id!");
      return;
    } else if (afmentalAssetId.s === "y") {
      toast.error("You already have the upgraded asset!");
      return;
    } else if (assetHolder !== walletAddress) {
      toast.error("You don't own this asset!");
      return;
    }

    const has_opted_in_fc = await axios.get(
      NODE_ENDPOINT +
        "/v2/accounts/" +
        walletAddress +
        "/assets/" +
        CUBE_ASSET_ID
    );
    if (has_opted_in_fc.status !== 200) {
      toast.info(
        "Please firstly opt-in to the Force Cube asset to get rewards!"
      );
      return;
    }

    setIsClicked(true);
    const transaction = await mental_af_check_tx(
      walletAddress,
      afmentalAssetId.ai
    );

    if (!transaction) {
      toast.error("Something went wrong while creating the check transaction!");
      setIsClicked(false);
      return;
    }

    let signedTxn;
    let encodedTxn;
    try {
      signedTxn = await signTransactions([
        algosdk.encodeUnsignedTransaction(transaction),
      ]);

      signedTxn = signedTxn[0];
      encodedTxn = encode(signedTxn);

      if (signedTxn && encodedTxn) {
        toast.info("Please wait while checking your upgrade request...", {
          autoClose: 4000,
        });
        const res = await axios.post(`${API_ENDPOINT}/mental-af/`, {
          tx: encodedTxn,
        });
        if (res.status === 200) {
          toast.success("Your Marvin successfully upgraded!");
          setIsClicked(false);
          setAfmentalAssetId(res.data);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      } else {
        toast.error("Something went wrong while creating the swap!");
        setIsClicked(false);
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  return (
    <main className="">
      <div className="">
        <div className="mental-af-horizontal">
          <div className="mental-description">
            Mental AF is awarded to a holder who wins the Mental AF competition.
            <h5>COLLECTION</h5>
            <div className="MentalAF-collection">
              {MentalAF.map((asset, index) => (
                <div className="MAF-collection">
                  <AssetBoxOptimal
                    key={index}
                    asset_id={asset}
                    onNameReceived={undefined}
                    onImageUrlReceived={undefined}
                    onTraitsReceived={undefined}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="swapperimage">
            <p>
              <div className="winner-text">
                Last Winner:
                <a
                  className=""
                  href={`https://www.minthol.art/algo/assets/${afmentalAssetId.ai}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {afmentalAssetId.ai}
                </a>{" "}
              </div>

              <p>{ASSET_NAMES[afmentalAssetId.ai] || "N/A"}</p>
            </p>
            <div className="winner-img">
              <AssetBoxForSwap
                asset_id={afmentalAssetId.ai}
                onNameReceived={undefined}
                onTraitsReceived={undefined}
                onImageUrlReceived={undefined}
              />
            </div>
          </div>
        </div>

        {isClicked ? (
          <button className="btn btn-warning" type="button" disabled>
            <div className="spinner-border text-light mr-2" role="status"></div>
          </button>
        ) : (
          <button
            onClick={handleMentalAfUpgrade}
            disabled={buttonText() !== "Upgrade"}
            className={`btn btn-info ${
              buttonText() !== "Upgrade" && "opacity-50 cursor-not-allowed"
            }`}
          >
            {buttonText()}
          </button>
        )}
      </div>
    </main>
  );
}
