import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_ENDPOINT2 } from "../../constants.ts";
import { toast } from "react-toastify";
import "./opengift.css";
import cover0 from "../../assets/comic/COVER0site.webp";
import cover1 from "../../assets/comic/cover1w.webp";
import QRCode from "qrcode";

export default function OpenGift() {
  const { token } = useParams(); // Extract the token from the URL
  const [giftId, setGiftId] = useState(null);
  const [sender, setSender] = useState(null);
  const [trxID, setTrxID] = useState(null);
  const [rarity, setRarity] = useState(null);
  const [senderNFD, setSenderNFD] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [mnemonic, setMnemonic] = useState("");
  const [address, setAddress] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [peraQr, setPeraQr] = useState("");
  const [isBlurred, setIsBlurred] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  useEffect(() => {
    // Function to handle both mobile detection and page size adjustment
    const handleResize = () => {
      const isMobileScreen = window.matchMedia("(max-width: 1024px)").matches;
      setIsMobile(isMobileScreen);
    };

    // Initial check when the component mounts
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchComicData = async () => {
      try {
        // First API call to get gift data
        const giftRes = await axios.get(`${API_ENDPOINT2}/open-gift/${token}/`);
        if (giftRes.status === 200) {
          const { asset_id, sender, rarity } = giftRes.data;
          setGiftId(asset_id);
          setSender(sender);
          setRarity(rarity);

          toast.success("Gift opened successfully!");

          // Second API call to get sender NFD data
          const nfdRes = await axios.get(
            `https://api.nf.domains/nfd/lookup?address=${sender}`
          );

          if (nfdRes.status === 200 && nfdRes.data) {
            // Access the name based on the dynamic sender address
            const sendernfd = nfdRes.data[sender]?.name; // Use sender as key to access the object

            if (sendernfd) {
              setSenderNFD(sendernfd);
            } else {
              toast.error("No NFD name found for the sender.");
            }
          } else {
            // If NFD lookup fails but gift was opened, don't break the flow, just log error
            toast.error("Failed to fetch NFD for the sender.");
          }
        } else {
          setError(true);
          toast.error("Failed to open the gift.");
        }
      } catch (err) {
        // Handles errors for both API requests
        setError(true);
        toast.error("Invalid or expired link.");
      } finally {
        // Stop loading after both API calls are resolved
        setLoading(false);
      }
    };

    fetchComicData();
  }, [token]);

  async function handleUnwrapGift(sender, giftId, token) {
    toast.info("Unwrapping the Comic Book");
    try {
      const res = await axios.post(`${API_ENDPOINT2}/unwrap-gift/`, {
        giftId,
        sender,
        token,
      });

      if (res.status === 200) {
        const { transaction, asset_id, rarity } = res.data;
        toast.success("Successfully unwrapped your comic!");
        setTrxID(transaction);
        setGiftId(asset_id);
        setRarity(rarity);
      } else {
        toast.error("Something went wrong while unwrapping!");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
    }
  }

  const generateTestQR = async () => {
    // Use placeholders for testing
    const testMnemonic =
      '  {"mnemonic":"sample mnemonic for testing freaky sample mnemonic for testing freaky sample mnemonic for testing freaky sample mnemonic for testing freaky sample mnemonic for testing freaky"}';
    const testAddress =
      "7PWMEGVTGLHQ427HEWC4ZNYYOHPXKUIANUUMSWPLEFMI7KNNJU6PG4WHZM";

    // Generate the QR code image from the mnemonic

    const qrCodeImage = await QRCode.toDataURL(testMnemonic);
    const qrCodeImage2 = await QRCode.toDataURL(testMnemonic.slice(15, -2));

    // Set the state with the generated QR code and sample data
    setMnemonic(testMnemonic);
    setAddress(testAddress);
    setQrCode(qrCodeImage);
    setPeraQr(qrCodeImage2);
  };

  const handleCopyMnemonic = () => {
    navigator.clipboard.writeText(mnemonic.slice(15, -2)).then(() => {
      toast.success("Mnemonic copied to clipboard!");
    });
  };
  const handleMnemonicClick = () => {
    setIsBlurred((prev) => !prev); // Toggle blur effect
  };

  async function handleDeliverGift(giftId, token) {
    try {
      const response = await axios.post(`${API_ENDPOINT2}/new-user-gift/`, {
        giftId,
        token,
      });

      // Assuming the response structure is correct
      if (response.data) {
        setMnemonic(response.data.mnemonic);
        setAddress(response.data.address);
        setQrCode(response.data.qr_code); // Set the QR code image data
        toast.success("Comic retrieved successfully!");
      } else {
        toast.error("No data received!");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      const errorMessage =
        error.response?.data?.error || "Failed to retrieve mnemonic!";
      toast.error(errorMessage);
    }
  }

  if (loading) {
    return <div className="opengift">Loading comic...</div>;
  }

  if (error) {
    return (
      <div className="opengift">Error: Invalid link or comic not found!</div>
    );
  }

  return (
    <div className="opengift">
      {mnemonic ? (
        <div>
          <div className="wallet-wrapper">
            <h5>Download The Wallet</h5>

            <a href="https://perawallet.app/" target="blank">
              <img src="/pera-logo.webp" alt="PERA" /> PERA WALLET
            </a>
          </div>
          <div className="key-wrapper">
            <h5>Your Secret Key</h5>
            <p>
              This is your 25 word <span>PRIVATE SECRET KEY</span> that you will
              use to add the account in your <span>DIGITAL WALLET</span>.
            </p>
            {isMobile ? (
              <>
                Open your <span>PERA WALLET</span> and then go to{" "}
                <span>I already have an Account</span> and then click{" "}
                <span>Recover an account</span>.
                <br />
                Now you can <span>COPY</span> the key and <span>PASTE</span> it
                in the wallet.
                <div className="codes">
                  <div className="secret-key">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={handleCopyMnemonic}
                    >
                      COPY KEY
                    </button>
                    <div>This will copy your secret key</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                Open your <span>WALLET</span> and then go to{" "}
                <span>IMPORT / RECOVER</span> Account and then{" "}
                <span>SCAN QR CODE</span>
                <div className="codes">
                  <div className="qrcode">
                    {/* Render the QR code image */}
                    <img src={qrCode} alt="QR Code" />

                    {/* <img
                      src={`data:image/png;base64,${btoa(qrCode)}`}
                      alt="QR Code"
                    /> */}
                  </div>
                </div>
              </>
            )}
          </div>
          If you have trouble importing your secret key you can find out more
          <a
            href="https://support.perawallet.app/en/article/recover-or-import-an-algorand-account-with-recovery-passphrase-11gdh1y/"
            target="blank"
            rel="noreferrer"
            className=""
          >
            HERE
          </a>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              generateTestQR();
            }}
          >
            CLOSE
          </button>
        </div>
      ) : (
        <>
          {rarity ? (
            <>
              <h4>
                You just unwrapped a digital comic on blockchain you Mental
                legend!{" "}
              </h4>
              <h5>Variation</h5>
              <div className="rarity-container">
                <div
                  className={`common ${rarity === "common" ? "active" : ""}`}
                >
                  common
                </div>
                <div
                  className={`uncommon ${
                    rarity === "uncommon" ? "active" : ""
                  }`}
                >
                  uncommon
                </div>
                <div className={`rare ${rarity === "rare" ? "active" : ""}`}>
                  rare
                </div>
                <div className={`epic ${rarity === "epic" ? "active" : ""}`}>
                  epic
                </div>
                <div
                  className={`legendary ${
                    rarity === "legendary" ? "active" : ""
                  }`}
                >
                  legendary
                </div>
              </div>

              <img className="animated-image" src={cover1} alt="cover" />
              <div className="nft-description">
                <p>What is a NFT comic book?</p>
                <p>
                  It's an asset on chain that you can <span>CLAIM</span> in your{" "}
                  <span>DIGITAL WALLET</span> and be a true uwner of it.
                  <br />
                  Then you can read it, sell it, swap it, burn it or use it's
                  utilites to interact on Mental Marvin site.
                </p>
              </div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  handleDeliverGift(giftId, token);
                }}
              >
                CLAIM
              </button>
            </>
          ) : (
            <>
              <h3>
                <span>{senderNFD}</span> sent you a GIFT!{" "}
              </h3>
              <h4>A Brand new Mental Marvin comic that you can now unwrap! </h4>
              <img
                className="animated-image"
                src={cover0}
                alt="cover"
                onClick={() => {
                  handleUnwrapGift(sender, giftId, token);
                }}
              />
            </>
          )}
        </>
      )}

      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => {
          generateTestQR();
        }}
      >
        test mnemonic
      </button>
      {/* Render the comic images or details here */}
    </div>
  );
}
